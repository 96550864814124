import './style.scss';

import { useWithDispatch } from 'hooks';
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useCommonStore } from 'store/common/reducer';

import { Loader, Page, PageNavbar } from '../../components';
import Sections from './Sections';

const Stylist = ({ user, stylist, match, loadStylist, loadUserMatches, loading, toggleModal }) => {
    const history = useHistory();
    const reviewsRef = React.createRef();
    const aboutRef = React.createRef();
    const workRef = React.createRef();
    const NAV_ANCHORS = [
        { ref: workRef, label: 'Looks' },
        { ref: aboutRef, label: 'About' },
        { ref: reviewsRef, label: 'Reviews' }
    ];
    const matches = useCommonStore((store) => store.stylistsMatchs);
    const loadUserMatchesAction = useWithDispatch(loadUserMatches);

    const onNavSelect = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        if (!matches.length) {
            loadUserMatchesAction(user);
        }
    }, [user]);

    useEffect(() => {
        loadStylist(match.params.id);
    }, [loadStylist, loadUserMatches, match]);

    const contactStylist = () => {
        user?.user_uuid
            ? toggleModal({ type: 'Contact' })
            : toggleModal({
                  type: 'Signin',
                  url: `/stylist/${stylist.uuid}/profile`
              });
    };

    return (
        <Page footer={false}>
            {(loading || !stylist.uuid) && <Loader />}
            {user && stylist && user.user_uuid === stylist.uuid && navigator.share && (
                <Sections.ShareBar />
            )}

            <Container className="stylist">
                <PageNavbar
                    className="d-block d-sm-none"
                    anchors={NAV_ANCHORS}
                    onSelect={onNavSelect}
                    onBack={() => history.push('/stylistSearch')}
                />

                <Sections.StylistHeader />

                <div ref={workRef}>
                    <Sections.Work />
                </div>

                <Sections.Recommendation />

                <div ref={aboutRef}>
                    <Sections.Experience />
                </div>

                <Sections.Expertise />

                <div ref={reviewsRef}>
                    <Sections.Reviews />
                </div>

                <Sections.StylingExperience />

                <Sections.Matches />

                {!loading && Object.keys(stylist).length > 0 && (
                    <Sections.BookBar onContactStylist={contactStylist} />
                )}
            </Container>
        </Page>
    );
};

export default Stylist;
