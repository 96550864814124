import './style.scss';

import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { modal } from '../../../content';
import { isButtonDisable, isLocationDressingRoom } from '../../../services/utils/styleRoom-utils';
import { Loader } from '../..';

const SaveStyleboard = ({
    loading,
    stylingRoomLoading,
    loadTags,
    tags,
    saveLook,
    toggleModal,
    redirectUrl,
    result,
    gender,
    clearResult,
    revision,
    error,
    changeModal,
    clearError
}) => {
    const history = useHistory();
    const [info, setInfo] = useState({ name: '', description: '' });
    const [selectedStyle, setSelectedStyle] = useState({});
    const [selectedOther, setSelectedOther] = useState({});
    const [selectedEvent, setSelectedEvent] = useState({});
    const [disable, setDisable] = useState(false);
    const isDressingRoom = isLocationDressingRoom();

    const isDisable = isButtonDisable(info.description, disable);

    useEffect(() => {
        loadTags(gender, 'style');
        loadTags(gender, 'other');
        loadTags(gender, 'event');
    }, []);

    useEffect(() => {
        if (tags.style) setSelectedStyle(tags.style[0]);
        if (tags.other) setSelectedOther(tags.other[0]);
        if (tags.event) setSelectedEvent(tags.event[0]);
    }, [tags]);

    useEffect(() => {
        if (result.message === 'success') {
            clearResult();
            toggleModal();
            if (!redirectUrl.includes('inbox')) window.location.href = redirectUrl;
            else history.push(redirectUrl);
        } else setDisable(false);
    }, [result]);

    useEffect(() => {
        if (error) {
            changeModal({
                type: 'Error',
                data: { error: modal.errors.sendBoard, callback: clearError }
            });
        }
    }, [error]);

    const update = (key, value) => {
        setInfo({ ...info, [key]: value });
    };

    const submit = () => {
        setDisable(true);
        saveLook(
            {
                ...info,
                tags: [selectedStyle.name, selectedOther.name, selectedEvent.name]
            },
            'outfit',
            revision ? revision.outfit_uuid : null
        );
    };

    return (
        <span className="save-styleboard">
            {(loading || stylingRoomLoading) && <Loader />}
            <span className="close" onClick={toggleModal} />
            <Modal.Header>
                {isDressingRoom ? modal.saveStyleboard.saveTitle : modal.saveStyleboard.title}
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Row>
                        <Form.Label>{modal.saveStyleboard.description}</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={info.description}
                            onChange={(e) => update('description', e.target.value)}
                        />
                        {!info.description.length && (
                            <p className="error">{modal.saveStyleboard.errors.description}</p>
                        )}
                    </Form.Row>

                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>{modal.saveStyleboard.name}</Form.Label>
                                <Form.Control
                                    type="text"
                                    maxLength={50}
                                    value={revision ? revision.name : info.name}
                                    onChange={(e) => update('name', e.target.value)}
                                />
                                {info.name.length === 50 && (
                                    <p className="error">{modal.saveStyleboard.errors.name}</p>
                                )}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Label>{modal.saveStyleboard.event}</Form.Label>
                            <DropdownButton
                                key="styleboard-events"
                                title={selectedEvent.name || ''}
                            >
                                {tags.event && tags.event.map((tag) => (
                                    <Dropdown.Item
                                        eventKey={tag.uuid}
                                        key={tag.uuid}
                                        onSelect={() => setSelectedEvent(tag)}
                                    >
                                        {tag.name}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Col xs={4}>
                            <Form.Label>{modal.saveStyleboard.tags}</Form.Label>
                            <DropdownButton
                                key="styleboard-style-tags"
                                title={selectedStyle.name || ''}
                            >
                                {tags.style &&
                                    tags.style.map((tag) => (
                                        <Dropdown.Item
                                            eventKey={tag.uuid}
                                            key={tag.uuid}
                                            onSelect={() => setSelectedStyle(tag)}
                                        >
                                            {tag.name}
                                        </Dropdown.Item>
                                    ))}
                            </DropdownButton>
                        </Col>
                        <Col xs={4}>
                            <Form.Label>{modal.saveStyleboard.other}</Form.Label>
                            <DropdownButton
                                key="styleboard-other-tags"
                                title={selectedOther.name || ''}
                            >
                                {tags.other &&
                                    tags.other.map((tag) => (
                                        <Dropdown.Item
                                            eventKey={tag.uuid}
                                            key={tag.uuid}
                                            onSelect={() => setSelectedOther(tag)}
                                        >
                                            {tag.name}
                                        </Dropdown.Item>
                                    ))}
                            </DropdownButton>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Form.Check
                            type="checkbox"
                            label={modal.saveStyleboard.feed}
                            onChange={(e) => update('publish_on_feed', e.target.checked)}
                        />
                    </Form.Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-test-id="send-board"
                    variant="warning"
                    onClick={submit}
                    disabled={isDisable}
                >
                    {isDressingRoom ? modal.saveButton : modal.saveStyleboard.button}
                </Button>
            </Modal.Footer>
        </span>
    );
};

export default SaveStyleboard;
