import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bookStylist } from 'store/booking/actions';
import { loadUserMatches } from 'store/common/actions';
import { toggleModal } from 'store/ui/actions';

import { loadStylistReviews } from '../../../redux/reducers/stylists/actions';
import BookBar from './BookBar';
import Experience from './Experience';
import Expertise from './Expertise';
import Recommendation from './Recommendation';
import Reviews from './Reviews';
import ShareBar from './ShareBar';
import StylingExperience from './StylingExperience';
import StylistHeader from './StylistHeader';
import Work from './Work';

const mapStateToProps = ({
    user: { user },
    stylists: { stylist, reviews, loading },
    style: { matches },
    common: { stylistsMatchs },
    common: { mixpanelStore }
}) => ({
    user,
    stylist,
    reviews,
    loading,
    matches,
    stylistsMatchs,
    mixpanelStore
});

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (data) => toggleModal(dispatch, data),
    loadStylistReviews: (uuid, from, count) => dispatch(loadStylistReviews(uuid, from, count)),
    loadUserMatches: (user) => loadUserMatches(dispatch, user),
    bookStylist: (stylist) => bookStylist(dispatch, stylist)
});

export default {
    Recommendation: connect(mapStateToProps, mapDispatchToProps)(Recommendation),
    Reviews: withRouter(connect(mapStateToProps, mapDispatchToProps)(Reviews)),
    Experience: connect(mapStateToProps, mapDispatchToProps)(Experience),
    Expertise: connect(mapStateToProps, mapDispatchToProps)(Expertise),
    StylistHeader: connect(mapStateToProps, mapDispatchToProps)(StylistHeader),
    BookBar: withRouter(connect(mapStateToProps, mapDispatchToProps)(BookBar)),
    ShareBar: connect(mapStateToProps, mapDispatchToProps)(ShareBar),
    Work: connect(mapStateToProps, mapDispatchToProps)(Work),
    StylingExperience: connect(mapStateToProps, mapDispatchToProps)(StylingExperience)
};
