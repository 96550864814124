import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AnyAction, Dispatch } from 'redux';

import { setupError } from '../store/error/actions';

type URLParams = Record<string, string | number | boolean | string[]>;

function requestHandler({
    dispatch,
    targetAction,
    params
}: {
    dispatch: Dispatch<AnyAction>;
    targetAction?: string;
    params?: URLParams;
}) {
    if (dispatch && targetAction) {
        dispatch({ type: `${targetAction}_REQUEST`, payload: params });
    }
}

function errorHandler({
    dispatch,
    targetAction,
    err,
    showErrorMessage = false,
    rethrow = false
}: {
    dispatch: Dispatch<AnyAction>;
    targetAction?: string;
    err: AxiosError;
    showErrorMessage?: boolean;
    rethrow?: boolean;
}) {
    if (dispatch && targetAction) {
        dispatch({ type: `${targetAction}_FAILURE`, payload: err });
    }
    if (dispatch) {
        const defaultMessage = 'Action failed';
        setupError(dispatch, {
            error: err,
            message: err?.response?.data?.message ?? defaultMessage,
            showMessage: showErrorMessage
        });
    }
    if (rethrow) {
        throw err;
    }
}

type PrettifyData = (data: any) => any;

function successHandler({
    dispatch,
    targetAction,
    prettifyData,
    response,
    analytics,
    showSuccessMessage = false
}: {
    dispatch: Dispatch<AnyAction>;
    targetAction?: string;
    prettifyData: PrettifyData;
    response: AxiosResponse;
    analytics?: boolean;
    showSuccessMessage?: boolean;
}) {
    const payload = prettifyData(response.data);
    if (dispatch && targetAction) {
        dispatch({ type: `${targetAction}_SUCCESS`, payload, analytics });
    }

    if (dispatch !== undefined && dispatch !== null && showSuccessMessage) {
        const message =
            typeof payload === 'string' ? payload : payload?.message ?? 'Action succeeded';
    }
    return payload;
}
interface Request {
    dispatch: Dispatch<AnyAction>;
    url: string;
    targetAction?: string;
    params?: URLParams;
    prettifyData?: PrettifyData;
    requireAuth?: boolean;
    analytics?: boolean;
    throwErrors?: boolean;
    config?: Partial<AxiosRequestConfig>;
}
interface UpdateRequest extends Request {
    data?: any;
    showSuccessMessage?: boolean;
}

async function fetch<T>({
    dispatch,
    targetAction,
    url,
    params = {},
    config = {},
    prettifyData = (data) => data,
    requireAuth = true,
    analytics = false,
    throwErrors = false
}: Request) {
    try {
        requestHandler({ dispatch, targetAction, params });
        const response = await Axios.request<T>({ method: 'GET', url, params, ...config });
        return successHandler({
            dispatch,
            targetAction,
            prettifyData,
            response,
            analytics,
            showSuccessMessage: false
        });
    } catch (err) {
        errorHandler({
            dispatch,
            targetAction,
            err: err as AxiosError,
            showErrorMessage: false,
            rethrow: throwErrors
        });
        return null;
    }
}

async function insert<T>(
    insertFunc: typeof Axios.post | typeof Axios.put,
    {
        dispatch,
        targetAction,
        url,
        params = {},
        data,
        prettifyData = (_data) => _data,
        requireAuth = true,
        showSuccessMessage = false,
        analytics = true,
        throwErrors = false
    }: UpdateRequest
) {
    try {
        requestHandler({ dispatch, targetAction, params });
        const response = await insertFunc<T>(url, params, data);
        return successHandler({
            dispatch,
            targetAction,
            prettifyData,
            response,
            analytics,
            showSuccessMessage
        });
    } catch (err: any) {
        errorHandler({ dispatch, targetAction, err, showErrorMessage: true, rethrow: throwErrors });
        return null;
    }
}
const post = async <T>(options: UpdateRequest) => await insert<T>(Axios.post, options);
const put = async <T>(options: UpdateRequest) => await insert<T>(Axios.put, options);
const remove = async <T>(options: UpdateRequest) => await insert<T>(Axios.delete, options);

interface MockRequest {
    dispatch: Dispatch<AnyAction>;
    targetAction: string;
    mock: any;
    prettifyData?: PrettifyData;
    analytics?: boolean;
}
function fetchMock({
    dispatch,
    targetAction,
    mock,
    prettifyData = (_data: any) => _data,
    analytics
}: MockRequest) {
    const response = { data: mock } as AxiosResponse;
    return successHandler({
        dispatch,
        targetAction,
        prettifyData,
        response,
        analytics,
        showSuccessMessage: false
    });
}

function createTypes(prefix: string, ...args: string[]) {
    return args.reduce((types: Record<string, string>, type) => {
        types[type] = prefix + type;
        return types;
    }, {});
}

export default {
    fetch,
    post,
    put,
    remove,
    fetchMock,
    createTypes
};
