import './style.scss';

import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { useEffect, useState } from 'react';
import { Image as BootstrapImage } from 'react-bootstrap';

import { styling } from '../../content';
import { convertImageToBase64 } from '../../services/utils/canvas-utils';
import { Loader } from '../';
import { CanvasGrid } from './';
import CropModal from './CanvasCropModal';
import CanvasToolbar from './CanvasToolbar';

const Canvas = ({
    canvasSizeIndex,
    canvasSizesArray,
    setCanvasSizeIndex,
    canvasLayout,
    setCanvasLayout,
    items = [],
    ps = [],
    type,
    removeFromCanvas,
    canvasLoading,
    setCanvasLoad,
    onSave,
    isMoodboard,
    updateCanvasTotalPrice,
    psTotalPrice,
    itemsTotalPrice
}) => {
    const { editor, onReady } = useFabricJSEditor();

    const [canvasItems, setCanvasItems] = useState([]);
    const [counter, setCounter] = useState({
        items: 0,
        ps: 0
    });
    const [toolbar, setToolbar] = useState({ show: false });
    const [crop, setCrop] = useState({ show: false });
    const [dimension, setDimension] = useState(0);
    const [preview, setPreview] = useState('');
    const [canvas, setCanvas] = useState();

    useEffect(() => {
        if (editor && !canvas) {
            editor.canvas.setHeight(canvasSizesArray[2]);
            editor.canvas.setWidth(canvasSizesArray[2]);
            setCanvas(editor.canvas);
        }
    }, [editor]);

    useEffect(() => {
        if (canvas) {
            canvas.on('after:render', (e) => {
                const base64 = e.ctx.canvas.toDataURL();
                if (base64 !== 'data:,') setPreview(base64);
            });
            canvas.on('object:scaling', (e) => updateToolbar(e.target));
            canvas.on('object:rotating', (e) => updateToolbar(e.target));
            canvas.on('object:moving', (e) => {
                let { top, left, angle } = e.target;
                const bounds = e.target.getBoundingRect();

                if (angle > 350 || angle < 10) {
                    if (top < 0) e.target.top = 0;
                    if (top + bounds.height > canvas.height)
                        e.target.top = canvas.height - bounds.height;
                    if (left < 0) e.target.left = 0;
                    if (left + bounds.width >= canvas.width)
                        e.target.left = canvas.width - bounds.width;
                }
                updateToolbar(e.target);
            });
            canvas.selection = false;
            canvas.preserveObjectStacking = true;
        }
    }, [canvas]);

    useEffect(() => {
        const size = canvasSizesArray[canvasSizeIndex];
        const maxDimension = window.innerHeight - 150;
        const _dimension = size < maxDimension ? size : maxDimension;
        if (canvas && dimension !== _dimension) {
            removeTransformer();
            canvas.setDimensions(
                {
                    width: _dimension,
                    height: _dimension
                },
                { cssOnly: canvasSizeIndex < 2 }
            );
        }
        setDimension(size < maxDimension ? size : maxDimension);
    }, [canvasSizeIndex]);

    useEffect(() => {
        removeTransformer();
        const filtered = items.filter((id) => !canvasItems.includes(id));
        filtered.forEach((item) => {
            if (item && canvasItems.indexOf(item.id) < 0)
                createImage({
                    src: item.picture,
                    id: item.id,
                    base64: true
                });
        });

        setCanvasItems([...canvasItems, ...filtered]);

        setCounter({
            items: items.length,
            ps: ps.length
        });
    }, [canvasLayout, items, ps]);

    const createImage = async ({ src, base64 = false, id }) => {
        try {
            setCanvasLoad(true);
            const img = new Image();
            if (base64) {
                const imageData = await convertImageToBase64(src);
                img.src = imageData;
            } else {
                img.src = src;
            }
            img.id = id;
            img.clearBackground = false;
            img.cropped = false;
            img.onload = () => {
                fabric.Image.fromObject(img, (fImage) => {
                    if (fImage.width > canvasSizesArray[2] / 4)
                        fImage.scaleToWidth(canvasSizesArray[2] / 4);

                    fImage
                        .set({
                            lockUniScaling: true,
                            cornerSize: 8,
                            borderColor: '#dbdbdb',
                            cornerColor: '#dbdbdb',
                            cornerStrokeColor: '#dbdbdb'
                        })
                        .on({
                            selected: (e) => updateToolbar(e.target),
                            moved: (e) => updateToolbar(e.target),
                            modified: (e) => {
                                if (e.action !== 'drag') updateToolbar(e.target);
                            },
                            deselected: (e) => {
                                if (e.target) {
                                    removeTransformer();
                                }
                            },
                            added: () => {
                                setCanvasLoad(false);
                                updateCanvasTotalPrice();
                            }
                        });
                    fImage.controls.mtr.offsetY = -20;

                    canvas.add(fImage);
                    canvas.renderAll();
                });
            };
        } catch (e) {
            setCanvasLoad(false);
        }
    };

    const updateToolbar = (item, disabled = false) => {
        item.hasControls = true;
        const bounds = item.getBoundingRect();
        setToolbar({
            item: item,
            show: true,
            disabled,
            x: bounds.left + (bounds.width / 2 - 230 / 2),
            y: bounds.top - 60
        });
    };

    const removeTransformer = () => {
        if (canvas) {
            const active = canvas.getActiveObject();
            if (active) {
                active.hasControls = false;
                active.hasBorder = false;
                canvas.renderAll();
            }
        }
        setToolbar({ show: false });
    };

    const onToolbarAction = async (action) => {
        const activeItem = canvas.getActiveObject();

        switch (action) {
            case 'remove':
                setCanvasItems(canvasItems.filter((item) => item !== activeItem.id));
                removeFromCanvas(activeItem.id);
                canvas.remove(activeItem);
                removeTransformer();
                updateCanvasTotalPrice();
                break;
            case 'flip':
                const flipX = activeItem.get('flipX');
                activeItem.set('flipX', !flipX);
                break;
            case 'flop':
                const flipY = activeItem.get('flipY');
                activeItem.set('flipY', !flipY);
                break;
            case 'backward':
                canvas.sendBackwards(activeItem);
                break;
            case 'forward':
                canvas.bringToFront(activeItem);
                break;
            case 'remove-background':
            case 'undo-remove-background':
                const img = new Image();
                img.src = activeItem.cropped ? activeItem.cropped : activeItem.src;
                const src = await replaceColor(
                    !activeItem.clearBackground
                        ? { img, alpha: 0 }
                        : { img, color: { r: 0, g: 0, b: 0 }, alpha: 255 }
                );
                activeItem.setSrc(src, () => canvas.renderAll());
                activeItem.clearBackground = action === 'remove-background';
                updateToolbar(activeItem);
                break;
            case 'crop':
                if (!activeItem.cropped) {
                    setCrop({
                        show: true,
                        item: activeItem.src,
                        index: getItemIndex(activeItem.id)
                    });
                } else {
                    activeItem.cropped = false;
                    setCrop({ show: false });
                }
                break;
        }
        canvas.renderAll();
        // updateCanvasState(stageRef.toJSON());
    };

    const getItemIndex = (id) => items.findIndex((item) => item && item.id === id);

    const toggleSize = () => setCanvasSizeIndex(canvasSizeIndex === 1 ? 2 : 1);

    const closeCanvas = () => setCanvasSizeIndex(0);

    const openCanvas = (layout) => {
        setCanvasSizeIndex(1);
        setCanvasLayout(layout);
    };

    const onCropDone = (cropped) => {
        const item = canvas.getActiveObject();

        setCrop({ show: false });

        item.original = item.src;
        item.cropped = cropped;
        item.setSrc(cropped, () => canvas.renderAll());
    };

    const checkDisableSave = () => {
        const _items = canvasLayout === 'free' ? items : ps;
        return !_items.length || _items.filter((item) => !!item).length < 3;
    };

    const onCanvasSave = () => {
        if (canvasLayout === 'free') removeTransformer();
        if (onSave) onSave(canvasLayout === 'free' ? canvas.toDataURL() : null);
    };

    const replaceColor = async ({
        img,
        color = { r: 255, g: 255, b: 255 },
        replace = { r: 255, g: 255, b: 255 },
        alpha = 0
    }) => {
        try {
            const c = document.createElement('canvas');
            const w = img.width,
                h = img.height;

            c.width = w;
            c.height = h;

            const ctx = c.getContext('2d');

            ctx.drawImage(img, 0, 0, w, h);
            const imageData = ctx.getImageData(0, 0, w, h);
            const pixel = imageData.data;

            let r = 0,
                g = 1,
                b = 2,
                a = 3;
            for (let p = 0; p < pixel.length; p += 4) {
                if (
                    pixel[p + r] === color.r &&
                    pixel[p + g] === color.g &&
                    pixel[p + b] === color.b &&
                    pixel[p + a] !== alpha
                ) {
                    pixel[p + r] = replace.r;
                    pixel[p + g] = replace.g;
                    pixel[p + b] = replace.b;
                    pixel[p + a] = alpha; // if white then change alpha to 0
                }
            }

            ctx.putImageData(imageData, 0, 0);
            const src = c.toDataURL('image/png');
            return Promise.resolve(src);
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return (
        <div className={`canvas ${canvasSizeIndex ? 'opened' : 'closed'} canvas-type-${type}`}>
            {crop.show && (
                <CropModal
                    show={crop.show}
                    onDone={onCropDone}
                    onClose={() => setCrop({ show: false })}
                    image={crop.item}
                />
            )}

            <div
                className="canvas-wrapper"
                style={{ width: dimension, height: dimension + (canvasSizeIndex === 2 ? 60 : 0) }}
            >
                {canvasLoading && <Loader />}

                <div className="sizeToggle resize" onClick={toggleSize} />
                <div className="sizeToggle close" onClick={closeCanvas} />

                {toolbar.show && (
                    <CanvasToolbar
                        top={toolbar.y}
                        left={toolbar.x}
                        onClick={onToolbarAction}
                        clearBackground={canvas.getActiveObject().clearBackground}
                        disabled={toolbar.loading}
                    />
                )}
                <div
                    className={`board ${
                        canvasSizeIndex === 2 && canvasLayout === 'free' ? 'show' : 'hide'
                    }`}
                >
                    <FabricJSCanvas onReady={onReady} />
                </div>
                <div
                    className={`preview ${
                        canvasSizeIndex === 1 && canvasLayout === 'free' ? 'show' : 'hide'
                    }`}
                >
                    {preview && <BootstrapImage src={preview} className="canvasImage" />}
                </div>

                <CanvasGrid className={canvasLayout === 'grid' ? 'show' : 'hide'} />

                {canvasSizeIndex > 1 && (
                    <div className="buttons">
                        <div className="layout">
                            {!isMoodboard && (
                                <div
                                    className={`layout-btn grid ${
                                        canvasLayout === 'grid' ? 'on' : 'off'
                                    }`}
                                    onClick={() => {
                                        setCanvasLayout('grid');
                                        updateCanvasTotalPrice();
                                    }}
                                >
                                    <span />
                                    <span />
                                    <span />
                                    <span />
                                    <span />
                                    <span />
                                    <span />
                                    <span />
                                    <span />
                                </div>
                            )}
                            <div
                                className={`layout-btn free ${
                                    canvasLayout === 'free' ? 'on' : 'off'
                                }`}
                                onClick={() => {
                                    setCanvasLayout('free');
                                    updateCanvasTotalPrice();
                                }}
                            />
                        </div>
                        <div className="total">
                            {styling.canvas.total.replace(
                                '%total%',
                                `${canvasLayout === 'free' ? itemsTotalPrice : psTotalPrice}`
                            )}
                        </div>
                        <div
                            className={`save ${checkDisableSave() ? 'disabled' : ''}`}
                            onClick={onCanvasSave}
                        >
                            {styling.canvas.save}
                        </div>
                    </div>
                )}
            </div>
            <div className="minimized-buttons">
                <div className="buttons">
                    <div className="layout">
                        {!isMoodboard && (
                            <div className="layout-btn grid" onClick={() => openCanvas('grid')}>
                                {counter.ps > 0 && (
                                    <div className="items-counter">{counter.ps}</div>
                                )}
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                            </div>
                        )}
                        <div className="layout-btn free" onClick={() => openCanvas('free')}>
                            {counter.items > 0 && (
                                <div className="items-counter">{counter.items}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Canvas;
