import '../style.scss';

import React from 'react';
import { Collapse, Image } from 'react-bootstrap';

interface IChevron {
    open: boolean;
    looksCount: number;
    looksMax: number;
    url: string;
    isPartnerStylist: boolean;
    setOpen: (isOpen: boolean) => void;
}

const Chevron: React.FC<IChevron> = ({
    open,
    looksCount,
    looksMax,
    url,
    isPartnerStylist,
    setOpen
}) => {
    const generateMessage = () => {
        if (isPartnerStylist) return `Sent ${looksCount} style boards`;
        return `Sent ${looksCount} out of ${looksMax} style boards.`;
    };

    return (
        <div className="view-look-progress">
            <Image
                className={open ? 'open' : ''}
                src={url}
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
            />
            <Collapse in={open}>
                <p id="example-collapse-text">{generateMessage()}</p>
            </Collapse>
        </div>
    );
};

export default Chevron;
