import { Loader } from 'components';
import { inbox } from 'content.json';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { User } from 'services';
import { CACHE_TIME_MS } from './consts';

interface Item {
    name: string;
    picture: string;
    rate: string;
    percent: number;
}
const Style: React.FC<{ uuid: string }> = ({ uuid }) => {
    const [styles, setStyles] = useState<Record<string, Item[]>>({});
    const fetchStyles = async () => {
        const res = await User.style(uuid);
        return res?.data;
    };
    const { data, isFetching } = useQuery<{ styles: Item[]; quota_max: string }>(
        ['user-styles', uuid],
        fetchStyles,
        { staleTime: CACHE_TIME_MS }
    );
    useEffect(() => {
        const res = data?.styles?.reduce((acc, curr) => {
            if (!acc[curr.rate]) {
                acc[curr.rate] = [];
            }
            acc[curr.rate].push(curr);
            return acc;
        }, {} as Record<string, Item[]>) as Record<string, Item[]>;
        setStyles(res);
    }, [data, setStyles]);
    return (
        <div className="style">
            {isFetching && <Loader />}
            {styles &&
                Object.keys(styles)
                    .reverse()
                    .map((rate) => (
                        <Row key={`style_${rate}`}>
                            <Col xs={12}>
                                <p>{inbox.bookings.info.rates[rate as any]}</p>
                            </Col>
                            {styles[rate] &&
                                styles[rate].map((style) => (
                                    <Col md={6} xl={4} key={style.name} className="moodboard">
                                        <p>{style.name}</p>
                                        <Image src={style.picture} />
                                    </Col>
                                ))}
                        </Row>
                    ))}
        </div>
    );
};
export default Style;
