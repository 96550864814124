import React, { useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FilterOptionsParameters, FilterParams, StylingRoomSection } from 'types/styling';

import { Filters, Loader } from '../../../../components';
import { styling } from '../../../../content.json';
import { replaceStrings } from '../../../../services/utils';
import { Empty, FilterBadges } from '../../components';
import { GridSizeButtons } from '../../components/GridSizeButtons';
import { TRouteParams } from '../../types';
import { GridItem } from './GridItem';
import { ICloset, TGridItem } from './types';

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const FiltersContainer = styled.div`
    padding: 0 15px 0 45px;
    width: 100%;
`;

const Results = styled.div`
    width: 100%;
`;

const ResultsHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 0 5px;
    height: 63px;
`;

const SubTabsNav = styled.div`
    display: flex;
    align-items: center;

    a {
        color: #000000;
        margin-right: 23px;
        font-size: 14px;

        &.current,
        &:hover {
            font-weight: bold;
            color: #000000;
        }
    }
`;

export const Closet: React.FC<ICloset> = ({
    user,
    loading,
    loadingFilters,
    loadCloset,
    closet,
    loadClosetFilters,
    loadCartFilters,
    updateFilters,
    clearSelectedFilters,
    cartFilters,
    filters,
    selectedFilters,
    setGridSize,
    gridSize,
    addToCanvas,
    styleRequest,
    loadClientCart,
    cart,
    loadOrders,
    loadOrdersFilters,
    orders,
    loadWishlist,
    wishlistItems,
    wishlistPage
}) => {
    const { subTab = 'closet' } = useParams<TRouteParams>();
    const isDressingRoom = location.pathname.includes('dressing-room');
    const { user_uuid: clientId, first_name: clientName } = styleRequest?.owner || {};
    const { partnerType } = user;
    const subTabs = isDressingRoom
        ? styling.subTabs.dressingRoom
        : partnerType === 'WishiB2B'
        ? styling.subTabs.closetB2B
        : styling.subTabs.closetB2C;

    const gridItems = useMemo<TGridItem[]>(() => {
        switch (subTab) {
            case 'closet':
                return closet.map((item) => ({
                    id: item.uuid,
                    title: item.brand,
                    imageUrl: item.picture
                }));
            case 'cart':
                return cart.map((item) => ({
                    id: item.uuid,
                    title: item.retailer_name,
                    imageUrl: item.picture,
                    price: item.price
                }));
            case 'purchases':
                return orders.map((item) => ({
                    id: item.uuid,
                    title: item.brand_name,
                    imageUrl: item.picture,
                    price: item.price
                }));
            case 'wishlist':
                return wishlistItems.map((item) => ({
                    id: item.productId,
                    title: item.productName,
                    imageUrl: item.productPhotoUrl,
                    price: item.price
                }));
        }

        return [];
    }, [subTab, closet, cart, orders, wishlistItems]);

    const itemsCount = useMemo(
        () => replaceStrings(styling.items, { count: gridItems.length }),
        [gridItems]
    );

    const onFilterChange = (filter: FilterParams) => {
        const isSelected =
            selectedFilters[filter.key]?.findIndex((item: FilterParams | FilterOptionsParameters) =>
                !Array.isArray(filter.value) ? item.key === filter.value.key : false
            ) > -1;
        updateFilters(filter, isSelected, fetchData);
    };

    const fetchData = (filters?: Record<string, FilterOptionsParameters[]>) => {
        const activeFilters = getParams(filters ?? selectedFilters);

        switch (subTab) {
            case 'cart':
                loadClientCart(clientId, {
                    ...activeFilters,
                    from: !filters && cart ? cart.length : 1
                });
                break;

            case 'purchases':
                loadOrders({
                    ...activeFilters,
                    user_uuid: clientId,
                    from: !filters && orders ? orders.length : 1
                });
                break;

            case 'wishlist':
                loadWishlist({
                    page: wishlistPage + 1
                });
                break;

            case 'closet':
                loadCloset({ ...activeFilters, from: !filters && closet ? closet.length : 0 });
                break;
        }
    };

    const getParams = (activeFilters: Record<string, FilterOptionsParameters[]>) => {
        const result: { [k: string]: string } = {};

        Object.keys(activeFilters).forEach((category) => {
            const filters = activeFilters[category];
            if (filters?.length) {
                result[category] = filters.map((filter) => filter.key).join(',');
            }
        });

        return result;
    };

    useEffect(() => {
        clearSelectedFilters();

        switch (subTab) {
            case 'cart':
                loadCartFilters(clientId);
                fetchData();
                break;

            case 'purchases':
                loadOrdersFilters({
                    fields: 'categories,colors,retailers,prices,brands,sizes'
                });
                fetchData();
                break;

            case 'closet':
                loadClosetFilters({
                    fields: 'categories,colors,retailers,prices,brands,sizes'
                });
                fetchData();
                break;

            case 'wishlist':
                loadWishlist({
                    page: 1
                });
        }
    }, [clientId, subTab]);

    return (
        <Container className="styling-room-closet">
            <FiltersContainer className="filters-column">
                {loadingFilters && <Loader />}
                <p className="total">{itemsCount}</p>
                <Filters
                    className={
                        selectedFilters.category && !selectedFilters.category.length
                            ? 'categories-all'
                            : ''
                    }
                    filters={subTab === 'cart' ? cartFilters : filters}
                    selected={selectedFilters}
                    setFilter={onFilterChange}
                    showSelection={true}
                />
            </FiltersContainer>

            <Results id="results" className="results">
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <ResultsHeader>
                            <SubTabsNav>
                                {subTabs.map((tab) => (
                                    <Link
                                        key={tab.value}
                                        className={tab.value === subTab ? 'current' : ''}
                                        to={`${tab.value}`}>
                                        {replaceStrings(tab.text, {
                                            client: clientName || ''
                                        })}
                                    </Link>
                                ))}
                            </SubTabsNav>
                            <FilterBadges
                                selectedFilters={selectedFilters}
                                clearFilter={(filterObj) =>
                                    onFilterChange({
                                        key: filterObj.key,
                                        value: filterObj.filter?.value as FilterOptionsParameters
                                    })
                                }
                                view={StylingRoomSection.shop}
                            />
                            <GridSizeButtons gridSize={gridSize} setGridSize={setGridSize} />
                        </ResultsHeader>
                        {gridItems.length ? (
                            <InfiniteScroll
                                dataLength={gridItems.length}
                                next={fetchData}
                                hasMore={true}
                                loader={undefined}>
                                {gridItems.map((item, index) => (
                                    <GridItem key={index} {...item} />
                                ))}
                            </InfiniteScroll>
                        ) : (
                            <Empty tab={subTab} name={clientName || ''} />
                        )}
                    </>
                )}
            </Results>
        </Container>
    );
};
