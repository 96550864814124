import '../style.scss';

import {
    faFacebook,
    faInstagram,
    faPinterest,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, InputGroup } from 'react-bootstrap';

const SOCIAL_ICONS = {
    pinterest: faPinterest,
    instagram: faInstagram,
    facebook: faFacebook,
    twitter: faTwitter
};

const Social = ({ props: { icon, placeholder }, onChange }) => (
    <Form.Group>
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>@</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                as="input"
                onChange={(e) => onChange({ text: e.target.value })}
                placeholder={placeholder}
            />
            <InputGroup.Append>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={SOCIAL_ICONS[icon]} />
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    </Form.Group>
);

export default Social;
