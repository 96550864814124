import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleModal, changeModal } from 'store/ui/actions';

import {
    saveLook,
    stylingRoomClearResult,
    clearError
} from '../../../redux/reducers/stylingRoom/actions';
import SavePersonalShopping from './SavePersonalShopping';

const mapStateToProps = ({
    ui: { redirectUrl },
    style: { loading },
    stylingRoom: { loading: stylingRoomLoading, result, gender, styleRequest, error }
}) => ({
    loading,
    stylingRoomLoading,
    styleRequest,
    result,
    gender,
    redirectUrl,
    error
});

const mapDispatchToProps = (dispatch) => ({
    saveLook: (info) => dispatch(saveLook(info, 'items')),
    clearResult: () => dispatch(stylingRoomClearResult()),
    toggleModal: (data) => toggleModal(dispatch, data),
    changeModal: (data) => changeModal(dispatch, data),
    clearError: () => dispatch(clearError(dispatch))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavePersonalShopping));
