import './style.scss';

import { Page } from 'components';
import { Container, Row } from 'react-bootstrap';

import Bookings from './Bookings';

const Inbox = () => (
    <Page footer={false}>
        <Container className="inbox bookings" fluid>
            <Row>
                <Bookings />
            </Row>
        </Container>
    </Page>
);

export default Inbox;
