import moment from 'moment';
import { BookingType, BookSessionType, QueueType } from 'types/booking';

export const isArchiveOrInquiries = (queue: QueueType | undefined) =>
    queue === QueueType.inquiries || queue === QueueType.archived;

export const isArchive = (type: string) => type === BookSessionType.archived;

export const isInquiries = (type: string) => type === BookSessionType.inquiry;

export const getFollowUpIndex = (booking: BookingType) =>
    booking.session.labels.findIndex((label) => label.type === 1);

const differenceInDays = (date: Date) => {
    const todayDate = moment(new Date()).startOf('day');
    const dueDate = moment(date).startOf('day');
    return Math.floor(moment.duration(todayDate.diff(dueDate)).asDays());
};

const differenceInHours = (date: Date) => {
    const todayDate = moment(new Date());
    return todayDate.diff(moment(date), 'hours');
};

const formatToDueDate = (date: Date, isPartner = false) => {
    const momentDate = moment(new Date(date));
    if (isPartner) {
        return differenceInDays(date) == 0
            ? `${TemplateStrings.dates.dueToday}, ${momentDate.format('hA')}`
            : TemplateStrings.dates.due(
                  `${momentDate.format('MMM DD')}, ${momentDate.format('hA')}`
              );
    }
    return differenceInDays(date) == 0
        ? TemplateStrings.dates.dueToday
        : TemplateStrings.dates.due(momentDate.format('MMM DD'));
};

const formatToOverdue = (date: Date, isPartner: boolean) => {
    if (isPartner) {
        const hourDifference = Math.abs(differenceInHours(date));
        const days = Math.floor(hourDifference / 24);
        const hours = hourDifference % 24;
        return TemplateStrings.dates.overdue(days, hours);
    }
    const days = Math.abs(differenceInDays(date));

    return TemplateStrings.dates.overdue(days);
};

const TemplateStrings = {
    dates: {
        dueToday: 'Due Today',
        due: (formattedDate: string) => `Due: ${formattedDate}`,
        overdue: (days: number, hours = 0) => {
            const dayText = days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '';
            const hourText = hours > 0 ? `${hours} hours` : '';
            const andText = dayText !== '' && hourText !== '' ? ' & ' : '';
            return `Due: ${dayText}${andText}${hourText} ago`;
        }
    }
};

export const getDueDateLabel = (date: Date, isPartner: boolean) => {
    if (differenceInDays(date) <= 0 && differenceInHours(date) <= 0 && isPartner)
        return { text: formatToDueDate(date, isPartner), type: 0 };
    if (differenceInDays(date) <= 0 && !isPartner)
        return { text: formatToDueDate(date, isPartner), type: 0 };
    return { text: formatToOverdue(date, isPartner), type: 2 };
};
