import { Stylist } from 'types/user';
import { Option } from 'types/utils';

import { IActionWithPayload } from '../types';

export enum ActionTypes {
    CLEAR_FILTERS = 'CLEAR_FILTERS',
    LOAD_MATCHES = 'LOAD_MATCHES',
    LOAD_MATCHES_SUCCESS = 'LOAD_MATCHES_SUCCESS',
    LOAD_MATCHES_FAILED = 'LOAD_MATCHES_FAILED',
    SET_ZE_ON = 'SET_ZE_ON',
    SET_ZE_OFF = 'SET_ZE_OFF',
    SET_MIXPANEL_STORE = 'SET_MIXPANEL_STORE'
}

export interface MixpanelStruct {
    [index: string]: { [key: string]: string | string[] | boolean | number };
}

export interface CommonState {
    stylists: Option[];
    stylistsMatchs: Stylist[];
    bookingSource: string | undefined;
    zeStatus: boolean;
    mixpanelStore: MixpanelStruct;
}

export type Actions =
    | IActionWithPayload<ActionTypes.LOAD_MATCHES_SUCCESS, Stylist[]>
    | IActionWithPayload<ActionTypes.SET_ZE_ON, boolean>
    | IActionWithPayload<ActionTypes.SET_ZE_OFF, boolean>
    | IActionWithPayload<ActionTypes.SET_MIXPANEL_STORE, MixpanelStruct>;
