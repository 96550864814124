import { IActionWithPayload } from '../types';

export enum ActionTypes {
    SET_GRID_SIZE = 'room/SET_GRID_SIZE'
}
export type GridSize = 'small' | 'large';
export interface ProfileState {
    gridSize: GridSize;
}

export type Actions = IActionWithPayload<ActionTypes.SET_GRID_SIZE, GridSize>;
