import { Dispatch } from 'redux';
import { AuthService, User } from 'services';
import { ICredentials } from 'services/auth-service';
import { MIXPANEL_EVENTS, registerProperties, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { clearBookingsSelection } from 'store/booking/actions';
import { setupError } from 'store/error/actions';
import { toggleModal } from 'store/ui/actions';
import { CoreUser, SystemUser } from 'types/user';

import { ActionTypes } from './types';

export const logoutUser = (dispatch: Dispatch) =>
    dispatch({
        type: ActionTypes.LOGOUT_USER
    });
export const updateUser = (dispatch: Dispatch, user: CoreUser) => {
    dispatch({
        type: ActionTypes.UPDATE_USER,
        payload: user
    });
    toggleModal(dispatch, { type: null });
};
export const login = async (
    dispatch: Dispatch,
    credentials: Partial<ICredentials>,
    source?: string
) => {
    try {
        const user = await AuthService.login(credentials);
        if (user?.error) {
            setupError(dispatch, {
                errors: [user?.error]
            });
            return;
        }

        if (user) {
            registerProperties({
                Gender: user.gender,
                'User Type': 'stylist',
                'Order Count': user.counters.purchases,
                'Booking Count': user.bookings_count,
                'User status': user.level,
                Country: user.country
            });

            if (source === 'form')
                trackEvent({
                    name: MIXPANEL_EVENTS.LOGIN,
                    properties: {}
                });
            updateUser(dispatch, user);
            return user;
        } else {
            dispatch({
                type: ActionTypes.LOGOUT_USER,
                payload: null
            });
            toggleModal(dispatch, { type: null });
        }
    } catch (err) {
        setupError(dispatch, {
            error: err,
            message: (err as any)?.message,
            errors: (err as any).errors
        });
    }
};
export const logout = (dispatch: Dispatch) => {
    AuthService.logout();
    logoutUser(dispatch);
    clearBookingsSelection(dispatch);
};

export const updateGeneralData = async (dispatch: Dispatch, data: SystemUser) => {
    try {
        await User.update(data);
        const { data: newUser } = await User.get(data.user_uuid);
        updateUser(dispatch, newUser);
        return;
    } catch (err) {
        sentryException(err as Error, 'Cannot update general user data');
        setupError(dispatch, {
            error: err,
            message: (err as any)?.message,
            errors: (err as any).errors
        });
    }
};
export const updateProfilePicture = async (
    dispatch: Dispatch,
    files: FileList,
    { user_uuid, token }: { user_uuid: string; token: string }
) => {
    try {
        const formData = new FormData();

        formData.append('picture', files[0], files[0].name);
        await User.profilePicture({
            uuid: user_uuid,
            token: token,
            data: formData
        });
        const { data } = await User.get(user_uuid);
        updateUser(dispatch, data);
        return;
    } catch (err) {
        sentryException(err as Error, "Cannot update user's profile picture");
    }
};
