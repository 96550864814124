import '../style.scss';

import React from 'react';
import { BookingQueue, QueueType } from 'types/booking';

interface IQueueTitle {
    queues: BookingQueue[];
    selectedQueue: QueueType | undefined;
    onBack: () => void;
}

const QueueTitle: React.FC<IQueueTitle> = ({ queues, selectedQueue, onBack }) => {
    const count = queues.find((queue) => queue.key === selectedQueue)?.count;
    const message = `You have ${count} ${selectedQueue?.toLowerCase()}`;

    return (
        <div className="back">
            <div className="back-btn" onClick={onBack}>
                <label>{selectedQueue}</label>
            </div>
            <p>{message}</p>
        </div>
    );
};

export default QueueTitle;
