import './style.scss';

import { useWithDispatch } from 'hooks';
import React from 'react';
import { setActiveQueue, updateSelectedBookingId } from 'store/booking/actions';
import { Filter } from 'store/booking/types';
import { BookingQueue, QueueType } from 'types/booking';

import Queue from './components/Queue';
interface IBookingQueues {
    bookingQueues: BookingQueue[];
    selectedQueue: QueueType | undefined;
    onQueueChange: (queue: QueueType) => void;
    setFilter: (filters: Filter, loadStatus: boolean) => void;
}

const Queues: React.FC<IBookingQueues> = ({
    bookingQueues,
    selectedQueue,
    onQueueChange,
    setFilter
}) => {
    const setActiveQueueAction = useWithDispatch(setActiveQueue);
    const updateSelectedBookingIdAction = useWithDispatch(updateSelectedBookingId);

    const onQueueChanged = (queue: QueueType) => {
        if (queue === selectedQueue) return;
        updateSelectedBookingIdAction(null);
        setActiveQueueAction(queue);
        onQueueChange(queue);
        setFilter({ queue }, true);
    };

    return (
        <div className="book-status-grid">
            {bookingQueues.map((queue, index) => (
                <Queue
                    key={index}
                    queue={queue}
                    isSelected={selectedQueue === queue.key}
                    onQueueChanged={onQueueChanged}
                />
            ))}
        </div>
    );
};

export default Queues;
