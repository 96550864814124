import './style.scss';

import { profile as texts } from '../../../content.json';

interface IEmpty {
    section: string;
}

const Empty: React.FC<IEmpty> = ({ section }) => (
    <div className="profile-empty">
        <p>{texts.empty.split('%section%').join(section)}</p>
        <img src={texts.emptyIcon} alt="" />
    </div>
);
export default Empty;
