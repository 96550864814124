import { connect } from 'react-redux';
import { changeModal, toggleModal } from 'store/ui/actions';

import { stopLoading } from '../../../redux/reducers/users/actions';
import ResetPassword from './ResetPassword';

const mapStateToProps = ({ users: { error } }) => ({ error });

const mapDispatchToProps = (dispatch) => ({
    changeModal: (type) => changeModal(dispatch, { type }),
    toggleModal: (data) => toggleModal(dispatch, data),
    resetPasswordFailed: (err) => dispatch(stopLoading(err))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
