import './style.scss';

import React from 'react';
import { Modal } from 'react-bootstrap';

import { modal } from '../../../content';

const ResetConfirmation = () => (
    <span className="reset-confirmation">
        <Modal.Header closeButton>
            <Modal.Title>{modal.reset.confirmation}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>
                {modal.reset.confirmationText}
                <a href="mailto:hello@wishi.me">{modal.reset.email}</a>
            </p>
        </Modal.Body>
    </span>
);
export default ResetConfirmation;
