import axios from 'axios';

import Config from './Config';
import { getUserToken } from './utils/token-utils';

const Style = {
    urls: {
        getMatches: (user_id) => `${Config.get('apiroot2')}user/${user_id}/bestMatchesNew`
    },
    getOutfit: (outfit_uuid, uuid = 'null') =>
        axios.get(`${Config.get('apiroot2')}outfit/web/${outfit_uuid}/${uuid}`, {
            headers: { token: getUserToken() }
        }),
    getOutfits: (user_uuid, stylist_uuid) =>
        axios.get(`${Config.get('apiroot2')}outfit/list/web/paging/${user_uuid}/${stylist_uuid}`, {
            params: { outfits: 1, from: 1, count: 50 },
            headers: { token: getUserToken() }
        }),
    getItem: (item_uuid, user_uuid) =>
        axios.get(`${Config.get('apiroot2')}item/catalog/${item_uuid}`, {
            params: { user_uuid },
            headers: { token: getUserToken() }
        }),
    filters: () =>
        axios.get(`${Config.get('apiroot2')}giftItem/filters?client=web`, {
            headers: { token: getUserToken() }
        }),
    getRequest: (request_uuid) =>
        axios.get(`${Config.get('apiroot2')}styleRequest/retrieve/${request_uuid}`, {
            headers: { token: getUserToken() }
        }),
    tags: (gender, type) =>
        axios.get(`${Config.get('apiroot2')}tag/active`, {
            params: { gender, type },
            headers: { token: getUserToken() }
        }),
};

export default Style;
