import 'react-intl-tel-input/dist/main.css';
import '../style.scss';

import { useEffect, useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { registerProperties } from 'services/mixpanel';

const Phone = ({ onChange, number = '', country = 'us' }) => {
    const [phone, setPhone] = useState('');
    useEffect(() => {
        setPhone(number);
    }, [number]);

    const setCaretPosition = (caretPos) => {
        const elem = document.getElementsByClassName('phone-number-input')[0];

        if (elem) {
            elem.focus();
            setTimeout(() => {
                if (elem.setSelectionRange) elem.setSelectionRange(caretPos, caretPos);
            }, 0);
        }
    };

    return (
        <IntlTelInput
            containerClassName="intl-tel-input"
            inputClassName="phone-number-input"
            defaultCountry={country}
            autoPlaceholder={false}
            value={phone}
            id="input_phone"
            onPhoneNumberChange={(status, value, countryData, number) => {
                if (/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(number) || number === '') {
                    setCaretPosition(number.length);
                    setPhone(number);
                    onChange({ text: number });
                    registerProperties({ Country: country });
                }
            }}
        />
    );
};

export default Phone;
