import { Dispatch } from 'redux';
import { stylistActionFailed } from 'redux/reducers/stylists/actions';
import { Cookies, Stylist } from 'services';
import ReduxService from 'services/redux-service';
import { sentryException } from 'services/SentryLogging';
import SessionManagment from 'services/SessionManagment';
import { getFollowUpIndex, isArchiveOrInquiries, isInquiries } from 'services/utils/bookings-utils';
import { getUserToken } from 'services/utils/token-utils';
import { store } from 'store';
import { BookingQueue, BookingType, FilterOption, QueueType } from 'types/booking';
import { Stylist as StylistType } from 'types/user';

import {
    ActionTypes,
    BookingdParams,
    BookingsPaginate,
    BookingStatus,
    Filter,
    FiltersCategory,
    LinksResponse,
    SessionMetaResponse,
    SingleBookingStatus
} from './types';

export const bookStylist = (dispatch: Dispatch, stylist: StylistType) =>
    dispatch({
        type: ActionTypes.BOOK_STYLIST,
        payload: stylist
    });
export const clearBookingsSelection = (dispatch: Dispatch) =>
    dispatch({
        type: ActionTypes.CLEAR_BOOKINGS_SELECTION
    });
export const clearBookingsList = (dispatch: Dispatch) =>
    dispatch({
        type: ActionTypes.CLEAR_BOOKINGS_LIST
    });
export const setTotalBookings = (dispatch: Dispatch, payload = 0) =>
    dispatch({
        type: ActionTypes.SET_TOTAL_BOOKINGS,
        payload
    });
export const setActiveQueue = (dispatch: Dispatch, payload: QueueType) =>
    dispatch({
        type: ActionTypes.SET_ACTIVE_QUEUE,
        payload
    });
export const saveCurrentPage = (dispatch: Dispatch, payload: number) =>
    dispatch({
        type: ActionTypes.SAVE_CURRENT_PAGE,
        payload
    });
export const setError = (dispatch: Dispatch) =>
    dispatch({
        type: ActionTypes.SET_ERROR
    });
export const loadBookings = async (
    dispatch: Dispatch,
    { params }: BookingdParams,
    loadBookingCount = true
) => {
    const user = Cookies.get('user');
    if (!user) return;
    try {
        setLoader(dispatch, true);
        const { bookings } = store.getState().booking;
        let filters = getFilters();
        const isFirstTime = !filters?.queue;
        const isArchiveOrInquiriesQueue = isArchiveOrInquiries(filters.queue);
        const filterParams = isArchiveOrInquiriesQueue ? [] : filters?.filter;

        if (loadBookingCount) {
            if (isFirstTime) {
                await firstTimeLoad(dispatch);
                filters = getFilters();
            } else loadBookingsCount(dispatch, filterParams);
        }

        if (!params?.page) params = { ...params, page: 1 };
        if (params.page === 1) clearBookingsList(dispatch);

        if (!isArchiveOrInquiriesQueue) saveCurrentPage(dispatch, params.page ?? 1);
        if (!params.filters) params.filters = filterParams;
        if (!params?.queue) params = { ...params, queue: filters.queue };

        await ReduxService.fetch({
            dispatch,
            targetAction: ActionTypes.LOAD_BOOKINGS,
            url: SessionManagment.urls.sessions(user.uuid),
            params,
            config: { headers: { token: user.token } },
            prettifyData: (data: {
                items: BookingType[];
                meta: SessionMetaResponse;
                links: LinksResponse;
            }) => {
                const pagination = {
                    meta: data.meta,
                    links: data.links
                };
                setTotalBookings(dispatch, data.meta.totalItems);
                setBookingsPagination(dispatch, pagination);
                setLoader(dispatch, false);
                return params?.page === 1 ? data.items : bookings.concat(data.items);
            }
        });
    } catch (error) {
        setLoader(dispatch, false);
        sentryException(error as Error, "Can't Load Bookings");
    }
};
export const refreshBookings = async (dispatch: Dispatch, bookings: BookingType[]) => {
    dispatch({
        type: ActionTypes.REFRESH_BOOKINGS,
        payload: bookings
    });
};
export const archiveSession = async (
    dispatch: Dispatch,
    session_id: string,
    isArchive: boolean
) => {
    const user = Cookies.get('user');
    const { filters, bookings } = store.getState().booking;

    if (user) {
        const updatedBookings = bookings.filter((booking) => booking.session.sid != session_id);

        if (isArchive) await Stylist.unarchiveSession(user.uuid, session_id);
        else await Stylist.archiveSession(user.uuid, session_id);

        if (filters.search) {
            const { data } = await SessionManagment.getSingleBooking(
                user.uuid,
                session_id,
                user.token
            );
            refreshBookings(dispatch, [data]);
        } else {
            refreshBookings(dispatch, updatedBookings);
            loadBookingsCount(dispatch, filters.filter);
        }
    }
};
export const setFilter = (dispatch: Dispatch, filters: Filter) => {
    dispatch({
        type: ActionTypes.SET_FILTER,
        payload: filters
    });
};
export const updateUnreadMessages = async (
    dispatch: Dispatch,
    session_id: string,
    isRead: boolean
) => {
    dispatch({
        type: ActionTypes.UPDATE_UNREAD_MESSAGES,
        payload: { session_id, isRead }
    });
};

export const changeImportanceLevel = async (
    dispatch: Dispatch,
    channel_id: string,
    isImportance = true
) => {
    try {
        const { token } = Cookies.get('user');
        await Stylist.important({
            channel_id,
            params: { enabled: isImportance },
            token
        });

        dispatch({
            type: ActionTypes.UPDATE_IMPORTANCE_LEVEL,
            payload: { channel_id, isImportance }
        });
    } catch (error) {
        sentryException(error as Error, 'Cannot change importance level');
        dispatch(stylistActionFailed(error, 'important'));
    }
};

export const loadClients = async (dispatch: Dispatch, params?: { from: number; count: number }) => {
    try {
        const _params = { ...params };
        if (!_params?.from) _params.from = 0;
        if (!_params?.count) _params.count = 1000;
        const user = Cookies.get('user');
        await ReduxService.fetch({
            dispatch,
            targetAction: ActionTypes.LOAD_CLIENTS_FOR_STYLIST,
            url: Stylist.urls.clients(user?.uuid),
            params: _params,
            config: { headers: { token: getUserToken() } },
            prettifyData: ({ items }: { items: any[] }) =>
                items.map((client: any) => ({
                    value: client.channel_sid,
                    label: client.user_name
                }))
        });
    } catch (error) {
        sentryException(error as Error, "Can't Load Stylist Clients name");
    }
};

export const loadSingleBooking = async (dispatch: Dispatch, channel_id: string) => {
    try {
        const user = Cookies.get('user');
        const params = { token: user.token };
        setLoader(dispatch, true);
        await ReduxService.fetch({
            dispatch,
            targetAction: ActionTypes.LOAD_BOOKINGS,
            url: SessionManagment.urls.searchBooking(user.uuid, channel_id),
            params,
            prettifyData: (data: BookingType) => {
                setLoader(dispatch, false);
                setFilter(dispatch, { search: channel_id });
                setTotalBookings(dispatch, 1);
                return [data];
            }
        });
    } catch (error) {
        setLoader(dispatch, false);
        sentryException(error as Error, "Can't Load Single Booking");
    }
};

export const updateSelectedBookingId = (dispatch: Dispatch, channel_id: string | null) =>
    dispatch({
        type: ActionTypes.UPDATE_SELECTED_BOOKING_ID,
        payload: channel_id
    });

export const updateSelectedFilters = (
    dispatch: Dispatch,
    filters: Record<string, FilterOption[]>
) => {
    if (filters?.sortBy[0]?.key === 'latest') updateSelectedBookingId(dispatch, null);

    dispatch({
        type: ActionTypes.UPDATE_SELECTED_FILTERS,
        payload: filters
    });
};

export const loadBookingsConfig = async (dispatch: Dispatch) => {
    try {
        const { token } = Cookies.get('user');
        const res = await SessionManagment.getSessionConfig(token);

        dispatch({
            type: ActionTypes.SET_BOOKINGS_NETADATA,
            payload: res.data
        });
    } catch (error) {
        setLoader(dispatch, false);
        sentryException(error as Error, "Can't Load Bookings Config");
    }
};

export const loadBookingsCount = async (
    dispatch: Dispatch,
    sessionFilters: string[] = [],
    updateQueue = true
) => {
    try {
        const user = Cookies.get('user');
        const { bookingStatus } = store.getState().booking;
        if (user) {
            await ReduxService.fetch({
                dispatch,
                targetAction: ActionTypes.LOAD_BOOKINGS_COUNT,
                url: SessionManagment.urls.sessionCount(user.uuid),
                params: { filters: sessionFilters },
                config: { headers: { token: user.token } },
                prettifyData: (data: BookingStatus) => {
                    const { queues, inquiriesAndArchive } = splitSessionCountData(data.queues);
                    const payload = {
                        ...data,
                        inquiriesAndArchive,
                        queues: !updateQueue ? bookingStatus.queues : queues,
                        activeQueue: bookingStatus.activeQueue
                    };
                    return payload;
                }
            });
        }
    } catch (error) {
        setLoader(dispatch, false);
        sentryException(error as Error, "Can't Load Bookings Count");
    }
};

export const setBookingsPagination = (dispatch: Dispatch, bookingPaginate: BookingsPaginate) => {
    dispatch({
        type: ActionTypes.SET_BOOKING_PAGINATE,
        payload: bookingPaginate
    });
};

export const setLoader = (dispatch: Dispatch, loader: boolean) => {
    dispatch({
        type: ActionTypes.SET_LOADING,
        payload: loader
    });
};

export const updateSingleBooking = async (
    dispatch: Dispatch,
    channel_id: string,
    status?: SingleBookingStatus
) => {
    try {
        const user = Cookies.get('user');
        if (!user) return;

        const { data } = await SessionManagment.getSingleBooking(user.uuid, channel_id, user.token);
        const bookingStatus = getBookingStatus(data, status);

        dispatch({
            type: ActionTypes.UPDATE_SINGLE_BOOKING_STATUS,
            payload: { booking: data, status: bookingStatus }
        });
    } catch (error) {
        sentryException(error as Error, 'Cannot upadte single booking');
    }
};

const getBookingStatus = (booking: BookingType, status?: SingleBookingStatus) => {
    if (status) return status;
    if (isInquiries(booking.session.type)) return SingleBookingStatus.inquiriesReply;
    if (getFollowUpIndex(booking) > -1) return SingleBookingStatus.followUp;
};

const splitSessionCountData = (queues: BookingQueue[]) => {
    const inquiriesAndArchive: BookingQueue[] = [];
    const newQueues = queues
        .filter((queue: BookingQueue) => {
            if (queue.priority >= 0) return queue;
            inquiriesAndArchive.push(queue);
        })
        .sort((a: BookingQueue, b: BookingQueue) => a.priority - b.priority);

    return { queues: newQueues, inquiriesAndArchive };
};

const getActiveQueue = (queues: BookingQueue[], defaultQueue: number) => {
    const { filters } = store.getState().booking;
    const selectedQueue = queues.findIndex((queue: BookingQueue) => queue.key === filters.queue);
    const defaultQueueIndex = queues.findIndex(
        (queue: BookingQueue) => queue.priority === defaultQueue
    );

    const index = selectedQueue >= 0 ? selectedQueue : defaultQueueIndex;
    return index >= 0 && index < queues.length ? queues[index].key : undefined;
};

const firstTimeLoad = async (dispatch: Dispatch) => {
    await loadBookingsCount(dispatch);
    const { bookingStatus } = store.getState().booking;
    const activeQueue = getActiveQueue(bookingStatus.queues, bookingStatus.highestNonZeroPriority);
    if (activeQueue) {
        setActiveQueue(dispatch, activeQueue);
        setFilter(dispatch, { queue: activeQueue });
    }
};

const getFilters = () => store.getState().booking.filters;
