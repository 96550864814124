import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { initMixpanel } from 'services/mixpanel';

import App from './App';
import SentryLogging from './services/SentryLogging';
import { store } from './store';

const getEnvFilePath = () => {
    if (process.env.NODE_ENV === 'production') return `.env.production`;
    return `.env.production`;
};

require('dotenv').config({ path: getEnvFilePath() });
const queryClient = new QueryClient();

SentryLogging();
initMixpanel();

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Router>
                <App />
            </Router>
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
);
