import { Cookies, User } from 'services';
import { toggleModal } from 'store/ui/actions';
import { logout } from 'store/user/actions';

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const CHANGE_MODAL = 'CHANGE_MODAL';
export const CHOSEN_ITEM = 'CHOSEN_ITEM';

export const SET_CART_FILTER = 'SET_CART_FILTER';
export const SET_CART_FILTERS = 'SET_CART_FILTERS';

export const TOGGLE_BIRTHDAY_MESSAGE = 'TOGGLE_BIRTHDAY_MESSAGE';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';

export const UPDATE_USER_CLOSET = 'UPDATE_USER_CLOSET';
export const CLEAR_USER_CLOSET = 'CLEAR_USER_CLOSET';
export const CLEAR_USER_ORDERS = 'CLEAR_USER_ORDERS';
export const UPDATE_PROFILE_FILTERS = 'UPDATE_PROFILE_FILTERS';
export const UPDATE_PROFILE_SELECTED_FILTERS = 'UPDATE_PROFILE_SELECTED_FILTERS';
export const CLEAR_PROFILE_SELECTED_FILTERS = 'CLEAR_PROFILE_SELECTED_FILTERS';
export const START_FILTERS_LOADING = 'START_FILTERS_LOADING';
export const UPDATE_USER_PHOTOS = 'UPDATE_USER_PHOTOS';
export const UPDATE_USER_LOOKS = 'UPDATE_USER_LOOKS';
export const UPDATE_USER_ORDERS = 'UPDATE_USER_ORDERS';
export const CLEAR_USER_PHOTOS = 'CLEAR_USER_PHOTOS';
export const UPDATE_CLOSET_TAGS = 'UPDATE_CLOSET_TAGS';
export const SET_CURRENT_ITEM = 'SET_CURRENT_ITEM';
export const startLoading = () => ({
    type: START_LOADING
});

export const startFiltersLoading = () => ({
    type: START_FILTERS_LOADING
});

export const stopLoading = (error) => ({
    type: STOP_LOADING,
    payload: { error }
});

export const setCartFilters = (filters) => ({
    type: SET_CART_FILTERS,
    payload: { filters }
});

export const toggleBirthdaMessage = () => ({
    type: TOGGLE_BIRTHDAY_MESSAGE
});

export const updateOrders = ({ items, quota_max, nextPage }) => ({
    type: UPDATE_ORDERS,
    payload: { orders: items, total: quota_max, nextPage }
});

export const clearCloset = () => ({
    type: CLEAR_USER_CLOSET
});

export const updateCloset = (items, total) => ({
    type: UPDATE_USER_CLOSET,
    payload: { items, total }
});

export const updatePhotos = (photos, total) => ({
    type: UPDATE_USER_PHOTOS,
    payload: { photos, total }
});

export const clearPhotos = () => ({
    type: CLEAR_USER_PHOTOS
});

export const clearOrders = () => ({
    type: CLEAR_USER_ORDERS
});

export const updateProfileFilters = (filters, type) => ({
    type: UPDATE_PROFILE_FILTERS,
    payload: { filters, type }
});

export const clearProfileSelectedFilters = () => ({
    type: CLEAR_PROFILE_SELECTED_FILTERS
});

export const updateLooks = (items, total) => ({
    type: UPDATE_USER_LOOKS,
    payload: { items, total }
});

export const updateClosetTags = (tags) => ({
    type: UPDATE_CLOSET_TAGS,
    payload: { tags }
});

export const setCurrentItem = (item) => ({
    type: SET_CURRENT_ITEM,
    payload: { item }
});

export const loadCartFilters = (user_uuid, params = {}) => {
    return async (dispatch) => {
        try {
            const user = Cookies.get('user');
            if (user) {
                const { data } = await User.cartFilters(user_uuid ? user_uuid : user.uuid, params);
                dispatch(clearProfileSelectedFilters());
                dispatch(setCartFilters(data));
            }
        } catch (error) {
            dispatch(stopLoading(error));
        }
    };
};

export const loadCloset = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                users: { closet = [] },
                user: { user }
            } = getState();
            if (!params.from) params.from = 0;
            if (!params.count) params.count = 50;
            if (params.from === 0) dispatch(clearCloset());
            const {
                data: { items, quota_max }
            } = await User.closet({ user_uuid: user.user_uuid, params });
            dispatch(
                updateCloset(params.from === 0 ? items : [...closet, ...items], parseInt(quota_max))
            );
        } catch (error) {
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const loadPhotos = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                users: { photos = [] },
                user: { user }
            } = getState();
            if (!params.from) params.from = 0;
            // if (!params.count) params.count = 50;
            if (params.from === 0) dispatch(clearPhotos());
            const {
                data: { items, quota_max }
            } = await User.photos({
                user_uuid: user.user_uuid,
                params
            });
            dispatch(
                updatePhotos(params.from === 0 ? items : [...photos, ...items], parseInt(quota_max))
            );
        } catch (error) {
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const loadClosetFilters = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startFiltersLoading());
        try {
            const {
                user: { user }
            } = getState();
            if (!params.client) params.client = 'web';
            if (!params.gender) params.gender = user.gender;
            params.refinements = 'USER_SPECIFIC';
            const { data } = await User.closetFilters({
                user_uuid: user.user_uuid,
                params
            });
            dispatch(clearProfileSelectedFilters());
            dispatch(updateProfileFilters(data, 'closet'));
        } catch (error) {
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const updateSelectedFilters = (update = {}, remove = false, callback) => {
    return async (dispatch, getState) => {
        const {
            users: { selectedFilters }
        } = getState();
        let selected = { ...selectedFilters };
        const SINGLE_SELECTION = ['category_uuid'];
        if (remove) {
            selected[update.key] = selected[update.key].filter(
                (item) => item.key !== update.value.key
            );
        } else {
            if (SINGLE_SELECTION.includes(update.key)) {
                selected[update.key] = [update.value];
            } else {
                if (!selected[update.key]) selected[update.key] = [];
                selected = {
                    ...selected,
                    [update.key]: [...selected[update.key], update.value]
                };
            }
        }
        dispatch({
            type: UPDATE_PROFILE_SELECTED_FILTERS,
            payload: { selected }
        });
        if (callback) callback(selected);
    };
};

export const removeItem = (uuid) => {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            await User.removeItem(uuid);
            dispatch(loadCloset());
            dispatch(loadClosetFilters());
        } catch (error) {
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const loadLooks = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                users: { boards = [] },
                user: { user }
            } = getState();
            if (!params.from) params.from = 1;
            if (!params.count) params.count = 50;
            const {
                data: { items, quota_max }
            } = await User.looks({
                user_uuid: user.user_uuid,
                params
            });
            dispatch(
                updateLooks(params.from === 1 ? items : [...boards, ...items], parseInt(quota_max))
            );
        } catch (error) {
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const loadLooksFilters = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startFiltersLoading());
        try {
            const {
                user: { user }
            } = getState();
            if (!params.client) params.client = 'web';
            if (!params.gender) params.gender = user.gender;
            const { data } = await User.looksFilters({
                user_uuid: user.user_uuid,
                params
            });
            dispatch(clearProfileSelectedFilters());
            dispatch(updateProfileFilters(data, 'looks'));
        } catch (error) {
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const removeLook = (look_uuid, params) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                user: { user }
            } = getState();
            await User.removeLook({
                user_uuid: user.user_uuid,
                look_uuid
            });
            dispatch(loadLooks(params));
        } catch (error) {
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const removePhoto = (photo_uuid) => {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const { data } = await User.removePhoto(photo_uuid);
            if (data.message === 'fail') {
                dispatch(stopLoading(data.message));
            } else {
                dispatch(loadPhotos());
            }
        } catch (error) {
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const loadOrders = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                users: { orders = [] },
                user: { user }
            } = getState();
            if (!params.from) params.from = 1;
            if (!params.count) params.count = 30;

            const { data } = await User.orders({
                user_uuid: params.user_uuid || user.user_uuid,
                params
            });

            dispatch(
                updateOrders({
                    items: params.from === 1 ? data.items : orders.concat(data.items),
                    quota_max: parseInt(data.quota_max),
                    nextPage: data.nextPage
                })
            );
        } catch (error) {
            dispatch(stopLoading({ view: 'profile', error }));
        }
    };
};

export const loadClosetTags = (params = {}) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            const {
                users: { closetTags },
                user: { user }
            } = getState();
            if (!params.from) params.from = 0;
            if (!params.count) params.count = 30;
            const { data } = await User.closetTags({
                user_uuid: user.user_uuid,
                params
            });
            dispatch(updateClosetTags(params.from === 0 ? data : [...closetTags, ...data]));
        } catch (error) {
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const addClosetItem = (item) => {
    return async (dispatch) => {
        try {
            await User.addItem(item);
            dispatch(loadCloset());
            dispatch(loadClosetFilters());
            toggleModal(dispatch);
        } catch (error) {
            toggleModal(dispatch);
            dispatch(stopLoading({ profile: true, error }));
        }
    };
};

export const deactivate = () => {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const user = Cookies.get('user');
            const token = user ? user.token : null;
            const { data } = await User.deactivate({
                user_uuid: user.uuid,
                token
            });
            if (data.error) {
                dispatch(stopLoading(data.error));
            } else {
                toggleModal(dispatch);
                logout(dispatch);
            }
        } catch (error) {
            toggleModal(dispatch);
            dispatch(stopLoading({ error }));
        }
    };
};
