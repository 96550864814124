import './style.scss';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { sentryException } from 'services/SentryLogging';

import { modal } from '../../../content';

const RemoveLook = ({ removeLook, data, toggleModal }) => {
    const onSubmit = () => {
        try {
            removeLook(data.look.uuid, { type: data.type });
            toggleModal();
        } catch (e) {
            sentryException(e, "Couldn't remove look");
        }
    };

    return (
        <span className="remove-look">
            <Modal.Header>
                <p>{modal.removeLook.title}</p>
            </Modal.Header>

            <Modal.Footer>
                <Button variant="light" onClick={() => toggleModal()}>
                    {modal.removeLook.cancel}
                </Button>
                <Button variant="dark" onClick={onSubmit}>
                    {modal.removeLook.submit}
                </Button>
            </Modal.Footer>
        </span>
    );
};

export default RemoveLook;
