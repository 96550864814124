import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { Actions } from '../actions';
import { ActionTypes, CommonState } from './types';

const initialState: CommonState = {
    stylists: [],
    stylistsMatchs: [],
    bookingSource: undefined,
    zeStatus: false,
    mixpanelStore: {}
};

const commonReducer = (state = initialState, action: Actions): CommonState => {
    switch (action.type) {
        case ActionTypes.LOAD_MATCHES_SUCCESS:
            return {
                ...state,
                stylistsMatchs: action.payload
            };
        case ActionTypes.SET_ZE_ON:
            return {
                ...state,
                zeStatus: true
            };
        case ActionTypes.SET_ZE_OFF:
            return {
                ...state,
                zeStatus: false
            };
        default:
            return state;
    }
};
export const name = 'common';
export const useCommonStore: TypedUseSelectorHook<CommonState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
export default commonReducer;
