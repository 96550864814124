import './style.scss';

import { connect } from 'react-redux';

import {
    addToCanvas,
    clearBoards,
    clearCloset,
    clearSelectedFilters,
    clearShop,
    clearStore,
    loadBoards,
    loadBoardsFilters,
    loadClientCart,
    loadCloset,
    loadClosetFilters,
    loadInspiration,
    loadInspirationTags,
    loadOrdersFilters,
    loadShop,
    loadShopFilters,
    loadStore,
    loadStoreFilters,
    loadWishlist,
    removeStoreItem,
    setGridSize,
    updateGender,
    updateStylingRoomFilters
} from '../../../redux/reducers/stylingRoom/actions';
import { favorite, loadStylistFavorites } from '../../../redux/reducers/stylists/actions';
import { clearOrders, loadCartFilters, loadOrders } from '../../../redux/reducers/users/actions';
import { Boards as BoardsComponent } from './Boards';
import { Closet as ClosetComponent } from './Closet';
import { Inspiration as InspirationComponent } from './Inspiration';
import { Shop as ShopComponent } from './Shop';
import { Store as StoreComponent } from './Store';

const mapStateToProps = ({
    users: { cartFilters, orders, ordersTotal },
    user: { user },
    stylingRoom,
    stylists: { favorites, favoritesTotal },
    style: { tags }
}) => ({
    ...stylingRoom,
    cartFilters,
    orders,
    ordersTotal,
    user,
    favorites,
    favoritesTotal,
    tags
});

const mapDispatchToProps = (dispatch) => ({
    loadCloset: (params) => dispatch(loadCloset(params)),
    loadShop: (params) => dispatch(loadShop(params)),
    loadStore: (params) => dispatch(loadStore(params)),
    loadInspiration: (params) => dispatch(loadInspiration(params)),
    loadBoards: (params) => dispatch(loadBoards(params)),
    loadShopFilters: (params) => dispatch(loadShopFilters(params)),
    loadClosetFilters: (params) => dispatch(loadClosetFilters(params)),
    loadStoreFilters: (params) => dispatch(loadStoreFilters(params)),
    loadInspirationTags: (params) => dispatch(loadInspirationTags(params)),
    loadBoardsFilters: (params) => dispatch(loadBoardsFilters(params)),
    loadCartFilters: (user_uuid, params) => dispatch(loadCartFilters(user_uuid, params)),
    loadOrdersFilters: (user_uuid, params) => dispatch(loadOrdersFilters(user_uuid, params)),
    updateFilters: (filter, remove, callback) =>
        dispatch(updateStylingRoomFilters(filter, remove, callback)),
    loadStylistFavorites: (params) => dispatch(loadStylistFavorites(params)),
    favorite: (item, attr) => dispatch(favorite(item, attr)),
    updateGender: (gender) => dispatch(updateGender(gender)),
    setGridSize: (size) => dispatch(setGridSize(size)),
    clearShop: () => dispatch(clearShop()),
    clearBoards: () => dispatch(clearBoards()),
    clearCloset: () => dispatch(clearCloset()),
    clearStore: () => dispatch(clearStore()),
    clearSelectedFilters: () => dispatch(clearSelectedFilters()),
    addToCanvas: (item, type) => dispatch(addToCanvas(item, type)),
    removeStoreItem: (item) => dispatch(removeStoreItem(item)),
    loadClientCart: (user_uuid, params) => dispatch(loadClientCart(user_uuid, params)),
    loadOrders: (params) => dispatch(loadOrders(params)),
    clearOrders: () => dispatch(clearOrders()),
    loadWishlist: (params) => dispatch(loadWishlist(params))
});

export const Boards = connect(mapStateToProps, mapDispatchToProps)(BoardsComponent);
export const Closet = connect(mapStateToProps, mapDispatchToProps)(ClosetComponent);
export const Inspiration = connect(mapStateToProps, mapDispatchToProps)(InspirationComponent);
export const Shop = connect(mapStateToProps, mapDispatchToProps)(ShopComponent);
export const Store = connect(mapStateToProps, mapDispatchToProps)(StoreComponent);
