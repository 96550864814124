import './style.scss';

import ConfettiGenerator from 'confetti-js';
import { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import { landing, logo } from '../../content';

const BirthdayMessage = ({ user, birthdayMessage, toggleBirthdaMessage }) => {
    useEffect(() => {
        if (birthdayMessage) {
            const options = {
                max: '200',
                size: '1',
                animate: true,
                props: ['circle'],
                colors: [
                    [255, 255, 255],
                    [255, 0, 0],
                    [0, 255, 0],
                    [0, 0, 255]
                ],
                clock: '120',
                width: `${window.innerWidth / 4}`,
                rotate: false,
                start_from_edge: true,
                respawn: false
            };
            const confettiRight = new ConfettiGenerator({ ...options, target: 'confetti-right' });
            const confettiLeft = new ConfettiGenerator({ ...options, target: 'confetti-left' });
            confettiRight.render();
            confettiLeft.render();

            return () => {
                confettiRight.clear();
                confettiLeft.clear();
            };
        }
    }, [birthdayMessage]);

    return birthdayMessage ? (
        <div className="birthday-message">
            <div className="close" onClick={toggleBirthdaMessage} />
            <canvas id="confetti-right" className="confetti right" />
            <canvas id="confetti-left" className="confetti left" />
            {user && (
                <Container>
                    <Row>
                        <Col>
                            <p>{landing.birthday.replace('%name%', user.first_name)}</p>
                            <p className="from">{landing.birthdayFrom}</p>
                            <Image src={logo.white} />
                        </Col>
                    </Row>
                </Container>
            )}
        </div>
    ) : (
        ''
    );
};

export default BirthdayMessage;
