import {
    faFacebookF,
    faInstagram,
    faLinkedin,
    faPinterestP
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { UserStyles } from 'types/styles/user';

interface ISocialIcons {
    client: UserStyles;
}

const SocialIcons: React.FC<ISocialIcons> = ({ client }) => {
    const [instagramUsername, setInstagramUsername] = useState('');
    const [facebookUsername, setFacebookUsername] = useState('');
    const [linkedinUsername, setLinkedinUsername] = useState('');
    const [pinterestUsername, setPinterestUsername] = useState('');

    useEffect(() => {
        const {
            facebook_username: facebook,
            pinterest_username: pinterest,
            instagram_username: instagram,
            linkedin_username: linkedin
        } = client;
        setInstagramUsername(
            instagram && instagram[0] === '@' ? instagram.substring(1) : instagram
        );
        setFacebookUsername(
            facebook && facebook.indexOf('/')
                ? facebook.substring(facebook.indexOf('/') + 1)
                : facebook
        );
        setLinkedinUsername(
            linkedin && linkedin.indexOf('/')
                ? linkedin.substring(linkedin.indexOf('/') + 1)
                : linkedin
        );
        setPinterestUsername(
            pinterest && pinterest[0] === '@' ? pinterest.substring(1) : pinterest
        );
    }, [
        client.facebook_username,
        client.pinterest_username,
        client.instagram_username,
        client.linkedin_username
    ]);
    return (
        <div className="social-icons">
            {instagramUsername && (
                <a
                    href={`http://instagram.com/${instagramUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
            )}
            {facebookUsername && (
                <a
                    href={`http://facebook.com/${facebookUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FontAwesomeIcon icon={faFacebookF} />
                </a>
            )}
            {linkedinUsername && (
                <a
                    href={`http://linkedin.com/${linkedinUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>
            )}
            {pinterestUsername && (
                <a
                    href={`http://pinterest.com/${pinterestUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FontAwesomeIcon icon={faPinterestP} />
                </a>
            )}
        </div>
    );
};
export default SocialIcons;
