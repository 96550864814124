import { KeyValue } from './utils';

export enum BookSessionType {
    archived = 'archived',
    inquiry = 'inquiry'
}

export enum loyalType {
    bronze = 'bronze',
    gold = 'gold',
    platinum = 'platinum'
}

export interface FilterOption {
    key: string;
    count: number;
    priority?: number;
    text?: string;
    singleSelect?: boolean;
    value?: string;
}
export interface BookingFilter {
    key: string;
    value: FilterOption;
}

// new bookings type for session management
export interface BookingType {
    client: ClientInfo;
    session: Session;
    actions: ButtonActions[];
}

export interface ClientInfo {
    fullName: string;
    firstName: string;
    lastName: string;
    loyalty?: string;
    uuid: string;
}

export interface Session {
    type: string;
    sid: string;
    description: string;
    lastModified: Date;
    dueDate: Date;
    labels: Label[];
    isFlaggedImportant: boolean;
    hasUnreadMessages: boolean;
    hasSessionView: boolean;
    isPendingFollowup: boolean;
    plan?: number;
    pricingModel?: string;
    looksCount?: number;
    looksMax?: number;
    price?: number;
    uuid: string;
}

export interface Label {
    text: string;
    type: number;
}

interface PlanType {
    text: string;
    background: string;
}

export interface LabelType {
    color: string;
    background: string;
    deprecated?: boolean;
}

interface ActionType {
    value: string;
}

interface SessionType {
    type: string;
}
export interface BookingConfigResponse {
    session: Record<string, SessionType>;
    actions: Record<string, ActionType>;
    plans: Record<string, PlanType>;
    labels: Record<string, LabelType>;
}

export interface ButtonActions {
    key: ButtonActionType;
    type: number;
    priority: number;
    isCta?: boolean;
}

export enum QueueType {
    all = 'all',
    new = 'new',
    previous = 'previous',
    active = 'active',
    important = 'important',
    dueToday = 'dueToday',
    overdue = 'overdue',
    inquiries = 'inquiries',
    archived = 'archived',
    newMessages = 'unread'
}

export enum ButtonActionType {
    chat = 'chat',
    restyle = 'restyle',
    archive = 'archive',
    moodboard = 'moodboard',
    styleboard = 'styleboard',
    forceEndSession = 'forceEndSession',
    requestEndSession = 'requestEndSession'
}

export interface BookingQueue {
    key: QueueType;
    value: string;
    count: number;
    priority: number;
    selected?: boolean;
}
