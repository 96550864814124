import { Lightbox, Loader } from 'components';
import React, { useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import Slider from 'react-slick';
import { User } from 'services';
import { Photo } from 'types/styling';
import { CACHE_TIME_MS } from './consts';

interface IPhotos {
    uuid: string;
}
const Photos: React.FC<IPhotos> = ({ uuid }) => {
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number>(-1);

    const fetchPhotos = async () => {
        const res = await User.photos({ user_uuid: uuid, params: {} });
        return res?.data;
    };
    const { data: photosData, isFetching } = useQuery<{ quota_max: number; items: Photo[] }>(
        ['user-photos', uuid],
        fetchPhotos,
        { staleTime: CACHE_TIME_MS }
    );
    const photos = photosData?.items ?? [];
    const hasPhotos = photos.length > 0;

    return (
        <div className="photos">
            {isFetching && <Loader />}
            {selectedPhotoIndex >= 0 && (
                <Lightbox
                    photos={photos.map((p) => p.photo_small) ?? []}
                    onClose={() => setSelectedPhotoIndex(-1)}
                />
            )}
            <Row>
                <Col>
                    {hasPhotos && (
                        <Slider
                            className="photos-carousel"
                            dots={false}
                            infinite={true}
                            centerMode={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                            autoplay={false}
                            arrows={true}
                        >
                            {photos.map((photo, index) => (
                                <Image
                                    key={index}
                                    src={photo.photo_small}
                                    onClick={() => setSelectedPhotoIndex(index)}
                                />
                            ))}
                        </Slider>
                    )}
                    {!hasPhotos && <span>No photos</span>}
                </Col>
            </Row>
        </div>
    );
};
export default Photos;
