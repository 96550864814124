import React, { useEffect, useState } from 'react';
import { ButtonGroup, Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Filters, GridItem, Loader, SearchBox } from '../../../components';
import { styling } from '../../../content.json';
import { Formatter } from '../../../services';
import {
    ClearFilterObject,
    FavouritesFilterParams,
    FilterOptionsParameters,
    FilterParams,
    FilterStruct,
    Gender,
    IStyleRequest,
    Item,
    ShopQueryParameters,
    StylingRoomSection
} from '../../../types/styling';
import { Empty, FilterBadges } from '../components';

interface IShop {
    loading: boolean;
    loadingFilters: () => void | undefined;
    loadShop: (input: ShopQueryParameters) => void;
    loadShopFilters: (fields: Record<string, string>) => void;
    styleRequest: IStyleRequest;
    updateFilters: (filter: FilterParams, isSelected: boolean, callback: () => void) => void;
    filters: FilterStruct[];
    filtersType: string;
    selectedFilters: Record<string, FilterOptionsParameters[]>;
    shop: Item[];
    updateGender: (gender: string | null) => void;
    gender: Gender;
    setGridSize: (input: string) => void;
    gridSize: string;
    loadStylistFavorites: (input: Record<string, string | number>) => void;
    favorites: Item[];
    favoritesTotal: number;
    favorite: (favouritesFilter: FavouritesFilterParams) => void;
    clearShop: () => void;
    clearSelectedFilters: () => void;
    addToCanvas: (item: Item, type: string) => void;
    total: number;
    itemsAvailability: Record<string, any>;
    nextPage: number;
    canvasLayout: string;
}

export const Shop: React.FC<IShop> = ({
    loading,
    loadingFilters,
    loadShop,
    loadShopFilters,
    styleRequest,
    updateFilters,
    filters,
    filtersType,
    selectedFilters,
    shop,
    updateGender,
    gender,
    setGridSize,
    gridSize,
    loadStylistFavorites,
    favorites,
    favoritesTotal,
    favorite,
    clearShop,
    clearSelectedFilters,
    addToCanvas,
    total,
    itemsAvailability,
    nextPage,
    canvasLayout
}) => {
    const [gridItems, setGridItems] = useState<Item[]>([]);
    const [keyword, setKeyword] = useState('');
    const [showFavorites, setShowFavorites] = useState(false);
    const [expandSearch, setExpandSearch] = useState(false);
    const [isLoadingShop, setIsLoadingShop] = useState(true);
    const [styleRetailers, setStyleRetailers] = useState<string[]>([]);
    const [submitted, setSubmitted] = useState(true);
    const [favoritesParams, setFavoritesParams] = useState<Record<string, any>>({});

    const fetchData = (filter?: Record<string, FilterOptionsParameters[]>) => {
        const activeFilters = filter ?? selectedFilters;
        const params = Object.keys(activeFilters).reduce(
            (ac, a) =>
                activeFilters[a].length
                    ? {
                          ...ac,
                          [a]: activeFilters[a].map((selection) => selection.key).join(',')
                      }
                    : ac,
            {}
        );
        const element = document.getElementById('results');
        if (element && filter && filter !== selectedFilters) element.scrollTo(0, 0);

        loadStylistFavorites({ ...params, from: 0, gender });
        setFavoritesParams({ ...params });
        loadShop({ ...params, page: !filter && shop ? nextPage : 1 });
    };

    useEffect(() => {
        const stylistRetailers = styleRequest?.styleData?.retailers ?? [];
        setStyleRetailers(stylistRetailers.map((item) => item.value));
        return () => {
            clearShop();
            clearSelectedFilters();
        };
    }, []);

    useEffect(() => {
        updateGender(gender !== 'neutral' ? gender : null);
        if (!loadingFilters) {
            clearShop();
            clearSelectedFilters();
            setIsLoadingShop(true);
            loadShopFilters({
                fields: 'categories,colors,retailers,prices,brands,sizes'
            });
        }
    }, [gender]);

    useEffect(() => {
        if (Object.keys(selectedFilters).length && isLoadingShop && styleRequest.owner) {
            setIsLoadingShop(false);
            fetchData();
        }
    }, [isLoadingShop, fetchData, styleRequest]);

    useEffect(() => {
        if (!favorites.length) loadStylistFavorites({ gender });
        if (Array.isArray(filters) && filters.length && filtersType === 'shop') {
            if (
                Array.isArray(filters) &&
                filters.length &&
                styleRequest.styleData &&
                styleRetailers.length > 0 &&
                !selectedFilters.brand
            ) {
                const retailers =
                    filters[
                        filters.findIndex(
                            (filter: FilterStruct) => filter.value.toLowerCase() === 'retailers'
                        )
                    ];

                if (retailers.filter_options && Array.isArray(retailers.filter_options)) {
                    onFilterChange({
                        key: 'brand',
                        value: retailers.filter_options.find(
                            (retailer: FilterOptionsParameters) =>
                                retailer.key === styleRetailers[0]
                        )
                    });
                }
            }
        }
    }, [filters, selectedFilters, styleRequest]);

    useEffect(() => {
        if (!loading && shop) setGridItems(showFavorites ? favorites : shop);
    }, [showFavorites, favorites, shop]);

    const onFilterChange = (filter: any) => {
        const isSelected =
            !!selectedFilters[filter.key] &&
            selectedFilters[filter.key].findIndex(
                (filterItem: FilterParams | FilterOptionsParameters) =>
                    filterItem.key === filter.value.key
            ) > -1;
        const callback =
            filter.key === 'category'
                ? () => {
                      loadShopFilters({
                          fields: 'categories,colors,retailers,prices,brands,sizes',
                          category: filter.value.key
                      });
                  }
                : fetchData;

        updateFilters(filter, isSelected, callback);

        if (filter.key === 'category') {
            if (!isSelected) {
                fetchData({ ...selectedFilters, [filter.key]: [filter.value] });
            } else {
                delete selectedFilters.category;
                fetchData({ ...selectedFilters });
            }
        }
    };

    const onRemoveBudget = () => {
        return updateFilters(
            {
                key: 'budget',
                value: [
                    { key: 'minPrice', value: 1, text: 'Min. Budget' },
                    { key: 'maxPrice', value: 5000, text: 'Max. Budget' }
                ]
            },
            true,
            fetchData
        );
    };

    const checkFavorite = (item: Item) =>
        favorites.findIndex((favorite: Item) =>
            favorite.sku && item.sku
                ? favorite.sku[0] != null
                    ? favorite.sku[0] === item.sku[0]
                    : favorite.item_uuid === item.item_uuid
                : favorite.originalItemUnique
                ? favorite.originalItemUnique === item.originalItemUnique
                : favorite.product_id === item[showFavorites ? 'product_id' : 'uuid']
        ) > -1;

    const toggleFavorites = () => setShowFavorites(!showFavorites);

    const closeSearch = (e: Record<string, any>) => {
        const closers = ['results-bar', 'badge-close', 'gender', 'favorites-button'];
        if (e && closers.includes(e.target.id)) {
            setExpandSearch(false);
        }
    };

    const clearFilter = (filter: ClearFilterObject) => {
        if (filter.key === 'minPrice') {
            setSubmitted(false);
            onRemoveBudget();
        } else if (filter.filter) {
            onFilterChange(filter.filter);
            closeSearch(filter.event);
        }
    };

    const firstNonEmpty = (...attributs: any) =>
        attributs.find((attr: any) => ![null, undefined].includes(attr));

    const getItemId = (item: Item) =>
        firstNonEmpty(item.uuid, item?.sku?.[0], item.originalItemUnique, item.item_uuid);

    return (
        <Container className="styling-room-shop" fluid>
            <Row>
                <Col className="filters-column">
                    {loadingFilters == undefined && <Loader id="filter-loader" />}
                    <p className="total">
                        {styling.items.replace(
                            '%count%',
                            Formatter.number(showFavorites ? favoritesTotal : total)
                        )}
                    </p>
                    {filters && Array.isArray(filters) && (
                        <Filters
                            className={
                                !selectedFilters.category ||
                                (selectedFilters.category && !selectedFilters.category.length)
                                    ? 'categories-all'
                                    : ''
                            }
                            filters={filters.map((filter) => filter)}
                            selected={selectedFilters}
                            setFilter={onFilterChange}
                            clearFilter={(key: string) => clearFilter({ key })}
                            showSelection={true}
                            filterSubmited={submitted}
                        />
                    )}
                </Col>
                <Col id="results" className="results">
                    {loading && <Loader id="shop-loader" />}
                    <div id="results-bar" className="results-bar" onClick={closeSearch}>
                        <div
                            id="favorites-button"
                            className={`favs-btn ${showFavorites ? 'on' : ''}`}
                            onClick={toggleFavorites}
                        />
                        <SearchBox
                            className={expandSearch ? 'expanded' : ''}
                            onFocus={() => setExpandSearch(true)}
                            onChange={(input) => {
                                if (input) setKeyword(input.value);
                            }}
                            onSubmit={() => {
                                updateFilters(
                                    {
                                        key: 'keywords',
                                        value: { key: keyword, text: keyword }
                                    },
                                    false,
                                    fetchData
                                );
                            }}
                        />

                        {Object.keys(selectedFilters).length ? (
                            <FilterBadges
                                selectedFilters={selectedFilters}
                                clearFilter={clearFilter}
                                view={StylingRoomSection.shop}
                            />
                        ) : (
                            ''
                        )}

                        <DropdownButton
                            key="shop-gender"
                            id="gender"
                            variant="secondary"
                            as={ButtonGroup}
                            title={styling.gender[gender]}>
                            {Object.keys(styling.gender).map((key) => (
                                <Dropdown.Item eventKey={key} key={key} onSelect={updateGender}>
                                    {key == 'female' || key == 'male' ? styling.gender[key] : ''}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>

                        <div className="grid-size">
                            <div
                                className={`size-btn large ${gridSize === 'large' ? 'active' : ''}`}
                                onClick={() => setGridSize('large')}
                            />
                            <div
                                className={`size-btn small ${gridSize === 'small' ? 'active' : ''}`}
                                onClick={() => setGridSize('small')}
                            />
                        </div>
                    </div>
                    {gridItems ? (
                        gridItems.length ? (
                            <InfiniteScroll
                                dataLength={gridItems ? gridItems.length : 0}
                                next={fetchData}
                                hasMore={true}
                                loader={undefined}>
                                {gridItems.map((item: Item, index) => (
                                    <GridItem
                                        key={index}
                                        id={getItemId(item)}
                                        index={index}
                                        type="shop"
                                        size={gridSize}
                                        availability={itemsAvailability}
                                        item={{ ...item, favorite: checkFavorite(item) }}
                                        onFavorite={() =>
                                            favorite({
                                                item: {
                                                    ...item,
                                                    product_id: getItemId(item)
                                                },
                                                attribute: 'product_id',
                                                gender,
                                                params: favoritesParams
                                            })
                                        }
                                        onImageClick={() => {
                                            return addToCanvas(item, canvasLayout);
                                        }}>
                                        {}
                                    </GridItem>
                                ))}
                            </InfiniteScroll>
                        ) : (
                            <Empty tab={showFavorites ? 'favorites' : 'shop'} />
                        )
                    ) : (
                        ''
                    )}
                </Col>
            </Row>
        </Container>
    );
};
