import React from 'react';
import { Button } from 'react-bootstrap';
import { ItemType } from 'types/item';

import { item as texts } from '../../../content.json';

interface IShopButton {
    item?: ItemType;
    sizes?: string[];
    variant: string;
}

const ShopButton: React.FC<IShopButton> = ({ item, sizes, variant }) => {
    const openLink = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const shopLink = (url: string | undefined) => (url ? texts.shop.replace('%brand%', url) : '');

    return (
        <div className={variant}>
            {sizes?.length ? (
                <a href={item?.buy_url} target="_blank" className="link" rel="noreferrer">
                    {shopLink(item?.buy_url_short)}
                </a>
            ) : (
                item?.buy_url_short && (
                    <Button variant="dark" onClick={() => openLink(item?.buy_url)}>
                        {shopLink(item?.buy_url_short)}
                    </Button>
                )
            )}
        </div>
    );
};

export default ShopButton;
