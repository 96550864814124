import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import {
    loadCloset,
    loadClosetFilters,
    loadClosetTags,
    loadLooks,
    loadLooksFilters,
    loadPhotos,
    updateSelectedFilters
} from '../../../redux/reducers/users/actions';
import Closet from './Closet';
import Collections from './Collections';
import Looks from './Looks';
import Photos from './Photos';

const mapStateToProps = ({
    user: { user },
    users: {
        loading,
        loadingFilters,
        closet,
        looks,
        photos,
        selectedFilters,
        gridSize,
        filters,
        filtersType,
        totalItems,
        closetTags,
        error
    }
}) => ({
    loading,
    loadingFilters,
    user,
    closet,
    looks,
    photos,
    selectedFilters,
    gridSize,
    filters,
    filtersType,
    totalItems,
    closetTags,
    error
});

const mapDispatchToProps = (dispatch) => ({
    loadCloset: (params) => dispatch(loadCloset(params)),
    loadLooks: (params) => dispatch(loadLooks(params)),
    loadClosetFilters: (params) => dispatch(loadClosetFilters(params)),
    loadLooksFilters: (params) => dispatch(loadLooksFilters(params)),
    loadPhotos: (params) => dispatch(loadPhotos(params)),
    updateFilters: (filter, remove, callback) =>
        dispatch(updateSelectedFilters(filter, remove, callback)),
    loadClosetTags: (params) => dispatch(loadClosetTags(params)),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default {
    closet: connect(mapStateToProps, mapDispatchToProps)(Closet),
    collections: connect(mapStateToProps, mapDispatchToProps)(Collections),
    looks: connect(mapStateToProps, mapDispatchToProps)(Looks),
    photos: connect(mapStateToProps, mapDispatchToProps)(Photos)
};
