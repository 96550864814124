import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';
import { updateGeneralData, updateProfilePicture, updateUser } from 'store/user/actions';

import Info from './Info';
import Main from './Main';
import Password from './Password';

const mapStateToProps = ({ users: { loading }, user: { user } }) => ({
    loading,
    user
});

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (data) => toggleModal(dispatch, data),
    updateUser: (user) => dispatch(updateUser(user)),
    updateProfilePicture: (files, { user_uuid, token }) =>
        updateProfilePicture(dispatch, files, { user_uuid, token }),
    updateGeneralData: (data) => updateGeneralData(dispatch, data)
});

export default {
    main: connect(mapStateToProps, mapDispatchToProps)(Main),
    info: connect(mapStateToProps, mapDispatchToProps)(Info),
    password: connect(mapStateToProps, mapDispatchToProps)(Password)
};
