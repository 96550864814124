import axios from 'axios';
import moment from 'moment';
import { Config, Cookies } from './';

const Tracking = {
    report: (data, token) => Tracking.send('report', { data, token }),
    track: (data, token) => Tracking.send('track', { data, token }),
    send: (platform, { data, token }) => {
        if (process.env.REACT_APP_ENV === 'production') {
            switch (platform) {
                case 'report':
                    return axios.post(`${Config.get('apiroot2')}events`, data, {
                        headers: { token }
                    });
                case 'track':
                    const date = Cookies.get('tracking');
                    if (isNaN(date) || moment().diff(moment(date), 'days') >= 1) {
                        Cookies.set('tracking', moment().valueOf());
                        return axios.post(
                            `${Config.get('apiroot2')}user/${data.id}/trackSession`,
                            { client: data.device },
                            { headers: { token } }
                        );
                    }
                    break;
            }
        }
    }
};

export default Tracking;
