export const classNameVariant = {
    fullScreen: 'd-none d-sm-block',
    smallScreen: 'd-block d-sm-none'
};

export const bookingLabelsStyle = {
    '0': {
        color: '#5A5A61',
        background: '#F4F4F4'
    },
    '1': {
        color: '#B16E08',
        background: '#FFF3DB'
    },
    '2': {
        color: '#FF1400',
        background: '#FDEDED'
    },
    '3': {
        color: '#5046E5',
        background: '#EEEDFC'
    }
};
