import './style.scss';

import { SearchBox } from 'components';
import Filters from 'components/Filters/Filters';
import { inbox } from 'content.json';
import { useWithDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { updateSelectedBookingId, updateSelectedFilters } from 'store/booking/actions';
import { useBookingStore } from 'store/booking/reducer';
import { Filter, FiltersCategory } from 'store/booking/types';
import { BookingFilter, FilterOption, QueueType } from 'types/booking';

import QuickFilters from './components/QuickFilters';

interface IBookingsFilter {
    bookingFilters: FiltersCategory[];
    clients: string[];
    total: number;
    showFilters: boolean;
    onMenuClick: (menuItem: QueueType) => void;
    fetchData: (filters: Filter, loadStatus: boolean) => void;
}
const BookingsFilter: React.FC<IBookingsFilter> = ({
    bookingFilters = [],
    clients = [],
    total,
    showFilters,
    onMenuClick,
    fetchData
}) => {
    const [searchValue, setSearchValue] = useState<string | null>(null);
    const [allTotal, setAllTotal] = useState(total);
    const [selectedFilters, setSelectedFilters] = useState<Record<string, FilterOption[]>>({});
    const [bookFilterMap, setFilterMap] = useState<any>({});
    const [isOpen, setIsOpen] = useState(false);
    const [isNewMessages, setIsNewMessages] = useState(false);
    const bookings = useBookingStore((store) => store.bookings);
    const bookingsFilters = useBookingStore((store) => store.filters);
    const selectedBookingFilters = useBookingStore((store) => store.selectedFilters);
    const updateSelectedFiltersAction = useWithDispatch(updateSelectedFilters);
    const updateSelectedBookingIdAction = useWithDispatch(updateSelectedBookingId);

    useEffect(() => {
        const mapping: any = {};

        if (bookingFilters.length > 0) {
            bookingFilters.forEach((filterCategories) => {
                mapping[filterCategories.key] = filterCategories.filters.map((item) => item.key);
            });
            setFilterMap({ ...mapping });
        }

        if (showFilters && searchValue) setSearchValue(null);
    }, [bookingFilters]);

    useEffect(() => {
        const filtersKeys = Object.keys(selectedBookingFilters);
        const isNewMessagesInclude =
            filtersKeys.length > 0 &&
            selectedBookingFilters['pending']?.length > 0 &&
            selectedBookingFilters['pending'][0].key === 'unread';

        if (filtersKeys.length > 0) setSelectedFilters(selectedBookingFilters);
        setIsNewMessages(isNewMessagesInclude);
    }, []);

    useEffect(() => {
        if (bookingsFilters.search && bookings.length === 1) {
            setSearchValue(bookings[0].client.fullName);
        }
    }, [bookings]);

    const updateSearch = (filter: { key: string; label?: string; value: string }) => {
        if (!filter && !searchValue) return;

        const search = filter?.label ? filter.value : null;
        if (!search) updateSelectedBookingIdAction(null);
        setSearchValue(filter?.label ?? null);

        const params = search ? { search } : {};
        const loadStatus = search ? false : true;
        fetchData(params, loadStatus);
    };

    const onFilterChange = (filter: BookingFilter) => {
        Object.keys(bookFilterMap).forEach((key) => {
            if (bookFilterMap[key].includes(filter.value.key)) filter.key = key;
        });

        const isSelected =
            !!selectedFilters[filter.key] &&
            selectedFilters[filter.key].findIndex((item) => item.key === filter.value.key) > -1;

        const SINGLE_SELECTION = ['pending', 'status', 'planType', 'pricingModel', 'sortBy'];

        if (isSelected) {
            selectedFilters[filter.key] = selectedFilters[filter.key].filter(
                (item) => item.key !== filter.value.key
            );
            if (selectedFilters['pending']?.length === 0) setIsNewMessages(false);
            if (selectedFilters[filter.key].length === 0) delete selectedFilters[filter.key];
            if (filter.key !== 'sortBy') setAllTotal(allTotal - filter.value.count);
            setSelectedFilters({ ...selectedFilters });
        } else {
            if (!selectedFilters[filter.key]) selectedFilters[filter.key] = [];
            if (selectedFilters[filter.key].find((item) => item.key === filter.key)) {
                selectedFilters[filter.key] = selectedFilters[filter.key].filter(
                    (item) => item.key !== filter.key
                );
            } else {
                if (filter.key !== 'sortBy') setAllTotal(allTotal + filter.value.count);
                if (Array.isArray(filter.value)) {
                    delete selectedFilters[filter.key];
                    filter.value.forEach((item) => {
                        selectedFilters[item.key] = [
                            {
                                key: item.key,
                                text: item.text,
                                count: item.count,
                                priority: item.priority
                            }
                        ];
                    });
                } else {
                    if (SINGLE_SELECTION.includes(filter.key)) {
                        if (filter.value.key === 'unread') setIsNewMessages(true);
                        if (filter.key === 'pending' && filter.value.key !== 'unread')
                            setIsNewMessages(false);

                        if (filter.key !== 'sortBy') {
                            if (selectedFilters[filter.key].length > 0)
                                setAllTotal(
                                    allTotal +
                                        filter.value.count -
                                        selectedFilters[filter.key][0].count
                                );
                            else setAllTotal(allTotal + filter.value.count);
                        }

                        selectedFilters[filter.key] = [filter.value];
                    } else {
                        selectedFilters[filter.key] = selectedFilters[filter.key].filter(
                            (item) => !item.singleSelect
                        );
                        selectedFilters[filter.key].push(filter.value);
                    }
                }
            }
            setSelectedFilters({ ...selectedFilters });
        }
    };

    const clearFilter = () => {
        const defaultSort = [
            {
                key: 'latest',
                value: 'Last Modified',
                count: -1,
                priority: 0
            }
        ];

        const hasSortFilter = Object.keys(selectedFilters).includes('sortBy');
        const sortFilter = hasSortFilter
            ? { sortBy: selectedFilters.sortBy }
            : { sortBy: defaultSort };

        setSelectedFilters(sortFilter);
        setIsNewMessages(false);
        setAllTotal(0);
    };

    const showNewMessages = () => {
        const statusIndex = bookingFilters.findIndex((status) => status.key === 'pending');
        const newMessages = bookingFilters[statusIndex].filters.find(
            (filter) => filter.key === QueueType.newMessages
        );

        onFilterChange({
            key: 'pending',
            value: newMessages as FilterOption
        });
        updateFilter(selectedFilters, true);
    };

    const updateFilter = (filterArray: Record<string, FilterOption[]>, selected = false) => {
        const params = Object.values(filterArray).flat(1);
        const hasNewMessages = params.findIndex((filter) => filter.key === QueueType.newMessages);

        let total = 0;
        params.forEach((filter) => (total += filter.count));

        setAllTotal(total);
        setIsNewMessages(hasNewMessages !== -1);
        updateSelectedBookingIdAction(null);

        if (!selected) {
            setIsOpen(!isOpen);
            setSelectedFilters({ ...filterArray });
        } else {
            setIsOpen(false);
            updateSelectedFiltersAction(filterArray);
            fetchData({ filter: params.map((filter) => filter.key) }, true);
        }
    };

    const isFilterSelected = () => {
        const filter = { ...selectedFilters };
        delete filter.sortBy;

        return Object.keys(filter).length > 0;
    };

    return (
        <Container className="bookings-filter">
            <Row>
                <Col>
                    <SearchBox
                        onFocus={() => setIsOpen(false)}
                        options={clients as any}
                        placeholder={inbox.bookings.search}
                        onChange={updateSearch as any}
                        value={searchValue ? { label: searchValue, value: searchValue } : null}
                        className={searchValue ? 'search-on' : ''}
                    />
                </Col>
                {showFilters && (
                    <Row>
                        <Col className="filters">
                            <Dropdown drop="down" show={isOpen}>
                                <Dropdown.Toggle
                                    variant="light"
                                    className="filter-dropdown"
                                    id={`dropdown.toggle`}
                                    data-test-id="filter-toggel"
                                    onClick={() => updateFilter(selectedBookingFilters)}
                                >
                                    <div data-test-id="filter-icon" className="filter-icon">
                                        {isFilterSelected() && <div className="filter-selected" />}
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <div className="menu-header">
                                        <label className="title">Filter & Sort</label>
                                        <label className="reset" onClick={clearFilter}>
                                            Reset
                                        </label>
                                    </div>
                                    <Filters
                                        filters={bookingFilters}
                                        selected={selectedFilters}
                                        setFilter={onFilterChange as any}
                                        clearFilter={undefined}
                                        showSelection={false}
                                        loadFilters={undefined}
                                        user={undefined}
                                        className=""
                                    />
                                    <Button
                                        data-test-id="show-filter-results"
                                        variant={'warning'}
                                        className="sticky-btn"
                                        onClick={() => updateFilter(selectedFilters, true)}
                                    >
                                        {`Show Results`}
                                    </Button>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>

                        <Row>
                            <QuickFilters
                                onOpen={setIsOpen}
                                onMenu={onMenuClick}
                                showNewMessages={showNewMessages}
                                isNewMessages={isNewMessages}
                            />
                        </Row>
                    </Row>
                )}
            </Row>
        </Container>
    );
};

export default BookingsFilter;
