import './style.scss';

import { modal } from 'content.json';
import { useWithDispatch } from 'hooks';
import { useLDClient, withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Validator } from 'services';
import { resetError } from 'store/error/actions';
import { useErrorStore } from 'store/error/reducer';
import { toggleModal } from 'store/ui/actions';
import { useUIStore } from 'store/ui/reducer';
import { login } from 'store/user/actions';
import { useUserStore } from 'store/user/reducer';
import { Client } from 'types/user';

import { extractUserData } from '../../../services/utils/user-utils';

const Signin: React.FC = () => {
    const history = useHistory();
    const [domain, setDomain] = useState('');
    const [remember, setRemember] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<{ email?: string; password?: string } | null>(null);
    const texts = modal.signin;
    const user = useUserStore((store) => store.user);
    const redirectUrl = useUIStore((store) => store.redirectUrl);
    const errorsStore = useErrorStore((store) => store.errors);
    const loading = useUIStore((store) => store.indicators.loading);

    const loginAction = useWithDispatch(login);
    const toggleModalAction = useWithDispatch(toggleModal);
    const resetErrorAction = useWithDispatch(resetError);
    const ldClient = useLDClient();
    const isValidUser = user && Object.keys(user)?.length > 0 && user.hasOwnProperty('email');

    useEffect(() => {
        resetErrorAction();
        const previousLDUser = ldClient?.getUser();
        if (domain) {
            const index = email.indexOf('@');
            setEmail(`${email.substr(0, index > -1 ? index : email.length)}${domain}`);
            setDomain('');
        }
        if (isValidUser && previousLDUser) {
            const userDetails = extractUserData(user as Client);
            if (userDetails && ldClient) ldClient.alias(userDetails, previousLDUser);
            history.push('/inbox');
        }
    }, [domain, email, user, redirectUrl, ldClient]);

    const validate = () => {
        const errors = {
            ...Validator.email(email),
            ...Validator.password(password)
        };
        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    };
    return (
        <span className="signin-modal">
            <Modal.Header closeButton />

            <Modal.Body>
                <Form
                    noValidate
                    onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        if (validate()) {
                            const loggedInUser = await loginAction(
                                {
                                    email,
                                    password,
                                    remember
                                },
                                'form'
                            );
                            const userDetails = extractUserData(loggedInUser as Client);
                            if (ldClient && userDetails)
                                await ldClient.identify(userDetails, undefined);
                        }
                    }}
                >
                    <Form.Row>
                        <Form.Group as={Col} controlId="email">
                            <Form.Label column={false}>{texts.email}</Form.Label>
                            <Form.Control
                                data-test-id="login-email"
                                type="email"
                                value={email}
                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                    setEmail(e.currentTarget.value)
                                }
                                required
                            />
                            {errors?.email && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            )}
                            {errorsStore[0]?.field === 'email' && (
                                <Form.Control.Feedback type="invalid">
                                    {errorsStore[0]?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="email-domains">
                        <Badge variant="secondary" onClick={() => setDomain('@gmail.com')}>
                            @gmail.com
                        </Badge>
                        <Badge variant="secondary" onClick={() => setDomain('@yahoo.com')}>
                            @yahoo.com
                        </Badge>
                        <Badge variant="secondary" onClick={() => setDomain('@hotmail.com')}>
                            @hotmail.com
                        </Badge>
                        <Badge variant="secondary" onClick={() => setDomain('@icloud.com')}>
                            @icloud.com
                        </Badge>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="password">
                            <Form.Label column={false}>{texts.password}</Form.Label>
                            <Form.Control
                                data-test-id="login-password"
                                type="password"
                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                    setPassword(e.currentTarget.value)
                                }
                                required
                            />
                            {errors?.password && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            )}
                            {errorsStore && errorsStore[0]?.field === 'password' && (
                                <Form.Control.Feedback type="invalid">
                                    {errorsStore[0]?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="rememberMe">
                            <Form.Check
                                label={texts.remember}
                                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                    setRemember(e.currentTarget.checked)
                                }
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="forgot" className="forgot-pass">
                            <strong
                                onClick={() =>
                                    toggleModalAction({ type: 'ResetPassword', data: {} })
                                }
                            >
                                {texts.forgot}
                            </strong>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Button
                            variant="dark"
                            id="submit"
                            type="submit"
                            className="submit-btn"
                            disabled={loading}
                            data-test-id="login-button"
                        >
                            {texts.signin}
                        </Button>
                    </Form.Row>
                </Form>
            </Modal.Body>
        </span>
    );
};

export default withLDConsumer()(Signin);
