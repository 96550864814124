import './style.scss';

import React from 'react';
import { Col } from 'react-bootstrap';
import { FilterItem } from 'types/item';

interface IFilterCheckBox {
    item: FilterItem;
    isSelected: boolean;
    filterKey: string;
    singleSelect: boolean | undefined;
    onChange: ({ key, value }: { key: string; value: FilterItem }) => void;
}

const FilterCheckBox: React.FC<IFilterCheckBox> = ({
    item,
    isSelected,
    filterKey,
    singleSelect,
    onChange
}) => {
    const isInbox = window.location.pathname === '/inbox';
    const isBold = isInbox && isSelected;
    const showCount = item?.count && isInbox && item.count > 0;
    const isSortCategory = item?.key === 'latest' || item?.key === 'dueDate';
    const filterChange = () => onChange({ key: filterKey, value: item });

    return (
        <Col className={`filter-item ${isSelected ? 'selected' : ''}`}>
            <input type="checkbox" id={item?.key} onChange={filterChange} checked={isSelected} />
            <label
                className={`filter-label ${singleSelect ? 'single' : ''} ${isBold ? 'bold' : ''}`}
                htmlFor={item?.key}
            >
                {item?.picture && <img src={item.picture} />}
                {item?.text || item?.value}
                {showCount ? <> ({item.count}) </> : isSortCategory || !isInbox ? '' : ' (0)'}
            </label>
        </Col>
    );
};

export default FilterCheckBox;
