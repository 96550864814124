import React from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';

export const ImageWithButton: React.FC<{ imageUrl: string; imageDescription?: string }> = ({
    imageUrl,
    imageDescription
}) => {
    const [show, setShow] = React.useState(false);
    return (
        <>
            {show && (
                <>
                    <Row>
                        <Col xs={12}>
                            <Image src={imageUrl} width="100%" />
                        </Col>
                    </Row>
                    {imageDescription != null && (
                        <Row>
                            <Col>
                                <span>{imageDescription}</span>
                            </Col>
                        </Row>
                    )}
                </>
            )}
            <Row>
                <Col>
                    <Button
                        variant="link"
                        size="sm"
                        onClick={() => setShow(!show)}
                        style={{ padding: 0 }}
                    >
                        {show ? 'hide image' : 'show image'}
                    </Button>
                </Col>
            </Row>
        </>
    );
};
