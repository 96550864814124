import '../style.scss';

import React, { useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';

interface IColor {
    checked: boolean;
    picture: string;
    className: string;
    value: boolean;
    onChange: ({ text }: { text: boolean }) => void;
}

const Color: React.FC<IColor> = ({ onChange, value, className = '', picture, checked = false }) => {
    const [check, setCheck] = useState(false);
    useEffect(() => {
        setCheck(checked);
    }, [checked]);

    const onChecked = () => {
        onChange({ text: value });
        setCheck(value);
    };

    return (
        <Form.Group className={`color ${check ? 'selected' : ''} ${className}`} onClick={onChecked}>
            <Image src={picture} alt="" />
        </Form.Group>
    );
};

export default Color;
