import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { loadItem, loadOutfit, updateItem } from '../../redux/reducers/style/actions';
import { setCurrentItem } from '../../redux/reducers/users/actions';
import Item from './Item';

const mapStateToProps = ({
    users: { currentItem },
    user: { user },
    style: { loading: styleLoading, outfit, item }
}) => ({
    styleLoading,
    outfit,
    item,
    user,
    currentItem
});

const mapDispatchToProps = (dispatch) => ({
    loadOutfit: (uuid) => dispatch(loadOutfit(uuid)),
    loadItem: (uuid) => dispatch(loadItem(uuid)),
    updateItem: (item) => dispatch(updateItem(item)),
    setCurrentItem: (item) => dispatch(setCurrentItem(item))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Item));
