import { connect } from 'react-redux';

import { togglePageMessage } from '../../redux/reducers/config/actions';
import PageMessage from './PageMessage';

const mapStateToProps = ({ config: { message } }) => ({ message });

const mapDispatchToProps = (dispatch) => ({
    togglePageMessage: () => dispatch(togglePageMessage())
});

export default connect(mapStateToProps, mapDispatchToProps)(PageMessage);
