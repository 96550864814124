import debounce from 'lodash/debounce';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Reducer } from 'redux';
import { Dispatch } from 'redux';
import { store } from '../store';

export const useWithDispatch = <T extends any[], U>(
    fn: (dispatch: Dispatch<any>, ...args: T) => U
) => {
    const dispatch = useDispatch();
    return (...args: T): U => fn(dispatch, ...args);
};

export const useReducer = (name: string, reducer: Reducer) => {
    React.useMemo(() => {
        if (!store.hasReducer?.(name) && store.loadReducer) {
            store.loadReducer(name, reducer);
        }
    }, []);
};
export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useLayoutEffect(() => {
        const updateSize = (): void => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', debounce(updateSize, 250));
        return (): void => window.removeEventListener('resize', updateSize);
    }, []);
    return isMobile;
};
