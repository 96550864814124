import './style.scss';

import { inbox } from 'content.json';
import React, { useState } from 'react';
import { Col, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Formatter } from 'services';
import { isArchive, isInquiries } from 'services/utils/bookings-utils';
import { getDueDateLabel } from 'services/utils/bookings-utils';
import { isPartner } from 'services/utils/user-utils';
import { useBookingStore } from 'store/booking/reducer';
import { BookingType, ButtonActionType, Label, loyalType } from 'types/booking';

import { bookingLabelsStyle } from '../../constants/global-styles';
import BookingButtons from './BookingButtons';
import ArchiveBooking from './components/ArchiveBooking';
import { BookingLabel } from './components/BookingLabel';
import Chevron from './components/Chevron';
interface IBooking {
    booking: BookingType;
    isSelected: boolean;
    onSelect: (booking: BookingType) => void;
    onClick: (action: ButtonActionType, booking: BookingType) => void;
    onImportant?: (channel_id: string, enabled: boolean) => void;
}
const Booking: React.FC<IBooking> = ({ booking, isSelected, onSelect, onClick, onImportant }) => {
    const bookingMetadata = useBookingStore((store) => store.bookingMetadata);
    const isBookingInquiries = isInquiries(booking.session.type);
    const [open, setOpen] = useState(false);
    const isPartnerStylist = isPartner();

    const onButtonClick = (e: React.MouseEvent, action: ButtonActionType, booking: BookingType) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(action, booking);
    };

    const isDeprecated = (labelType: number) => bookingMetadata?.labels[labelType]?.deprecated;
    const bookingLabels = booking.session.labels.filter((label) => !isDeprecated(label.type));
    const customLabel = booking.session?.dueDate
        ? [getDueDateLabel(booking.session.dueDate, isPartnerStylist)]
        : [];
    const labels = customLabel.concat(bookingLabels);

    return (
        <Row className={`booking ${isSelected ? 'selected' : ''}`}>
            {!isArchive(booking.session.type) ? (
                <Col
                    id={booking.session.sid}
                    className={`booking-content ${isBookingInquiries ? 'inquiries' : ''}`}
                >
                    <div className="booking-title">
                        {booking.session.hasUnreadMessages && <div className="new-messages" />}
                        <div className="client-info">
                            <p className="name" title={booking.client.fullName}>
                                {booking.client.fullName}
                            </p>
                            {booking.client?.loyalty && (
                                <Image
                                    className="loyalty"
                                    src={inbox.icons[booking.client.loyalty as loyalType]}
                                />
                            )}
                        </div>
                        <div className="session-info">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id={booking.session.uuid}>
                                        {
                                            inbox.bookings.tooltips[
                                                booking.session.isFlaggedImportant
                                                    ? 'unimportant'
                                                    : 'important'
                                            ]
                                        }
                                    </Tooltip>
                                }
                            >
                                <div
                                    className={`important ${
                                        booking.session.isFlaggedImportant ? 'on' : 'off'
                                    }`}
                                    onClick={() =>
                                        onImportant?.(
                                            booking.session.sid,
                                            !booking.session.isFlaggedImportant
                                        )
                                    }
                                />
                            </OverlayTrigger>
                            <p className="date">{Formatter.date(booking.session.lastModified)}</p>
                        </div>
                    </div>

                    <div className="discription" onClick={() => onSelect(booking)}>
                        <div className="plan-info">
                            <p
                                className={`content ${
                                    booking.session.hasUnreadMessages ? 'bold' : ''
                                }`}
                            >
                                {booking.session.description}
                            </p>
                            {booking.session.plan != undefined && (
                                <p
                                    data-test-id="book-plan"
                                    className="book-plan"
                                    style={{
                                        background:
                                            bookingMetadata.plans[booking.session.plan].background
                                    }}
                                >
                                    {bookingMetadata.plans[booking.session.plan].text}
                                </p>
                            )}
                        </div>
                        <div className="book-status">
                            {labels.map((label: Label, index: number) => (
                                <BookingLabel
                                    data-test-id="booking-label"
                                    key={index}
                                    isSelected={isSelected}
                                    labelType={label.type}
                                    labels={bookingLabelsStyle}
                                >
                                    {label.text}
                                </BookingLabel>
                            ))}
                        </div>
                    </div>

                    <BookingButtons
                        booking={booking}
                        isInquiries={isBookingInquiries}
                        buttonActions={booking.actions}
                        onButtonClick={onButtonClick}
                    />

                    {!isBookingInquiries && (
                        <Chevron
                            open={open}
                            looksCount={booking.session?.looksCount ?? 0}
                            looksMax={booking.session?.looksMax ?? 0}
                            url={inbox.icons.arrowLeft}
                            setOpen={setOpen}
                            isPartnerStylist={isPartnerStylist}
                        />
                    )}
                </Col>
            ) : (
                <Col
                    className="booking-archive"
                    onClick={() => onSelect(booking)}
                    id={booking.session.sid}
                >
                    <ArchiveBooking
                        clientName={booking.client.fullName}
                        looksCount={booking.session.looksCount ?? 0}
                        lastModified={booking.session.lastModified}
                        description={booking.session.description}
                    />
                </Col>
            )}
        </Row>
    );
};

export default Booking;
