import '../style.scss';

import React from 'react';
import { BookingQueue, QueueType } from 'types/booking';

interface IQueue {
    queue: BookingQueue;
    isSelected: boolean;
    onQueueChanged: (queue: QueueType) => void;
}

const Queue: React.FC<IQueue> = ({ queue, isSelected, onQueueChanged }) => (
    <div
        data-test-id={queue.key}
        className={`book-status ${isSelected ? 'selected' : ''}`}
        onClick={() => onQueueChanged(queue.key)}
    >
        <p className="book-status-count">{queue.count}</p>
        <p className="book-status-name">{queue.value}</p>
    </div>
);

export default Queue;
