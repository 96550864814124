import AddClosetItem from './AddClosetItem';
import DeactivateAccount from './DeactivateAccount';
import EndSession from './EndSession';
import Error from './Error';
import Photo from './Photo';
import ReadReview from './ReadReview';
import RemoveItem from './RemoveItem';
import RemoveLook from './RemoveLook';
import RemovePhoto from './RemovePhoto';
import ResetConfirmation from './ResetConfirmation';
import ResetPassword from './ResetPassword';
import SaveMoodboard from './SaveMoodboard';
import SavePersonalShopping from './SavePersonalShopping';
import SaveStyleboard from './SaveStyleboard';
import Signin from './Signin';

const components = {
    Signin,
    ResetPassword,
    ResetConfirmation,
    EndSession,
    Error,
    SaveStyleboard,
    SaveMoodboard,
    SavePersonalShopping,
    RemoveItem,
    RemoveLook,
    RemovePhoto,
    AddClosetItem,
    Photo,
    DeactivateAccount,
    ReadReview
};

export default components;
