export const replaceStrings = (
    str: string,
    replacements: { [key: string]: string | number }
): string => {
    Object.keys(replacements).forEach((key: string) => {
        const replacement = replacements[key];
        str = str.replaceAll(`%${key}%`, replacement.toString());
    });

    return str;
};
