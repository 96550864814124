import React from 'react';
import { Formatter } from 'services';

interface IArchiveBooking {
    clientName: string;
    looksCount: number;
    lastModified: Date;
    description: string;
}
const ArchiveBooking: React.FC<IArchiveBooking> = ({
    clientName,
    looksCount,
    lastModified,
    description
}) => (
    <>
        <div className="booking-title">
            <span>
                <p className="name">{clientName}</p>
                <p className="looks">{` | ${looksCount} Looks`}</p>
            </span>

            <p className="date">{Formatter.date(lastModified)}</p>
        </div>

        <p className="content">{description}</p>
    </>
);

export default ArchiveBooking;
