import { Loader } from 'components';
import { useWithDispatch } from 'hooks';
import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router';
import { Style, User } from 'services';
import { changeImportanceLevel, loadBookings } from 'store/booking/actions';
import { useBookingStore } from 'store/booking/reducer';
import { Filter } from 'store/booking/types';
import { setRevision, setRoomStylingType } from 'store/room/actions';
import { toggleModal } from 'store/ui/actions';
import { BookingQueue, BookingType, ButtonActionType, QueueType } from 'types/booking';
import { RoomState } from 'types/styling';

import Booking from './Booking';
import NoResults from './components/NoResults';
import Queues from './Queues';
interface IBookingList {
    bookings: BookingType[];
    selectedBooking: BookingType | null;
    totalBookings: number;
    listRef: React.Ref<any>;
    selectedQueue: QueueType | undefined;
    showBookingQueues: boolean;
    bookingQueues: BookingQueue[];
    bookingLimitPerPage: number;
    onArchive: (booking: BookingType | null) => void;
    onQueueChange: (queue: QueueType) => void;
    setFilter: (filters: Filter, loadStatus: boolean) => void;
    onBookingSelect: (book: BookingType) => void;
}

const BookingList: React.FC<IBookingList> = ({
    bookings,
    selectedBooking,
    totalBookings,
    listRef,
    selectedQueue,
    showBookingQueues,
    bookingQueues,
    bookingLimitPerPage,
    onArchive,
    onQueueChange,
    setFilter,
    onBookingSelect
}) => {
    const history = useHistory();
    const loadBookingsAction = useWithDispatch(loadBookings);
    const toggleModalAction = useWithDispatch(toggleModal);
    const setRoomStylingTypeAction = useWithDispatch(setRoomStylingType);
    const setRevisionAction = useWithDispatch(setRevision);
    const changeImportanceLevelAction = useWithDispatch(changeImportanceLevel);
    const bookingPaginate = useBookingStore((store) => store.bookingPaginate);
    const bookingLoader = useBookingStore((store) => store.bookingLoader);
    const [jump, setJump] = useState(false);

    const onRevise = async (booking: BookingType) => {
        const styleData = await User.styleData(booking.client.uuid, {
            session_uuid: booking.session.uuid
        });

        const revisionLookUuid = styleData?.data?.canvas?.revision_look_uuid;
        if (!revisionLookUuid) throw 'revision_look_uuid is null';

        const { data } = await Style.getOutfit(revisionLookUuid, booking.session.uuid);
        let name = '';
        if (data.outfit_name) {
            if (data.outfit_name.includes('Revision')) {
                const outfit_name = data.outfit_name.substring(
                    0,
                    data.outfit_name.indexOf('Revision')
                );
                name = `${outfit_name ? `${outfit_name} ` : ''}Revision ${
                    data.revision_number + 1
                }`;
            } else if (data.outfit_name.length) {
                name = `${data.outfit_name} - Revision ${data.revision_number + 1}`;
            }
        } else {
            name = `Revision ${data.revision_number + 1}`;
        }
        const revisedLook = {
            name,
            outfit_uuid: data.unique,
            items: data.feedback?.items_to_replace
                ? data.items.filter((item: any) => !item.is_selected)
                : data.items
        };
        setRevisionAction(revisedLook);
        setRoomStylingTypeAction(RoomState.revision);
    };

    const loadMore = () => {
        const page =
            bookings.length > bookingLimitPerPage
                ? Math.round(bookings.length / bookingLimitPerPage + 1)
                : bookingPaginate.meta.currentPage + 1;

        if (bookingPaginate.links.next && !bookingLoader)
            return loadBookingsAction({ params: { page } }, false);
    };

    const onBookingButtonClick = async (action: ButtonActionType, booking: BookingType) => {
        switch (action) {
            case ButtonActionType.requestEndSession:
                toggleModalAction({ type: 'EndSession', data: booking });
                return;
            case ButtonActionType.archive:
                onArchive(booking);
                return;
            case ButtonActionType.chat:
                onBookingSelect(booking);
                return;
            case ButtonActionType.restyle:
                await onRevise(booking);
                break;
            case ButtonActionType.styleboard:
                setRevisionAction(null);
                setRoomStylingTypeAction(RoomState.look);
                break;
            case ButtonActionType.moodboard:
                setRevisionAction(null);
                setRoomStylingTypeAction(RoomState.moodboard);
                break;
        }

        history.push(
            `/styling-room/${booking.session.uuid}/${
                action === ButtonActionType.moodboard ? 'inspiration' : 'shop'
            }`
        );
    };

    const jumpToTop = () => {
        const element = document.getElementsByClassName('bookings-list');
        element[0].scrollTo(0, 0);
        setJump(false);
    };

    const getClassName = () => {
        const baseClass = 'bookings-list';
        const addClass = !showBookingQueues ? ' archived-list' : '';
        return `${baseClass}${addClass}`;
    };

    return (
        <Container className={getClassName()} id="bookings-list" ref={listRef}>
            <InfiniteScroll
                style={{ overflow: 'visible' }}
                dataLength={bookings.length}
                onScroll={(e: any) => setJump(e.target.scrollTop != 0)}
                next={loadMore}
                hasMore={bookings.length < totalBookings}
                scrollableTarget="bookings-list"
                loader={undefined}
            >
                {bookingLoader && <Loader />}

                {showBookingQueues && (
                    <Queues
                        selectedQueue={selectedQueue}
                        bookingQueues={bookingQueues}
                        onQueueChange={onQueueChange}
                        setFilter={setFilter}
                    />
                )}

                {bookings.length === 0 && (
                    <NoResults
                        loading={bookingLoader}
                        selectedQueue={selectedQueue}
                        bookingQueues={bookingQueues}
                    />
                )}

                {bookings.map((booking) => (
                    <Booking
                        key={booking.session.sid}
                        booking={booking}
                        onSelect={onBookingSelect}
                        onClick={onBookingButtonClick}
                        onImportant={changeImportanceLevelAction}
                        isSelected={selectedBooking?.session?.sid === booking.session.sid}
                    />
                ))}

                {jump && (
                    <Button
                        size="sm"
                        variant="light"
                        className="jump-to-top-btn d-none d-sm-flex"
                        data-test-id="move-to-top"
                        onClick={jumpToTop}
                    >
                        <div className="jump-icon"></div>
                    </Button>
                )}
            </InfiniteScroll>
        </Container>
    );
};
export default BookingList;
