import React from 'react';
import { UserStyles } from 'types/styles/user';

export const getBudgetView = (style: UserStyles | undefined) => {
    if (style?.budget_ranges.length) {
        return style?.budget_ranges?.map((size) => (
            <span key={size.name}>
                {size.name}: <b>{size?.ranges[0]}</b>,{' '}
            </span>
        ));
    }
};
