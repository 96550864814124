import { connect } from 'react-redux';
import { setLandingType } from 'store/ui/actions';

import Landing from './Landing';

const mapStateToProps = ({ config: { message }, ui: { landingType } }) => ({
    message,
    landingType
});
const mapDispatchToProps = (dispatch) => ({
    setLandingType: (landingType) => setLandingType(dispatch, landingType)
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
