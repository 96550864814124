import './style.scss';

import { VFC, useMemo } from 'react';

import { Client, Stylist } from '../../types/user';
import { parseCoreUser } from 'services/utils/user-utils';

type TProps = {
    user: Client | Stylist | any;
    level?: boolean;
    className?: string;
};

const ProfilePhoto: VFC<TProps> = ({ user, level = false, className = '' }) => {
    const { initials, fullName } = useMemo(
        () => parseCoreUser(user),
        [user]
    );

    return (
        <div className={`profile-photo ${className} ${level ? user?.level : ''}`}>
            {(user?.picture_small || user?.profile_picture) && (
                <img src={user.picture_small || user.profile_picture} alt={fullName} />
            )}
            {initials}
        </div>
    );
};

export default ProfilePhoto;
