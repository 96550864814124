import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { Actions } from '../actions';
import { ActionTypes, UserState } from './types';

const initialState: UserState = {
    user: null,
    redirectUrl: null,
    cart: null,
    twilioInit: false
};

const userReducer = (state = initialState, action: Actions): UserState => {
    switch (action.type) {
        case ActionTypes.LOGOUT_USER: {
            return {
                ...state,
                user: null,
                redirectUrl: null,
                cart: null,
                twilioInit: false
            };
        }
        case ActionTypes.REFRESH_USER_DATA:
        case ActionTypes.UPDATE_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                } as any
            };
        }
        default:
            return state;
    }
};
export const name = 'user';
export const useUserStore: TypedUseSelectorHook<UserState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
export default userReducer;
