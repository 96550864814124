import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

import { styling } from '../../../content.json';
import { replaceStrings } from '../../../services/utils';
import { TSubTab as TClosetSubTab } from '../Tabs/Closet';
import { TTab } from '../types';

const Container = styled.div`
    font-family: UntitledSans-Regular, sans-serif;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0.41px;
    max-width: 431px;
    color: #000;
    white-space: pre;
    padding-left: 5px;

    a,
    a:hover {
        color: #000;
        font-weight: bold;
    }
`;

export const Empty: FC<{ tab: TTab | TClosetSubTab; [k: string]: string }> = ({
    tab,
    ...replacementStrings
}) => {
    const text = styling.empty[tab];
    const { hasHTML } = text as any;
    const content = useMemo<string>(() => {
        let result = '';
        if (text) {
            if (typeof text === 'string') {
                result = text;
            } else if (text.content) {
                result = text.content;
            }
        }
        return replaceStrings(result, replacementStrings);
    }, [text, replacementStrings]);

    if (content) {
        return (
            <Container>
                {hasHTML ? <div dangerouslySetInnerHTML={{ __html: content }} /> : content}
            </Container>
        );
    }

    return <></>;
};
