import React from 'react';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';

import { ProfilePhoto } from '../../../components';
import { stylist as texts } from '../../../content';

const BookBar = ({ stylist }) => {
    return (
        <Navbar className="book-bar" fixed="bottom" expand={false}>
            <Container className="no-padding">
                <Nav className="d-none d-md-flex">
                    <Container>
                        <Row>
                            <Col className="profile-image">
                                <ProfilePhoto user={stylist} />
                            </Col>
                            {stylist && (
                                <Col className="stylist-details">
                                    <div className="name">{stylist.name}</div>
                                    <div className="response-time">
                                        {' '}
                                        {texts.response.replace(
                                            '%time%',
                                            stylist.average_response_time
                                        )}
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default BookBar;
