import './style.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import { BirthdayMessage, Page } from '../../components';
import Signin from '../../components/ModalContent/Signin';

const Landing = ({ setLandingType }) => {
    setLandingType('shoppers');
    return (
        <Page>
            <BirthdayMessage />
            <Container className="landing" fluid>
                <Signin />
            </Container>
        </Page>
    );
};

export default Landing;
