import './style.scss';

import React from 'react';

const PageMessage = ({ togglePageMessage, text, message }) => (
    <div id="page-message" className={`page-message ${message ? 'show' : 'hide'}`}>
        <div className="close" onClick={togglePageMessage} />
        <p>{text}</p>
    </div>
);

export default PageMessage;
