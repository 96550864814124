import {
    BookingConfigResponse,
    BookingQueue,
    BookingType,
    FilterOption,
    QueueType
} from 'types/booking';
import { Stylist } from 'types/user';

import { IAction, IActionWithPayload } from '../types';

export enum ActionTypes {
    BOOK_STYLIST = 'booking/BOOK_STYLIST',
    CAMPAIGN_BOOKING = 'booking/CAMPAIGN_BOOKING',
    CLEAR_BOOKINGS_LIST = 'booking/CLEAR_BOOKINGS_LIST',
    CLEAR_BOOKINGS_SELECTION = 'booking/CLEAR_BOOKINGS_SELECTION',
    LOAD_BOOKINGS = 'booking/LOAD_BOOKINGS',
    LOAD_BOOKINGS_REQUEST = 'booking/LOAD_BOOKINGS_REQUEST',
    LOAD_BOOKINGS_SUCCESS = 'booking/LOAD_BOOKINGS_SUCCESS',
    LOAD_BOOKINGS_FAILURE = 'booking/LOAD_BOOKINGS_FAILURE',
    LOAD_CLIENTS_FOR_STYLIST = 'booking/LOAD_CLIENTS_FOR_STYLIST',
    LOAD_CLIENTS_FOR_STYLIST_REQUEST = 'booking/LOAD_CLIENTS_FOR_STYLIST_REQUEST',
    LOAD_CLIENTS_FOR_STYLIST_SUCCESS = 'booking/LOAD_CLIENTS_FOR_STYLIST_SUCCESS',
    SET_FILTER = 'booking/SET_FILTER',
    SET_TOTAL_BOOKINGS = 'booking/SET_TOTAL_BOOKINGS',
    UPDATE_UNREAD_MESSAGES = 'booking/UPDATE_UNREAD_MESSAGES',
    UPDATE_IMPORTANCE_LEVEL = 'booking/UPDATE_IMPORTANCE_LEVEL',
    UPDATE_SELECTED_BOOKING_ID = 'booking/UPDATE_SELECTED_BOOKING_ID',
    UPDATE_SELECTED_FILTERS = 'booking/UPDATE_SELECTED_FILTERS',
    SET_UNREAD_MESSAGES = 'booking/SET_UNREAD_MESSAGES',
    LOAD_SESSION_CONFIG = 'booking/LOAD_SESSION_CONFIG',
    SET_BOOKINGS_NETADATA = 'booking/SET_BOOKINGS_NETADATA',
    SET_BOOKING_PAGINATE = 'booking/SET_BOOKING_PAGINATE',
    SET_LOADING = 'booking/SET_LOADING',
    REFRESH_BOOKINGS = 'bookings/REFRESH_BOOKINGS',
    UPDATE_SINGLE_BOOKING_STATUS = 'bookings/UPDATE_SINGLE_BOOKING_STATUS',
    LOAD_BOOKINGS_COUNT = 'bookings/LOAD_BOOKINGS_COUNT',
    LOAD_BOOKINGS_COUNT_REQUEST = 'bookings/LOAD_BOOKINGS_COUNT_REQUEST',
    LOAD_BOOKINGS_COUNT_SUCCESS = 'bookings/LOAD_BOOKINGS_COUNT_SUCCESS',
    LOAD_BOOKINGS_COUNT_FAILURE = 'bookings/LOAD_BOOKINGS_COUNT_FAILURE',
    SET_ACTIVE_QUEUE = 'bookings/SET_ACTIVE_QUEUE',
    SAVE_CURRENT_PAGE = 'bookings/SAVE_CURRENT_PAGE',
    SET_ERROR = 'bookings/SET_ERROR'
}
export type SessionAttribute = 'isFlaggedImportant' | 'hasUnreadMessages';

//export type Plan = typeof pricing.plans[number];
export interface Filter {
    search?: string | null;
    queue?: QueueType;
    filter?: string[];
    page?: number;
}

export interface FilterElement {
    key: string;
    text: string;
    count: number;
    priority: number;
}
export interface FiltersCategory {
    key: string;
    value: string;
    isMulti: boolean;
    filters: FilterElement[];
    priority: number;
}

export interface BookingStatus {
    highestNonZeroPriority: number;
    queues: BookingQueue[];
    filterCategories: FiltersCategory[];
    inquiriesAndArchive?: BookingQueue[];
    activeQueue?: QueueType;
}
export interface LinksResponse {
    first: string;
    previous: string | null;
    next: string | null;
}

export interface SessionMetaResponse {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
}

export interface BookingsPaginate {
    meta: SessionMetaResponse;
    links: LinksResponse;
}

export interface BookingdParams {
    params?: {
        page?: number;
        limit?: number;
        queue?: QueueType;
        filters?: string[];
    };
}

export enum SingleBookingStatus {
    followUp = 'followUp',
    requestEndSession = 'requestEndSession',
    inquiriesReply = 'inquiriesReply'
}

export interface SingleBooking {
    booking: BookingType;
    status: SingleBookingStatus;
}

export interface BookingState {
    loading: boolean;
    stylist: Stylist | null;
    bookings: BookingType[];
    filters: Filter;
    totalBookings: number;
    bookingStatus: BookingStatus;
    clientsForStylist: any[];
    bookingMetadata: BookingConfigResponse;
    selectedBookingId: string | null;
    selectedFilters: Record<string, FilterOption[]>;
    unreadMessages: number;
    bookingPaginate: BookingsPaginate;
    bookingLoader: boolean;
    savedCurrentPage: number;
    error: Error | null;
}

export type Actions =
    | IActionWithPayload<ActionTypes.BOOK_STYLIST, Stylist>
    | IAction<ActionTypes.CLEAR_BOOKINGS_LIST>
    | IAction<ActionTypes.CLEAR_BOOKINGS_SELECTION>
    | IActionWithPayload<ActionTypes.CAMPAIGN_BOOKING, any>
    | IActionWithPayload<ActionTypes.LOAD_BOOKINGS_SUCCESS, BookingType[]>
    | IActionWithPayload<ActionTypes.SET_FILTER, Filter>
    | IActionWithPayload<ActionTypes.SET_TOTAL_BOOKINGS, number>
    | IActionWithPayload<ActionTypes.LOAD_CLIENTS_FOR_STYLIST_SUCCESS, any[]>
    | IActionWithPayload<
          ActionTypes.UPDATE_UNREAD_MESSAGES,
          { session_id: string; isRead: boolean }
      >
    | IActionWithPayload<
          ActionTypes.UPDATE_IMPORTANCE_LEVEL,
          { channel_id: string; isImportance: boolean }
      >
    | IActionWithPayload<ActionTypes.UPDATE_SELECTED_BOOKING_ID, string | null>
    | IActionWithPayload<ActionTypes.UPDATE_SELECTED_FILTERS, Record<string, FilterOption[]>>
    | IActionWithPayload<ActionTypes.SET_UNREAD_MESSAGES, number>
    | IAction<ActionTypes.LOAD_SESSION_CONFIG>
    | IActionWithPayload<ActionTypes.SET_LOADING, boolean>
    | IActionWithPayload<ActionTypes.SET_BOOKINGS_NETADATA, BookingConfigResponse>
    | IActionWithPayload<ActionTypes.SET_BOOKING_PAGINATE, BookingsPaginate>
    | IActionWithPayload<ActionTypes.REFRESH_BOOKINGS, BookingType[]>
    | IActionWithPayload<ActionTypes.UPDATE_SINGLE_BOOKING_STATUS, SingleBooking>
    | IActionWithPayload<ActionTypes.SET_ACTIVE_QUEUE, QueueType>
    | IActionWithPayload<ActionTypes.LOAD_BOOKINGS_COUNT_SUCCESS, BookingStatus>
    | IActionWithPayload<ActionTypes.SAVE_CURRENT_PAGE, number>
    | IActionWithPayload<ActionTypes.LOAD_BOOKINGS_FAILURE, Error | null>
    | IActionWithPayload<ActionTypes.LOAD_BOOKINGS_COUNT_FAILURE, Error | null>
    | IAction<ActionTypes.SET_ERROR>;
