import Stylist from './Stylist';

export const sendMessage = async ({
    stylist_uuid,
    client_uuid,
    type = 'text',
    sender = 'stylist',
    content
}: {
    stylist_uuid: string;
    client_uuid: string;
    type?: string;
    sender?: string;
    content: any;
}) => {
    return await Stylist.sendMessage({
        stylist_uuid,
        client_uuid,
        type,
        sender,
        content
    });
};
