import React from 'react';
import { sentryException } from 'services/SentryLogging';

import Content from '../../../content';

const ShareBar = ({ stylist }) => {
    const onShare = () => {
        try {
            navigator.share({
                title: stylist.name,
                text: stylist.name,
                url: window.location.href
            });
        } catch (e) {
            sentryException(e, "Couldn't share stylist details");
        }
    };

    return (
        <div className="share-bar" onClick={onShare}>
            {Content.stylist.share}
        </div>
    );
};

export default ShareBar;
