import './style.scss';

import { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton, Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { modal } from '../../../content';
import { isButtonDisable, isLocationDressingRoom } from '../../../services/utils/styleRoom-utils';
import { Loader } from '../..';

const SaveMoodboard = ({
    loading,
    stylingRoomLoading,
    loadTags,
    tags,
    saveMoodboard,
    toggleModal,
    redirectUrl,
    result,
    gender,
    clearResult,
    error,
    changeModal,
    clearError
}) => {
    const history = useHistory();
    const [description, setDescription] = useState('');
    const [postFeed, setPostFeed] = useState(false);
    const [selectedTag, setSelectedTag] = useState({});
    const [disable, setDisable] = useState(false);
    const isDressingRoom = isLocationDressingRoom();
    const isDisable = isButtonDisable(description, disable);

    useEffect(() => {
        loadTags(gender, 'style');
    }, []);

    useEffect(() => {
        if (tags.style) setSelectedTag(tags.style[0]);
    }, [tags]);

    useEffect(() => {
        if (result.message === 'success') {
            clearResult();
            toggleModal();
            if (!redirectUrl.includes('inbox')) window.location.href = redirectUrl;
            else history.push(redirectUrl);
        } else setDisable(false);
    }, [result]);

    useEffect(() => {
        if (error) {
            changeModal({
                type: 'Error',
                data: { error: modal.errors.sendBoard, callback: clearError }
            });
        }
    }, [error]);

    const submit = () => {
        setDisable(true);
        saveMoodboard({
            description,
            tags: { tag: selectedTag.name },
            publish_on_feed: postFeed
        });
    };

    return (
        <span className="save-moodboard">
            {(loading || stylingRoomLoading) && <Loader />}
            <span className="close" onClick={toggleModal} />
            <Modal.Header>
                {isDressingRoom ? modal.saveMoodboard.saveTitle : modal.saveMoodboard.title}
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>{modal.saveMoodboard.description}</Form.Label>
                    <Form.Control
                        as="textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    {!description.length && (
                        <p className="error">{modal.saveMoodboard.errors.description}</p>
                    )}
                </Form.Group>
                <Form.Label>{modal.saveMoodboard.tags}</Form.Label>
                <DropdownButton key="moodboard-tags" title={selectedTag?.name ?? ''}>
                    {tags.style &&
                        tags.style.map((tag) => (
                            <Dropdown.Item
                                eventKey={tag.uuid}
                                key={tag.uuid}
                                onSelect={() => setSelectedTag(tag)}
                            >
                                {tag.name}
                            </Dropdown.Item>
                        ))}
                </DropdownButton>
                <Form.Row>
                    <Form.Check
                        type="checkbox"
                        label={modal.saveStyleboard.feed}
                        onChange={(e) => setPostFeed(e.target.checked)}
                    />
                </Form.Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-test-id="send-board"
                    variant="warning"
                    onClick={submit}
                    disabled={isDisable}
                >
                    {isDressingRoom ? modal.saveButton : modal.saveMoodboard.button}
                </Button>
            </Modal.Footer>
        </span>
    );
};

export default SaveMoodboard;
