import React, { FC } from 'react';
import { Badge } from 'react-bootstrap';
import { Formatter } from 'services';
import styled from 'styled-components';
import { ClearFilterObject, FilterOptionsParameters, StylingRoomSection } from 'types/styling';

const Container = styled.div`
    max-width: calc(100% - 270px);

    .badge {
        position: relative;
        margin: 5px 13px 5px 0;
        font-size: 14px;
        border-color: #1d1d1d;
        height: 32px;
        padding: 8px 15px;

        .close {
            top: 3px;
            right: 6px;
            left: auto;
            width: 7px;
            height: 7px;

            &:before,
            &:after {
                height: 7px;
                left: auto;
                right: 0;
                width: 1px;
            }
        }
    }
`;

type TFilterBadgesProps = {
    view: StylingRoomSection;
    selectedFilters: Record<string, FilterOptionsParameters[]>;
    clearFilter: (clearFilter: ClearFilterObject) => void;
};

export const FilterBadges: FC<TFilterBadgesProps> = ({ selectedFilters, view, clearFilter }) => {
    const keyFilters = Object.keys(selectedFilters).filter(
        (key) => key !== 'maxPrice' && key !== 'max_budget'
    );
    const isShop = view === StylingRoomSection.shop;
    const isStore = view === StylingRoomSection.store;

    return (
        <Container>
            {keyFilters.map((key) =>
                selectedFilters[key].map((filter) => (
                    <Badge variant="light" key={filter.key}>
                        <div
                            id="badge-close"
                            className="close"
                            onClick={() =>
                                clearFilter({
                                    key,
                                    filter: {
                                        key: key,
                                        value: filter
                                    }
                                })
                            }
                        />
                        {isShop && key === 'minPrice' ? (
                            <span>
                                {Formatter.price(
                                    parseFloat(selectedFilters.minPrice[0]?.key || '')
                                )}
                                -
                                {Formatter.price(
                                    parseFloat(selectedFilters.maxPrice[0]?.key || '')
                                )}
                            </span>
                        ) : isStore && key === 'min_budget' ? (
                            <span>
                                {Formatter.price(
                                    parseFloat(selectedFilters.min_budget[0]?.key || '')
                                )}
                                -
                                {Formatter.price(
                                    parseFloat(selectedFilters.max_budget[0]?.key || '')
                                )}
                            </span>
                        ) : (
                            filter.text
                        )}
                    </Badge>
                ))
            )}
        </Container>
    );
};
