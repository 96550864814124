import axios from 'axios';
import * as jsCookie from 'js-cookie';

import { Config } from '.';

type LoginType = 'tokenLogin' | 'emailLogin' | null;

interface Credentials {
    token?: string;
    email?: string;
    password?: string;
}

const UserAuth = {
    isAuth: () => !!jsCookie.get('user'),
    login: async (credentials: Credentials) => {
        try {
            const loginType: LoginType = credentials.token ? 'tokenLogin' : 'emailLogin';
            return await UserAuth[loginType](credentials);
        } catch (e) {
            return Promise.reject(e);
        }
    },
    emailLogin: async (loginData: Credentials) => {
        if (loginData.email && loginData.password) {
            return await axios.put(`${Config.get('api-gw')}login/email`, {
                client: `${Config.isMobile() ? 'mobile-' : ''}web`,
                email: loginData.email,
                password: loginData.password
            });
        }
    },
    tokenLogin: async ({ token }: Credentials) => {
        return await axios.put(`${Config.get('api-gw')}login/token`, { token });
    },
    getToken: async () =>
        await axios.get(
            `${Config.get('apiroot2')}user/getToken/${Config.isMobile() ? 'mobile-' : ''}web`
        ),
    resetPassword: async (email: Credentials) =>
        await axios.post(`${Config.get('apiroot2')}user/resetPassword`, { email })
};

export default UserAuth;
