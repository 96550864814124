import { FC, useMemo } from 'react';
import styled from 'styled-components';

import { Formatter } from '../../../../services';
import { TGridItem } from './types';

const Container = styled.div`
    display: inline-flex;
    width: 215px;
    height: 300px;
    border: 1px solid #f4f4f4;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
`;

const ItemImage = styled.img`
    display: block;
    width: 100%;
    height: 215px;
    object-fit: contain;
`;

const Details = styled.div`
    margin-top: 5px;
    font-family: UntitledSans-Regular, sans-serif;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.7px;
`;

const Title = styled.div`
    font-family: UntitledSans-Medium, sans-serif;
`;

const Price = styled.div``;

type TProps = TGridItem & {
    onClick?: () => void;
};

export const GridItem: FC<TProps> = ({ title, imageUrl, price, onClick }) => {
    const formattedPrice = useMemo(() => Formatter.price(price), [price]);

    return (
        <Container onClick={onClick}>
            <ItemImage src={imageUrl} />
            <Details>
                <Title>{title}</Title>
                {price && <Price>{formattedPrice}</Price>}
            </Details>
        </Container>
    );
};
