import React, { VFC } from 'react';
import styled from 'styled-components';

import { TGridSize } from '../types';

const Container = styled.div`
    display: flex;
    margin-left: auto;

    button {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-radius: 2px;
        margin-right: 7px;
        background: transparent no-repeat center/26px;
        opacity: 0.5;
        border: none;

        &.active,
        &:hover {
            opacity: 1;
        }

        &.large {
            background-image: url(/assets/icons/grid-large.svg);
        }

        &.small {
            background-image: url(/assets/icons/grid-small.svg);
        }
    }
`;

type TProps = {
    gridSize: TGridSize;
    setGridSize: (gridSize: TGridSize) => void;
};

export const GridSizeButtons: VFC<TProps> = ({ gridSize, setGridSize }) => {
    return (
        <Container>
            <button
                className={`large ${gridSize === 'large' && 'active'}`}
                onClick={() => setGridSize('large')}
            />
            <button
                className={`small ${gridSize === 'small' && 'active'}`}
                onClick={() => setGridSize('small')}
            />
        </Container>
    );
};
