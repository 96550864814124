import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleModal, changeModal } from 'store/ui/actions';

import { loadTags } from '../../../redux/reducers/style/actions';
import {
    saveLook,
    stylingRoomClearResult,
    clearError
} from '../../../redux/reducers/stylingRoom/actions';
import SaveStyleboard from './SaveStyleboard';

const mapStateToProps = ({
    ui: { redirectUrl },
    style: { loading, tags},
    stylingRoom: { loading: stylingRoomLoading, result, gender, styleRequest, error },
    room: { revision }
}) => ({
    loading,
    stylingRoomLoading,
    styleRequest,
    tags,
    result,
    gender,
    revision,
    redirectUrl,
    error
});

const mapDispatchToProps = (dispatch) => ({
    loadTags: (gender, type) => dispatch(loadTags(gender, type)),
    saveLook: (info, type, revision) => dispatch(saveLook(info, type, revision)),
    clearResult: () => dispatch(stylingRoomClearResult()),
    toggleModal: (data) => toggleModal(dispatch, data),
    changeModal: (data) => changeModal(dispatch, data),
    clearError: () => dispatch(clearError(dispatch))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveStyleboard));
