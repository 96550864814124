import './style.scss';

import { Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { settings as texts } from '../../../content';

const Main = () => {
    const history = useHistory();

    return (
        <Container className="settings-main" fluid>
            <Row>
                <Col>
                    <h3>{texts.title}</h3>
                </Col>
            </Row>
            <Container>
                <Row>
                    {texts.sections.map(({ key, title, description }) => (
                        <Col xs={12} md={4} key={key}>
                            <Card onClick={() => history.push(`/settings/${key}`)}>
                                <Card.Title>
                                    <div>{title}</div>
                                    <div className="arrow right" />
                                </Card.Title>
                                <Card.Body>{description}</Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Container>
    );
};

export default Main;
