import './style.scss';

import { createElement, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import { Page } from '../../components';
import Sections from './sections';

const Setting = (props) => {
    const { tab = 'main' } = useParams();
    const history = useHistory();
    const [section, setSection] = useState();

    useEffect(() => {
        if (tab.length) Sections[tab] ? setSection(Sections[tab]) : history.push('/settings');
    }, [tab]);

    return (
        <Page footer={false}>
            <div className="settings">
                <Container>{section ? createElement(section, props) : ''}</Container>
            </div>
        </Page>
    );
};

export default Setting;
