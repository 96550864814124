import { connect } from 'react-redux';

import {
    addToCanvas,
    removeFromCanvas,
    setCanvasLayout,
    setCanvasLoad,
    setCanvasSizeIndex,
    updateCanvasState,
    updateCanvasTotalPrice
} from '../../redux/reducers/stylingRoom/actions';
import Canvas from './Canvas';
import _CanvasGrid from './CanvasGrid';
import _PresistentCanvas from './PersistentCanvas';

const mapStateToProps = ({
    stylingRoom: {
        canvasLoading,
        canvasSizesArray,
        canvasSizeIndex,
        canvasLayout,
        items,
        ps,
        canvasState,
        psTotalPrice,
        itemsTotalPrice
    }
}) => ({
    canvasLoading,
    canvasSizesArray,
    canvasSizeIndex,
    canvasLayout,
    items,
    ps,
    canvasState,
    psTotalPrice,
    itemsTotalPrice
});

const mapDispatchToProps = (dispatch) => ({
    setCanvasSizeIndex: (size) => dispatch(setCanvasSizeIndex(size)),
    setCanvasLayout: (layout) => dispatch(setCanvasLayout(layout)),
    updateCanvasState: (canvas) => dispatch(updateCanvasState(canvas)),
    removeFromCanvas: (id) => dispatch(removeFromCanvas(id)),
    setCanvasLoad: (loading) => dispatch(setCanvasLoad(loading)),
    updateCanvasTotalPrice: () => dispatch(updateCanvasTotalPrice()),
    addToCanvas: (item, type) => dispatch(addToCanvas(item, type))
});

export const CanvasGrid = connect(mapStateToProps, mapDispatchToProps)(_CanvasGrid);
export const PresistentCanvas = connect(mapStateToProps, mapDispatchToProps)(_PresistentCanvas);
export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
