import config from './config';
import style from './style';
import { stylingRoom } from './stylingRoom';
import stylists from './stylists';
import users from './users';

export default {
    users,
    config,
    style,
    stylists,
    stylingRoom
};

export * from './stylingRoom';
