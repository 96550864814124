import { Client, Stylist } from './user';
export type ItemType = 'shop' | 'inspiration' | 'closet' | 'store' | 'url' | 'catalog';
export type Currency = 'USD' | 'GBP' | 'EUR';
export type Gender = 'male' | 'female' | 'neutral';
export interface GeneralStylePreferenceKeyValue {
    key: string;
    value: string;
}

export interface GeneralStylePreferenceNameUuid {
    uuid: string;
    name: string;
}

export enum StylingRoomSection {
    shop = 'shop',
    store = 'store',
    closet = 'closet',
    inspiration = 'inspiration'
}

export interface FilterStructInspiration {
    tag_uuid: string;
    tag_name: string;
    tag_count: number;
}
export interface InspirationItem {
    brand_name: string | null;
    buy_url: string;
    gender: string;
    item_uuid: string;
    picture: string;
    picture_large: string;
    picture_medium?: string;
    picture_small?: string;
    price: string;
    tagTagName: string[];
    uuid: string;
    cropped: null;
    id?: string;
}

export interface InspirationQueryParameters {
    keywords?: string;
    from?: number;
    count?: number;
    is_trend?: number;
    gender?: string;
}
export interface ClosetQueryParameters {
    category_uuids?: string;
    category?: string;
    colors?: string;
    from?: number;
    count?: number;
    gender?: string;
    brands?: string;
    fields?: string;
    seasons?: string;
    user_uuid?: string;
}

export interface BoardsQueryParameters {
    type?: string;
    from?: number;
    count?: number;
    gender?: string;
    user_uuid?: string;
    style_tags?: string;
}
export interface Item {
    item_uuid?: string;
    itemType: ItemType;
    additionalImages?: string[];
    age?: string;
    ageGroup?: string;
    availability?: string;
    brand: string;
    brand_name?: string;
    buyUrl: string;
    buy_url?: string;
    buyUrlShort: string;
    buy_url_short?: string;
    category: string;
    color?: string | string[];
    condition?: string;
    createdTime: string;
    discription?: string;
    discount?: string;
    favorite: string | boolean;
    feed: string;
    gender: string;
    itemBrand?: string;
    keywords?: string;
    material?: string;
    merchant_name?: string;
    name?: string;
    notes?: string;
    pattern?: string;
    picture?: string;
    pictureUrl: string;
    picture_large?: string;
    picture_medium?: string;
    picture_small?: string;
    sku: string[] | string;
    photo_large?: string;
    product_id?: string | string[];
    retailerPrice?: string;
    productUrl: string;
    shortDescription: string;
    longDescription?: string;
    price: number | string;
    currency: Currency;
    uuid?: string;
    size: string[] | string;
    sizes?: string[];
    tags: string | string[];
    type?: string;
    originalItemUnique?: string;
    items?: Item[] | OrderItem[];
    id?: string;
}

export interface Order extends Item {
    appartment: string;
    brand_name: string;
    category_name: string;
    category_uuid: string;
    city: string;
    colors: string;
    country: string;
    created: string;
    first_address: string;
    item_buy_url: string;
    item_size: string;
    item_uuid: string;
    items: Item[] | OrderItem[];
    order_number: string;
    picture: string;
    price: string;
    second_address: string;
    shipping: string;
    state: string;
    subtotal: string;
    tax: string;
    total: string;
    zipcode: string;
    uuid?: string;
}

export interface OrderItem extends Order {
    brand_name: string;
    color: string;
    item_buy_url: string;
    item_size: string;
    item_uuid: string;
    order_uuid: string;
    original_name: string;
    picture: string;
    price: string;
    status_name: string;
}

export interface ClosetItem extends Item {
    available_for_checkout?: boolean;
    brand_name: string;
    brand_id?: string;
    buy_url: string;
    buy_url_short?: string;
    categoryUuid?: string;
    category_name?: string;
    colors: string | string[];
    colors_and_prints?: string[];
    has_sizes_information?: boolean;
    is_in_closet?: boolean;
    is_like?: number;
    item_uuid: string;
    notes?: string;
    picture: string;
    picture_large: string;
    picture_medium?: string;
    picture_small?: string;
    price: string;
    prints: string;
    retail_price?: string;
    retailer_name?: string;
    sizes?: string[];
    source_item_uuid?: string;
    source_look_uuid?: string;
    seasons: string[];
    uuid: string;
    order_number?: string;
}

export type TWishlistItem = {
    brandName: string;
    price: number;
    productName: string;
    productPhotoUrl: string;
};

export interface ClothingCategories {
    key: string;
    uuid: string;
    value: string;
}
export interface ShopQueryParameters {
    category?: string;
    count?: number;
    from?: number;
    gender?: string;
    user_uuid?: string;
    userUuid?: string;
    client?: string;
    fields?: string;
    itemBrand?: string;
    page?: number;
}
export interface FilterOptionsParameters {
    key?: string;
    text?: string;
    single_select?: boolean;
    picture?: string;
    value?: string | number;
    min?: { key: string; text: string };
    max?: { key: string; text: string };
    keywords?: string;
    count?: number;
    priority?: number;
    singleSelect?: boolean;
}
export interface FilterStruct extends GeneralStylePreferenceKeyValue {
    filter_options?: FilterOptionsParameters | FilterOptionsParameters[];
    isMulti?: boolean;
}

export interface FilterMap extends GeneralStylePreferenceKeyValue {
    filter_options: FilterOptionsParameters[];
}

export interface FilterParams {
    key: string;
    value: FilterOptionsParameters | FilterOptionsParameters[];
}

export interface ClearFilterObject {
    key: string;
    event?: any;
    filter?: FilterParams;
}

export interface BudgetRange {
    budget_max: string;
    budget_min: string;
    categories: GeneralStylePreferenceNameUuid[];
    name: string;
    ranges: string[];
}
export interface ColorPrintsObject {
    key: string;
    name: string;
    picture: string;
}
export interface CategorySize {
    category_name: string;
    category_uuid: string;
    size: string;
}

export interface StyleData {
    age: string;
    birthday: string;
    body_tags?: string[];
    body_type?: string;
    bookings_count: string;
    bottom_size: string;
    brands?: GeneralStylePreferenceNameUuid[];
    budget: number;
    budget_bag?: string | number;
    budget_blouse?: string | number;
    budget_jacket?: string | number;
    budget_max: number;
    budget_ranges: BudgetRange[];
    budget_shoes?: string | number;
    clothing_categories: ClothingCategories[];
    clothing_preferences: GeneralStylePreferenceKeyValue;
    colors: string[];
    colors_and_prints: string[];
    colors_and_prints_objects: ColorPrintsObject[];
    comfort_zone: GeneralStylePreferenceKeyValue;
    dress_size: string;
    events?: string[];
    fabrics: GeneralStylePreferenceKeyValue[];
    facebook_username?: string;
    fit_bottom: GeneralStylePreferenceKeyValue[];
    fit_top: GeneralStylePreferenceKeyValue[];
    gender: string;
    goals: string[];
    goals_new: string[];
    heels: GeneralStylePreferenceKeyValue[];
    heels_type: GeneralStylePreferenceKeyValue[];
    heels_type_comments?: string;
    height: GeneralStylePreferenceKeyValue;
    highlight: GeneralStylePreferenceKeyValue[];
    holiday_type: string[];
    instagram_username?: string;
    jeans: GeneralStylePreferenceKeyValue[];
    jeans_size?: string;
    jewelry: GeneralStylePreferenceKeyValue[];
    linkedin_username?: string;
    locations: string;
    pinterest_username: string;
    prints: GeneralStylePreferenceKeyValue[];
    retailers: GeneralStylePreferenceKeyValue[];
    shoe_size: string;
    size_comments: string;
    size_one_piece?: string;
    size_outwear?: string;
    size_underwear?: string;
    sizes: CategorySize[];
    source?: string;
    style_icons: string[];
    style_icons_comments?: string;
    style_tags: GeneralStylePreferenceNameUuid[];
    top_size: string;
    what_matters: GeneralStylePreferenceKeyValue[];
    what_matters_comments?: string;
    work_comments?: string;
    work_type: GeneralStylePreferenceKeyValue;
    zodiac_sign: string;
}

export interface IStyleRequest {
    brand?: string;
    budget_max?: string;
    budget_min?: string;
    created_at: string;
    description: string;
    event: GeneralStylePreferenceNameUuid;
    event_other_text: string;
    events: string;
    feedback?: string;
    goal_other_text: string;
    goals: string;
    holiday_other_text: string;
    holiday_type?: string;
    inventory_name: string;
    owner: Client;
    request_id: string;
    styleData: StyleData;
    stylist: Stylist;
    time?: string;
    uuid: string;
    weather?: string;
}

export interface FavouritesFilterParams {
    item: InspirationItem | Item;
    attribute: string;
    gender: string;
    params: Record<string, any>;
}

export enum RoomState {
    look = 'look',
    personal = 'personal',
    revision = 'revision',
    moodboard = 'moodboard'
}

export interface Photo {
    photo_uuid: string;
    photo_large: string;
    photo_medium: string;
    photo_small: string;
}

export type TCanvasLayout = 'free' | 'grid';
