import './style.scss';

import { inbox } from 'content.json';
import React, { useEffect, useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { Client, Stylist } from 'types/user';

import Info from './Info';
import Boards from './Boards';
import Notes from './Notes';
import Photos from './Photos';
import { Quiz } from './Quiz';
import Style from './Style';

interface IClientInfo {
    client: Client;
    stylist: Stylist;
}

const ClientInfo: React.FC<IClientInfo> = ({ client, stylist }) => {
    const [uuid, setUuid] = useState(client.user_uuid ?? '');
    const [tab, setTab] = useState<string>('info');

    useEffect(() => {
        setUuid(client?.user_uuid ?? '');
        if (tab !== 'info') setTab('info');
    }, [client, uuid]);

    return (
        <Container className="client-info">
            <Tabs
                id={tab}
                activeKey={tab}
                onSelect={(tab: string) => {
                    setTab(tab);
                }}
            >
                <Tab eventKey="info" title={inbox.bookings.info.tabs.info}>
                    {tab === 'info' && <Info client={client} uuid={uuid} />}
                    {tab === 'info' && <Quiz client={client} uuid={uuid} />}
                </Tab>
                <Tab eventKey="photos" title={inbox.bookings.info.tabs.photos}>
                    {tab === 'photos' && <Photos uuid={uuid} />}
                </Tab>
                <Tab eventKey="style" title={inbox.bookings.info.tabs.style}>
                    {tab === 'style' && <Style uuid={uuid} />}
                </Tab>
                <Tab eventKey="boards" title={inbox.bookings.info.tabs.boards}>
                    {tab === 'boards' && <Boards uuid={uuid} stylist={stylist} />}
                </Tab>
                <Tab eventKey="notes" title={inbox.bookings.info.tabs.notes}>
                    {tab === 'notes' && <Notes uuid={uuid} stylist={stylist} />}
                </Tab>
            </Tabs>
        </Container>
    );
};

export default ClientInfo;
