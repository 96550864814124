import './style.scss';

import { useEffect, useState } from 'react';
import { Dropdown, Image, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { logo, navbar } from '../../content';
import { ProfilePhoto } from '../';

const NavBar = ({ onClick, user, logout, location, fixed = 'top' }) => {
    const [expended, setExpended] = useState(false);
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        setToggle(false);
    }, [location]);

    return (
        <Navbar className={`nav-bar retailers-page`} fixed={fixed} expand="sm" expanded={expended}>
            <Navbar.Brand className="logo">
                <Image src={logo.black} className="d-flex d-sm-none" />
                <Image src={logo.text_black} className="d-none d-sm-flex" />
            </Navbar.Brand>

            {!user?.hasOwnProperty('email') ? (
                <Nav>
                    <Nav.Link />
                </Nav>
            ) : (
                // Logged in
                <>
                    <Navbar.Toggle onClick={() => setExpended(!expended)}>
                        <Image src={navbar.icons.hamburger} />
                    </Navbar.Toggle>

                    {/*Left bar*/}
                    <Nav className="mobile-nav d-flex d-sm-none">
                        <span>
                            {user.type !== 'stylist' && (
                                <Link to="/inbox" className="inbox">
                                    <Image className="inbox" src={navbar.icons.inbox} />
                                </Link>
                            )}
                        </span>
                    </Nav>

                    <Navbar.Collapse onClick={() => setExpended(false)}>
                        <Nav>
                            <span className="d-flex d-sm-none">
                                <Link to="/profile/closet">{navbar.profile}</Link>
                                <Link to="/settings">{navbar.settings}</Link>
                                <Link to="#" onClick={logout} data-test-id="logout-button">
                                    {navbar.logout}
                                </Link>
                            </span>
                        </Nav>

                        {/*Right bar*/}
                        <Nav onSelect={onClick} className="justify-content-end">
                            <Link to="/inbox" className="d-none d-sm-flex">
                                {user.type === 'stylist' ? navbar.bookings : navbar.sessions}
                            </Link>
                            <Dropdown
                                className="user-menu d-none d-sm-flex"
                                show={toggle}
                                onToggle={setToggle}
                                data-test-id="user-menu"
                            >
                                <Dropdown.Toggle>
                                    <ProfilePhoto user={user} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu alignRight={true}>
                                    <Link to="/profile/closet">{navbar.profile}</Link>
                                    {user.type === 'stylist' && (
                                        <Link to="/dressing-room/closet">{navbar.dressing}</Link>
                                    )}
                                    <Dropdown.Divider />
                                    <Link to="/settings">{navbar.settings}</Link>
                                    <Dropdown.Item onClick={logout} data-test-id="logout-button">
                                        {navbar.logout}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </>
            )}
        </Navbar>
    );
};

export default NavBar;
