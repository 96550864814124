import jsCookies from 'js-cookie';

const getCookieByName = (name: string) => {
    const cookie = jsCookies.get(name);
    let value;
    try {
        if (cookie) value = JSON.parse(cookie);
    } catch (e) {
        value = cookie ? cookie : {};
    }
    return value;
};

export const getUserToken = () => getCookieByName('user').token;
