import styled from 'styled-components';
import { LabelType } from 'types/booking';

interface LabelProps {
    labelType: number;
    isSelected: boolean;
    labels: Record<string, LabelType>;
}

export const BookingLabel = styled.p<LabelProps>(
    (props) =>
        `background: ${props.labels[props.labelType].background};
        color: ${props.labels[props.labelType].color};
        border: ${props.labelType === 0 && props.isSelected ? '1px solid #9A9A9A' : 'none'};`
);
