import './style.scss';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { modal } from '../../../content';

const RemovePhoto = ({ removePhoto, data, toggleModal }) => {
    const onSubmit = () => {
        removePhoto(data.photo_uuid);
        toggleModal();
    };

    return (
        <span className="remove-photo">
            <Modal.Header>
                <p>{modal.removePhoto.title}</p>
            </Modal.Header>

            <Modal.Footer>
                <Button variant="light" onClick={() => toggleModal()}>
                    {modal.removePhoto.cancel}
                </Button>
                <Button variant="dark" onClick={onSubmit}>
                    {modal.removePhoto.submit}
                </Button>
            </Modal.Footer>
        </span>
    );
};

export default RemovePhoto;
