import '../style.scss';

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

interface ICheckbox {
    checked: boolean;
    picture: string;
    className: string;
    value: string;
    label: string;
    onChange: ({ text }: { text: string }) => void;
}

const Checkbox: React.FC<ICheckbox> = ({
    onChange,
    checked = false,
    value = '',
    className = '',
    label
}) => {
    const [check, setCheck] = useState(false);

    useEffect(() => {
        setCheck(checked);
    }, [checked]);

    const onChecked = (e: React.FormEvent<HTMLInputElement>) => {
        setCheck(e.currentTarget.checked);
        onChange({ text: value });
    };

    return (
        <Form.Group>
            <input
                type="checkbox"
                id={`checkbox-${value}`}
                className={`checkbox ${className}`}
                onChange={onChecked}
                value={value}
                checked={check}
            />
            {label && (
                <label className="checkbox-label" htmlFor={`checkbox-${value}`}>
                    {label}
                </label>
            )}
        </Form.Group>
    );
};

export default Checkbox;
