import './style.scss';

import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

interface IReviewData {
    client_name: string;
    rate: string;
    description: string;
    date: string;
    word_count: string;
}
const changeModalHeight = (modalClass: string, classToAdd: string) => {
    const modal = document.querySelector(modalClass);
    if (modal) {
        const modalElement = modal as HTMLElement;
        modalElement.classList.add(classToAdd);
    }
};

const ReadReview: React.FC<{ data?: IReviewData }> = ({ data }) => {
    useEffect(() => {
        changeModalHeight('.modal-dialog', 'reviewReader-modal');
    }, []);
    return (
        <span className="reviewReader-modal">
            <Modal.Header closeButton />
            <Modal.Body>
                {data && (
                    <>
                        <span className="reviewReader-modal clientName">{data.client_name}</span>
                        <span className="reviewReader-modal reviewDate">{data.date}</span>
                        <span
                            className="reviewReader-modal ratingStar"
                            style={{ width: parseInt(data.rate) * 35 }}
                        ></span>
                        <p className="reviewReader-modal cardText">{data.description}</p>
                    </>
                )}
            </Modal.Body>
        </span>
    );
};
export default ReadReview;
