import './style.scss';

import React, { useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { ErrorState } from 'store/error/types';

import { modal } from '../../../content.json';
import { Validator } from '../../../services';
import UserAuth from '../../../services/UserAuth';
import { ErrorEmail } from '../../../types/utils';

interface IResetPassword {
    changeModal: (type: string) => void;
    resetPasswordFailed: (error: any) => void;
    toggleModal: () => void;
    error: ErrorState;
}

const ResetPassword: React.FC<IResetPassword> = ({
    changeModal,
    resetPasswordFailed,
    toggleModal,
    error
}) => {
    const [errors, setErrors] = useState<ErrorEmail>({});

    const validate = (email: string) => {
        const errors = { ...Validator.email(email) };
        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    };

    return (
        <span className="reset-password">
            <Modal.Header closeButton>
                <Modal.Title>{modal.reset.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form
                    noValidate
                    onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        const email = e.currentTarget.email.value;

                        if (validate(email)) {
                            try {
                                await UserAuth.resetPassword(email);
                                changeModal('ResetConfirmation');
                            } catch (e: any) {
                                resetPasswordFailed(e as Error);
                            }
                        }
                    }}
                >
                    <Form.Row>
                        <Form.Text as={Col}>{modal.reset.description}</Form.Text>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="email">
                            <Form.Control
                                type="email"
                                isInvalid={!!error || errors.email}
                                required
                            />
                            {errors.email && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            )}

                            {error && (
                                <Form.Control.Feedback type="invalid">
                                    {error.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <strong onClick={() => toggleModal()}>{modal.reset.back}</strong>
                        </Col>
                        <Col>
                            <Button variant="dark" id="submit" type="submit" className="submit-btn">
                                {modal.reset.send}
                            </Button>
                        </Col>
                    </Form.Row>
                </Form>
            </Modal.Body>
        </span>
    );
};

export default ResetPassword;
