import { Dispatch } from 'redux';

import { ActionTypes, Revision } from './types';

export const setRoomStylingType = (dispatch: Dispatch, payload: string) => {
    dispatch({
        type: ActionTypes.SET_STYLING_ROOM_TYPE,
        payload
    });
};
export const setRevision = (dispatch: Dispatch, revision: Revision | null) =>
    dispatch({
        type: ActionTypes.SET_REVISION,
        payload: revision
    });
