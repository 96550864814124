import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { UserAuth } from 'services';

interface IPrivateRoute extends RouteProps {
    component: any;
}
const PrivateRoute: React.FC<IPrivateRoute> = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            UserAuth.isAuth() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);
export default PrivateRoute;
