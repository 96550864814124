import './style.scss';

import { Button, Modal } from 'react-bootstrap';

import { modal } from '../../../content';

const Error = ({ data = {}, toggleModal }) => {
    const onSubmit = () => {
        activeCallback();
        toggleModal();
    };

    const activeCallback = () => {
        if (data?.callback) data.callback();
    };

    return (
        <span className="error">
            <Modal.Header closeButton onClick={activeCallback}>
                <p>{modal.error.title}</p>
            </Modal.Header>

            <Modal.Body>
                <p>{data && data.error}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="dark" onClick={onSubmit}>
                    {modal.error.submit}
                </Button>
            </Modal.Footer>
        </span>
    );
};

export default Error;
