import { AnyAction } from 'redux';

import { Formatter } from '../../../services';
import {
    ADD_TO_CANVAS,
    CATEGORIES_DATA_AND_SIZES,
    CLEAR_CANVAS,
    CLEAR_CLOSET,
    CLEAR_ERROR,
    CLEAR_SELECTED_FILTERS,
    CLEAR_SHOP,
    CLEAR_STORE,
    CLEAR_WISHLIST,
    LOAD_FILTERS_FAILED,
    LOAD_FILTERS_START,
    REMOVE_FROM_CANVAS,
    SET_CANVAS_LAYOUT,
    SET_CANVAS_LOAD,
    SET_CANVAS_SIZE_INDEX,
    SET_GRID_SIZE,
    STYLING_ROOM_CLEAR_RESULT,
    STYLING_ROOM_LOAD_FAILED,
    STYLING_ROOM_LOAD_START,
    STYLING_ROOM_SAVE_SUCCESS,
    TOGGLE_WISHLIST_LOADING,
    UPDATE_AVAILABILITY,
    UPDATE_AVAILABILITY_FILLER,
    UPDATE_BOARDS,
    UPDATE_CANVAS_DATA_URL,
    UPDATE_CANVAS_STATE,
    UPDATE_CANVAS_TOTAL_PRICE,
    UPDATE_CLIENT_CART,
    UPDATE_CLOSET,
    UPDATE_FILTERS,
    UPDATE_GENDER,
    UPDATE_INSPIRATION,
    UPDATE_SELECTED_FILTERS,
    UPDATE_SHOP,
    UPDATE_STORE,
    UPDATE_STYLE_REQUEST,
    UPDATE_WISHLIST
} from './actions';
import { TStylingRoomState } from './types';

const stockUpdateRetailers = ['net-a-porter'];

const initialState: TStylingRoomState = {
    loading: false,
    canvasLoading: false,
    loadingFilters: false,
    styleRequest: {},
    shop: [],
    closet: [],
    store: [],
    inspiration: [],
    boards: [],
    cart: [],
    wishlistItems: [],
    wishlistLoading: false,
    wishlistPage: 1,
    filters: [],
    filtersType: null,
    selectedFilters: {},
    gender: 'female',
    gridSize: 'small',
    canvasSizesArray: [45, 150, 600],
    canvasSizeIndex: 1,
    canvasLayout: 'free',
    error: null,
    items: [],
    ps: [],
    canvasState: null,
    canvasDataUrl: '',
    result: {},
    total: 0,
    revision: {},
    itemsAvailability: {},
    retailerStockForUpdate: stockUpdateRetailers,
    categories: {},
    nextPage: 1,
    psTotalPrice: Formatter.price(0),
    itemsTotalPrice: Formatter.price(0)
};

export const stylingRoom = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case STYLING_ROOM_LOAD_START: {
            return {
                ...state,
                loading: true
            };
        }

        case LOAD_FILTERS_START: {
            return {
                ...state,
                loadingFilters: true
            };
        }

        case SET_CANVAS_LOAD: {
            const { loading } = action.payload;
            return {
                ...state,
                canvasLoading: loading
            };
        }

        case UPDATE_STYLE_REQUEST: {
            const { styleRequest } = action.payload;
            return {
                ...state,
                styleRequest,
                loading: false
            };
        }

        case UPDATE_CLOSET: {
            const { closet, total } = action.payload;
            return {
                ...state,
                closet,
                total,
                loading: false
            };
        }

        case UPDATE_SHOP: {
            const { shop, total, nextPage } = action.payload;
            return {
                ...state,
                shop,
                total,
                loading: false,
                nextPage
            };
        }

        case UPDATE_STORE: {
            const { store, total } = action.payload;
            return {
                ...state,
                store,
                total,
                loading: false
            };
        }

        case UPDATE_INSPIRATION: {
            const { inspiration } = action.payload;
            return {
                ...state,
                inspiration,
                loading: false
            };
        }

        case UPDATE_BOARDS: {
            const { boards } = action.payload;
            return {
                ...state,
                boards,
                loading: false
            };
        }

        case UPDATE_CLIENT_CART: {
            const { cart, total } = action.payload;
            return {
                ...state,
                cart,
                total,
                loading: false
            };
        }

        case UPDATE_FILTERS: {
            const { filters, type } = action.payload;
            return {
                ...state,
                filters,
                filtersType: type,
                loadingFilters: false
            };
        }

        case UPDATE_SELECTED_FILTERS: {
            const { selectedFilters } = action.payload;
            return {
                ...state,
                selectedFilters
            };
        }

        case UPDATE_GENDER: {
            const { gender } = action.payload;
            if (state.gender === gender) return state;
            return {
                ...state,
                shop: null,
                inspiration: [],
                gender: gender || 'female'
            };
        }

        case SET_GRID_SIZE: {
            const { gridSize } = action.payload;
            return {
                ...state,
                gridSize
            };
        }

        case SET_CANVAS_SIZE_INDEX: {
            const { canvasSizeIndex } = action.payload;
            return {
                ...state,
                canvasSizeIndex
            };
        }
        case SET_CANVAS_LAYOUT: {
            const { canvasLayout } = action.payload;
            return {
                ...state,
                canvasLayout
            };
        }

        case ADD_TO_CANVAS: {
            const item = action.payload.item;
            const type = action.payload.type;
            const list = type === 'grid' ? 'ps' : 'items';
            const attr = getUniqueAttribute(item);
            const attrValue = attr === 'sku' ? item.sku[0] : item[attr];
            const exist = state[list].findIndex((itm) => {
                return itm.item_uuid === attrValue || itm.uuid === attrValue;
            });
            return {
                ...state,
                [list]:
                    exist > -1
                        ? state[list]
                        : [
                              ...state[list],
                              {
                                  ...item,
                                  id: `${state.canvasLayout}_${state[list].length}`,
                                  item_uuid: attrValue,
                                  uuid: attrValue,
                                  picture: item.pictureUrl || item.picture || item.data.pictureUrl
                              }
                          ]
            };
        }

        case REMOVE_FROM_CANVAS: {
            const { id } = action.payload;
            const list = state.canvasLayout === 'grid' ? 'ps' : 'items';
            const filtered = [...state[list]].filter((item) => item && item.id !== id);
            return {
                ...state,
                [list]: filtered
            };
        }

        case CLEAR_CANVAS: {
            return {
                ...state,
                items: [],
                ps: []
            };
        }

        case CLEAR_SELECTED_FILTERS: {
            return {
                ...state,
                selectedFilters: {}
            };
        }

        case CLEAR_CLOSET: {
            return {
                ...state,
                total: 0,
                closet: []
            };
        }

        case CLEAR_STORE: {
            return {
                ...state,
                store: [],
                total: 0
            };
        }

        case CLEAR_ERROR: {
            return {
                ...state,
                error: null
            };
        }

        case UPDATE_AVAILABILITY: {
            return {
                ...state,
                itemsAvailability: action.payload['itemsAvailability']
            };
        }

        case UPDATE_AVAILABILITY_FILLER: {
            return {
                ...state,
                itemsAvailability: action.payload['fillerItems']
            };
        }

        case UPDATE_CANVAS_STATE: {
            const { canvasState } = action.payload;
            return {
                ...state,
                canvasState
            };
        }

        case UPDATE_CANVAS_TOTAL_PRICE: {
            const { items, ps } = state;

            const itemsTotalPrice = items.reduce(
                (sum, item) => sum + (parseFloat(item.price.toString()) || 0),
                0
            );

            const psTotalPrice = ps.reduce(
                (sum, item) => sum + (parseFloat(item.price.toString()) || 0),
                0
            );

            const currency = items[0]?.currency;

            return {
                ...state,
                itemsTotalPrice: Formatter.price(itemsTotalPrice, undefined, currency),
                psTotalPrice: Formatter.price(psTotalPrice, undefined, currency)
            };
        }

        case UPDATE_CANVAS_DATA_URL: {
            const { canvasDataUrl } = action.payload;
            return {
                ...state,
                canvasDataUrl
            };
        }

        case STYLING_ROOM_SAVE_SUCCESS: {
            const { result } = action.payload;
            return {
                ...state,
                result,
                revision: {},
                loading: false
            };
        }

        case STYLING_ROOM_CLEAR_RESULT: {
            return {
                ...state,
                result: {}
            };
        }

        case STYLING_ROOM_LOAD_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                error,
                loading: false
            };
        }

        case LOAD_FILTERS_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                error,
                loadingFilters: false
            };
        }

        case CATEGORIES_DATA_AND_SIZES: {
            const categories = action.payload;
            return {
                ...state,
                categories: categories
            };
        }

        case CLEAR_SHOP: {
            return {
                ...state,
                shop: null,
                nextPage: 1
            };
        }

        case UPDATE_WISHLIST: {
            const { items, page } = action.payload;
            return {
                ...state,
                wishlistItems: page === 1 ? items : [...state.wishlistItems, ...items],
                wishlistPage: page
            };
        }

        case TOGGLE_WISHLIST_LOADING: {
            return {
                ...state,
                wishlistLoading: action.payload.isLoading
            };
        }

        case CLEAR_WISHLIST: {
            return {
                ...state,
                wishlistItems: [],
                wishlistPage: 1
            };
        }

        default:
            return state;
    }
};

const getUniqueAttribute = (item: any) =>
    item.hasOwnProperty('sku') && item.sku[0]
        ? 'sku'
        : item.hasOwnProperty('uuid')
        ? 'uuid'
        : item.hasOwnProperty('unique')
        ? 'unique'
        : 'item_uuid';
