import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { login, logout } from 'store/user/actions';

import NavBar from './NavBar';

const mapStateToProps = ({
    user: { user },
    users: { loading },
    stylists: { stylist },
    ui: { landingType }
}) => ({
    loading,
    user,
    stylist,
    landingType
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => logout(dispatch, ownProps.history),
    login: (credentials) => login(dispatch, credentials)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
