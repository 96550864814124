import 'rc-slider/assets/index.css';
import './style.scss';

import React, { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { RangeBudget } from 'types/item';

import { Formatter } from '../../services';

interface IFilterSlider {
    filterKey: any;
    options: Record<string, any>;
    selected: Array<any>;
    min: number | undefined;
    max: number | undefined;
    onChange: ({ key, value }: { key: string; value: Record<any, any> | number }) => void;
    onClear: (filterKey: string) => void;
    filterSubmited: boolean;
}

const FilterSlider: React.FC<IFilterSlider> = ({
    filterKey,
    options,
    selected,
    min = 1,
    max = 5000,
    onChange,
    onClear,
    filterSubmited
}) => {
    const [range, setRange] = useState<RangeBudget>({ min: min, max: max });
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (selected) {
            setRange({ min: selected[0].value, max: selected[1].value });
        }
    }, [selected]);

    useEffect(() => {
        if (!filterSubmited) setSubmitted(filterSubmited);
    }, [filterSubmited]);

    const submit = () => {
        if (!submitted) {
            if (Object.keys(options).length) {
                onChange({
                    key: filterKey,
                    value: Object.keys(options).map((key) => ({
                        key: options[key].key,
                        value: range[key == 'min' ? key : 'max'],
                        text: options[key].text
                    }))
                });
                setSubmitted(true);
            } else {
                onChange({ key: filterKey, value: range });
                setSubmitted(true);
            }
        } else {
            setSubmitted(false);
            onClear('minPrice');
            setRange({ min: min, max: max });
        }
    };

    const setMinBudget = (priceInput: string) => {
        const price = parseInt(Formatter.priceFormatter(priceInput));
        const min = isNaN(price) || price < 0 ? 0 : price;
        setRange({ ...range, min });
    };

    const setMaxBudget = (priceInput: string) => {
        const price = parseInt(Formatter.priceFormatter(priceInput));
        const max = isNaN(price) || price < 0 ? 0 : price;
        setRange({ ...range, max });
    };

    return (
        <Col className="filter-slider">
            <Form>
                <Form.Control
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setMinBudget(e.target.value.substr(1))
                    }
                    value={Formatter.price(range.min)}
                />
                <Form.Control
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setMaxBudget(e.target.value.substr(1))
                    }
                    value={Formatter.price(range.max)}
                />
                <Button onClick={submit} className={!submitted ? 'submit' : 'clear'} />
            </Form>
        </Col>
    );
};

export default FilterSlider;
