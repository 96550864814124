import './style.scss';

import React, { useState } from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';

interface Option {
    label: string;
    value: string;
}
interface ISearchBox {
    options?: Option[];
    placeholder?: string;
    value?: Option | null;
    onChange: (option: SingleValue<Option>, actionMeta?: ActionMeta<Option>) => void;
    onSubmit?: () => void;
    onFocus?: () => void | null;
    onBlur?: () => void | null;
    inputOnChange?: boolean;
    className?: string;
}
const SearchBox: React.FC<ISearchBox> = ({
    options = [],
    placeholder = '',
    value = null,
    onChange,
    onSubmit,
    onFocus = undefined,
    onBlur = undefined,
    className = ''
}) => {
    const [openMenu, setMenuOpen] = useState(false);
    return (
        <Select
            id="search-box"
            inputId="search-box-input"
            className={`search-box ${className}`}
            classNamePrefix="select"
            placeholder={placeholder}
            hideSelectedOptions={true}
            options={options}
            isMulti={false}
            isClearable={true}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onInputChange={(input: string) => {
                if (options.length) setMenuOpen(input.length > 0);
                else if (input) onChange({ label: input, value: input });
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && onSubmit) {
                    setMenuOpen(false);
                    onSubmit();
                }
            }}
            menuIsOpen={openMenu}
            openMenuOnClick={false}
            value={value}
        />
    );
};

export default SearchBox;
