import './style.scss';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { modal } from '../../../content';

const DeactivateAccount = ({ deactivate, toggleModal, error }) => (
    <span className="deactivate-account">
        <Modal.Header closeButton>
            <p>{modal.deactivate.title}</p>
        </Modal.Header>
        <Modal.Body>
            <p>{error ? error.message : modal.deactivate.text}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="dark" onClick={error ? toggleModal : deactivate}>
                {error ? modal.deactivate.cancel : modal.deactivate.button}
            </Button>
        </Modal.Footer>
    </span>
);

export default DeactivateAccount;
