import '../style.scss';

import React from 'react';

interface ITextarea {
    props: any;
    value: string;
    onChange: ({ text }: { text: string }) => void;
}

const Textarea: React.FC<ITextarea> = ({ onChange, props = {}, value = '' }) => (
    <textarea
        onChange={(e) => onChange({ text: e.target.value })}
        defaultValue={value}
        {...props}
    />
);

export default Textarea;
