import React from 'react';
import { Image } from 'react-bootstrap';
import Slider from 'react-slick';

interface IItemImages {
    isMobile: boolean;
    pictures: string[];
    mainPicture: string;
    sliderRef: any;
    setLightbox: (isLightbox: boolean) => void;
}

const ItemImages: React.FC<IItemImages> = ({
    isMobile,
    pictures,
    mainPicture,
    sliderRef,
    setLightbox
}) => (
    <Slider
        className={`item-carousel ${pictures?.length > 0 ? 'with-dots' : ''}`}
        ref={sliderRef}
        dots={isMobile}
        arrows={!isMobile}
        infinite={false}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay={false}
        adaptiveHeight={true}
    >
        {pictures?.length > 0 ? (
            [mainPicture, ...pictures].map((picture) => (
                <Image key={picture} src={picture} onClick={() => !isMobile && setLightbox(true)} />
            ))
        ) : (
            <Image key={mainPicture} src={mainPicture} onClick={() => setLightbox(true)} />
        )}
    </Slider>
);

export default ItemImages;
