import React from 'react';
import { isArchiveOrInquiries } from 'services/utils/bookings-utils';
import { useBookingStore } from 'store/booking/reducer';
import { BookingQueue, QueueType } from 'types/booking';

interface INoResults {
    loading: boolean;
    selectedQueue: QueueType | undefined;
    bookingQueues: BookingQueue[];
}

const NoResults: React.FC<INoResults> = ({ loading, selectedQueue, bookingQueues }) => {
    let message = 'Nothing in ';
    const error = useBookingStore((store) => store.error);

    if (error) return <div className="no-results">No Data</div>;

    if (isArchiveOrInquiries(selectedQueue)) message += selectedQueue;
    else {
        const queue = bookingQueues.find((queue) => queue.key === selectedQueue);
        queue?.value ? (message += queue.value) : (message = 'No Results');
    }

    return <div className="no-results">{loading ? 'Loading...' : message}</div>;
};

export default NoResults;
