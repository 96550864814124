import './style.scss';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { isMatchedStylist, MIXPANEL_EVENTS, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import { useCommonStore } from 'store/common/reducer';
import { Client } from 'types/user';

import { modal } from '../../../content.json';

interface IEndSession {
    user: Client;
    data: any;
    toggleModal: () => void;
    endSession: (sid: string) => void;
}

const EndSession: React.FC<IEndSession> = ({ endSession, data, toggleModal, user }) => {
    const matches = useCommonStore((store) => store.stylistsMatchs);

    const onSubmit = () => {
        try {
            trackEvent({
                name: MIXPANEL_EVENTS.END_SESSION_REQUEST,
                properties: {
                    'User UUID': data.client.uuid,
                    'User email': data.client.email ? data.client.email : user.email,
                    'Stylist name': user.first_name + ' ' + user.last_name,
                    'Stylist UUID': user.user_uuid,
                    'Session count': user.bookings_count,
                    'Session status': 'Ongoing',
                    'Session UUID': data.sid,
                    'Plan type': data.plan,
                    'Payment amount': data.price,
                    'Session amount': data.price,
                    'Is stylist match': isMatchedStylist(matches, user.user_uuid)
                }
            });
            endSession(data.session.sid);
            toggleModal();
        } catch (e) {
            sentryException(e as Error, 'End session request error');
        }
    };

    return (
        <span className="end-session">
            <Modal.Header closeButton>
                <p>{modal.endSession.title}</p>
            </Modal.Header>

            <Modal.Body>
                <p>{modal.endSession.description}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="dark" onClick={onSubmit}>
                    {modal.endSession.submit}
                </Button>
            </Modal.Footer>
        </span>
    );
};

export default EndSession;
