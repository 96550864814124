import React from 'react';
import { Button } from 'react-bootstrap';
import { useBookingStore } from 'store/booking/reducer';
import { BookingType, ButtonActions, ButtonActionType } from 'types/booking';

interface IBookingButtons {
    booking: BookingType;
    buttonActions: ButtonActions[];
    isInquiries: boolean;
    onButtonClick: (e: React.MouseEvent, action: ButtonActionType, booking: BookingType) => void;
}

const BookingButtons: React.FC<IBookingButtons> = ({
    booking,
    onButtonClick,
    buttonActions,
    isInquiries
}) => {
    let buttons: JSX.Element[] = [];
    const bookingMetadata = useBookingStore((store) => store.bookingMetadata);

    const getClassName = (index: number) => {
        if (buttonActions.length <= 1 || (buttonActions.length >= 2 && index == 2))
            return 'single-btn';
        if (buttonActions.length >= 2 && index == 1) return 'right-btn';
        else return 'left-btn';
    };

    if (buttonActions.length > 1) {
        buttonActions = buttonActions.sort((a, b) => a.priority - b.priority);
    }

    if (buttonActions.length > 0) {
        buttons = buttonActions.map((action, index) => (
            <Button
                data-test-id={action.key}
                className={getClassName(index)}
                key={action.key}
                variant={action.isCta ? 'warning' : 'light'}
                style={{ fontWeight: action.isCta ? 'bold' : '' }}
                onClick={(e: React.MouseEvent) => onButtonClick(e, action.key, booking)}
            >
                {bookingMetadata.actions[action.key].value}
            </Button>
        ));
    }

    return <div className={`buttons ${isInquiries ? 'inquiries' : ''}`}>{buttons}</div>;
};
export default BookingButtons;
