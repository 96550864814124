import { TOGGLE_PAGE_MESSAGE } from './actions';

const initialState = {
    isMobile:
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i),
    message: true,
    showModal: false,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TOGGLE_PAGE_MESSAGE: {
            return {
                ...state,
                message: !state.message
            };
        }
        default:
            return state;
    }
}
