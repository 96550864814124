import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Content from '../../../content';

const StylingExperience = () => (
    <>
        <div className="title">{Content.stylist.stylingExperience.title}</div>
        <Row className="styling-experience">
            {Content.stylist.stylingExperience.items.map((item) => (
                <Col key={item.title} xs={12} md={4}>
                    <p className="subtitle">{item.title}</p>
                    <p className="experience">{item.text} </p>
                </Col>
            ))}
        </Row>
    </>
);

export default StylingExperience;
