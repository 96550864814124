import { connect } from 'react-redux';
import { toggleModal } from 'store/ui/actions';

import { endSession } from '../../../redux/reducers/stylists/actions';
import EndSession from './EndSession';

const mapStateToProps = ({ user: { user } }) => ({
    user
});

const mapDispatchToProps = (dispatch) => ({
    endSession: (sid) => dispatch(endSession(sid)),
    toggleModal: (data) => toggleModal(dispatch, data)
});

export default connect(mapStateToProps, mapDispatchToProps)(EndSession);
