import './style.scss';

import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { modal } from '../../../content';
import { isButtonDisable, isLocationDressingRoom } from '../../../services/utils/styleRoom-utils';
import { Loader } from '../..';

const SavePersonalShopping = ({
    loading,
    stylingRoomLoading,
    saveLook,
    toggleModal,
    redirectUrl,
    result,
    clearResult,
    error,
    changeModal,
    clearError
}) => {
    const history = useHistory();
    const [description, setDescription] = useState('');
    const [disable, setDisable] = useState(false);
    const isDressingRoom = isLocationDressingRoom();
    const isDisable = isButtonDisable(description, disable);

    useEffect(() => {
        if (result.message === 'success') {
            clearResult();
            toggleModal();
            if (!redirectUrl.includes('inbox')) window.location.href = redirectUrl;
            else history.push(redirectUrl);
        } else setDisable(false);
    }, [result]);

    useEffect(() => {
        if (error) {
            changeModal({
                type: 'Error',
                data: { error: modal.errors.sendBoard, callback: clearError }
            });
        }
    }, [error]);

    const submit = () => {
        setDisable(true);
        saveLook({ description });
    };

    return (
        <span className="save-personal-shopping">
            {(loading || stylingRoomLoading) && <Loader />}
            <span className="close" onClick={toggleModal} />
            <Modal.Header>
                {isDressingRoom
                    ? modal.savePersonalShopping.saveTitle
                    : modal.savePersonalShopping.title}
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>{modal.savePersonalShopping.description}</Form.Label>
                    <Form.Control
                        as="textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    {!description.length && (
                        <p className="error">{modal.savePersonalShopping.errors.description}</p>
                    )}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-test-id="send-board"
                    variant="warning"
                    onClick={submit}
                    disabled={isDisable}
                >
                    {isDressingRoom ? modal.saveButton : modal.savePersonalShopping.button}
                </Button>
            </Modal.Footer>
        </span>
    );
};

export default SavePersonalShopping;
