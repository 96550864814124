import { Dispatch } from 'redux';

import { ActionTypes, ModalData } from './types';

export const toggleModal = (dispatch: Dispatch, data?: ModalData) => {
    dispatch({
        type: ActionTypes.TOGGLE_MODAL,
        payload: data ?? {}
    });
};

export const changeModal = (dispatch: Dispatch, { type, data = {} }: { type: string; data: any }) =>
    dispatch({
        type: ActionTypes.CHANGE_MODAL,
        payload: { type, data }
    });
export const setModal = (dispatch: Dispatch, payload: boolean) =>
    dispatch({
        type: ActionTypes.SET_MODAL,
        payload
    });

export const setLandingType = (dispatch: Dispatch, landingType: string) => {
    dispatch({
        type: ActionTypes.SET_LANDING_TYPE,
        payload: landingType
    });
};
