import '../style.scss';

import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const Dropdown = ({ onChange, label, options = [], value = '' }) => {
    const [selection, setSelection] = useState();
    useEffect(() => {
        if (options.length && !selection) setSelection(options[0]);
    }, [options, selection]);
    const onSelect = (text) => onChange({ text });
    return (
        <Form.Group>
            <label>{label}</label>
            <Form.Control
                as="select"
                title={label}
                onChange={(e) => onSelect(e.target.value)}
                value={value}
            >
                {options.map((option) => (
                    <option key={option.key} value={option.key}>
                        {option.text}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};
export default Dropdown;
