import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { Actions } from '../actions';
import { ActionTypes, RoomState } from './types';

const initialState: RoomState = {
    stylingType: 'moodboard',
    revision: null
};

const roomReducer = (state = initialState, action: Actions): RoomState => {
    switch (action.type) {
        case ActionTypes.SET_STYLING_ROOM_TYPE: {
            return {
                ...state,
                stylingType: action.payload
            };
        }
        case ActionTypes.SET_REVISION: {
            return {
                ...state,
                revision: action.payload
            };
        }
        default:
            return state;
    }
};
export const name = 'room';
export const useRoomStore: TypedUseSelectorHook<RoomState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);
export default roomReducer;
