import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import Content from '../../../content';

const Expertise = ({ stylist, ref }) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <div className="experience" ref={ref}>
            {stylist.bio && (
                <>
                    <div className="title">{Content.stylist.experience}</div>

                    <Row>
                        <Col>
                            <p className="subtitle">{Content.stylist.bio}</p>
                            <p>{stylist.bio}</p>
                        </Col>
                    </Row>

                    {showMore && (
                        <>
                            {stylist.education && (
                                <Row>
                                    <Col>
                                        <p className="subtitle">{Content.stylist.school}</p>
                                        <p>{stylist.education}</p>
                                    </Col>
                                </Row>
                            )}

                            {stylist.collaborations && (
                                <Row>
                                    <Col>
                                        <p className="subtitle">{Content.stylist.collaborations}</p>
                                        <p>{stylist.collaborations}</p>
                                    </Col>
                                </Row>
                            )}

                            {stylist.website && (
                                <Row>
                                    <Col>
                                        <p className="subtitle">{Content.stylist.website}</p>
                                        <a
                                            href={`${
                                                stylist.website.indexOf('http') < 0 ? 'http://' : ''
                                            }${stylist.website}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {stylist.website}
                                        </a>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}

                    <div className="content-toggle-btn" onClick={() => setShowMore(!showMore)}>
                        {showMore ? Content.stylist.less : Content.stylist.more}
                    </div>
                </>
            )}
        </div>
    );
};

export default Expertise;
