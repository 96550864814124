import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Filters, GridItem, Loader } from '../../../components';
import { styling } from '../../../content.json';
import { Formatter } from '../../../services';
import {
    ClearFilterObject,
    FilterOptionsParameters,
    FilterParams,
    FilterStruct,
    Gender,
    Item,
    ShopQueryParameters,
    StylingRoomSection
} from '../../../types/styling';
import { Empty, FilterBadges } from '../components';

interface IStore {
    loading: boolean;
    loadingFilters: () => void | undefined;
    loadStore: (input: ShopQueryParameters) => void;
    loadStoreFilters: (fields: Record<string, string>) => void;
    updateFilters: (filter: FilterParams, isSelected: boolean, callback: () => void) => void;
    filters: FilterStruct[];
    filtersType: string;
    selectedFilters: Record<string, FilterOptionsParameters[]>;
    store: Item[];
    gender: Gender;
    setGridSize: (input: string) => void;
    gridSize: string;
    clearSelectedFilters: () => void;
    addToCanvas: (item: Item) => void;
    total: number;
    clearStore: () => void;
    removeStoreItem: (item: Item) => void;
}

export const Store: React.FC<IStore> = ({
    loading,
    loadingFilters,
    loadStore,
    store,
    loadStoreFilters,
    updateFilters,
    filters,
    filtersType,
    gender,
    selectedFilters,
    total,
    gridSize,
    setGridSize,
    addToCanvas,
    removeStoreItem,
    clearStore,
    clearSelectedFilters
}) => {
    const [jump, setJump] = useState(false);
    const [gridItems, setGridItems] = useState<Item[]>([]);
    const resultsRef = useRef<HTMLDivElement>(null);
    const [submitted, setSubmitted] = useState(true);

    useEffect(() => {
        clearStore();
        clearSelectedFilters();
        loadStoreFilters({
            fields: 'categories,colors,retailers,prices,brands,sizes'
        });
    }, [gender]);

    useEffect(() => {
        if (Array.isArray(filters) && filters.length && filtersType === 'store') fetchData();
    }, [filters, selectedFilters]);

    useEffect(() => {
        if (!loading && store) setGridItems(store);
    }, [store]);

    const fetchData = (filters?: Record<string, FilterOptionsParameters[]>) => {
        const activeFilters = filters ?? selectedFilters;
        const params = Object.keys(activeFilters).reduce(
            (ac, a) =>
                activeFilters[a].length
                    ? {
                          ...ac,
                          [a]: activeFilters[a]
                              .map((selection: FilterOptionsParameters) => selection.key)
                              .join(',')
                      }
                    : ac,
            {}
        );
        loadStore({ ...params, from: !filters && store ? store.length : 0 });
    };

    const onFilterChange = (filter: FilterParams) => {
        const isSelected =
            !!selectedFilters[filter.key] &&
            selectedFilters[filter.key].findIndex((item: FilterParams | FilterOptionsParameters) =>
                !Array.isArray(filter.value) ? item.key === filter.value.key : false
            ) > -1;

        updateFilters(filter, isSelected, fetchData);
    };

    const onRemoveBudget = () => {
        return updateFilters(
            {
                key: 'budget',
                value: [
                    { key: 'min_budget', value: 1, text: 'Min. Budget' },
                    { key: 'max_budget', value: 5000, text: 'Max. Budget' }
                ]
            },
            true,
            fetchData
        );
    };

    const clearFilter = (filter: ClearFilterObject) => {
        if (filter.key === 'min_budget') {
            setSubmitted(false);
            onRemoveBudget();
        } else if (filter.filter) onFilterChange(filter.filter);
    };

    return (
        <Container className="styling-room-store" fluid>
            <Row>
                <Col className="filters-column">
                    {loadingFilters == undefined && <Loader />}
                    <p className="total">
                        {styling.items.replace('%count%', Formatter.number(total))}
                    </p>
                    <Filters
                        className={
                            !selectedFilters.category ||
                            (selectedFilters.category && !selectedFilters.category.length)
                                ? 'categories-all'
                                : ''
                        }
                        filters={filters.map((filter) => filter)}
                        selected={selectedFilters}
                        setFilter={onFilterChange}
                        clearFilter={(key) => clearFilter({ key })}
                        showSelection={true}
                        filterSubmited={submitted}
                    />
                </Col>
                <div id="results" className="results col" ref={resultsRef}>
                    {loading && <Loader />}
                    <div className="results-bar">
                        {Object.keys(selectedFilters).length ? (
                            <FilterBadges
                                selectedFilters={selectedFilters}
                                clearFilter={clearFilter}
                                view={StylingRoomSection.store}
                            />
                        ) : (
                            ''
                        )}

                        <div className="grid-size">
                            <div
                                className={`size-btn large ${gridSize === 'large' ? 'active' : ''}`}
                                onClick={() => setGridSize('large')}
                            />
                            <div
                                className={`size-btn small ${gridSize === 'small' ? 'active' : ''}`}
                                onClick={() => setGridSize('small')}
                            />
                        </div>
                    </div>
                    {gridItems ? (
                        gridItems.length ? (
                            <InfiniteScroll
                                dataLength={gridItems ? gridItems.length : 0}
                                next={fetchData}
                                hasMore={true}
                                loader={undefined}
                                onScroll={() =>
                                    setJump(
                                        resultsRef.current
                                            ? resultsRef.current.scrollTop > 0
                                            : false
                                    )
                                }>
                                {gridItems.map((item) => (
                                    <GridItem
                                        key={item.uuid}
                                        type="store"
                                        size={gridSize}
                                        onRemove={() => removeStoreItem(item)}
                                        onClick={() => addToCanvas(item)}
                                        item={item}>
                                        {}
                                    </GridItem>
                                ))}
                            </InfiniteScroll>
                        ) : (
                            <Empty tab="store" />
                        )
                    ) : (
                        ''
                    )}
                </div>
            </Row>
            <div
                className={`top-btn ${jump ? 'show' : 'hide'}`}
                onClick={() => (resultsRef.current ? resultsRef.current.scrollTo(0, 0) : null)}>
                {styling.top}
            </div>
        </Container>
    );
};
