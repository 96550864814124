import { Dispatch } from 'redux';

import { ActionTypes, GridSize } from './types';

export const setGridSize = (dispatch: Dispatch, payload: GridSize) => {
    dispatch({
        type: ActionTypes.SET_GRID_SIZE,
        payload
    });
};
