const availabilityStringValues: Record<string, string> = {
    'Not available': 'notInStock',
    Available: 'available',
    Unknown: 'unknown'
};

export const getItemAvailability = (
    itemsAvailabilityObject: { [index: string]: { [size: string]: string } },
    id: string
) => {
    if (itemsAvailabilityObject && id in itemsAvailabilityObject) {
        const itemAvailability = itemsAvailabilityObject[id];
        const availabilityObject: { size: string; icon: string }[] = [];
        Object.keys(itemAvailability).map((size) => {
            const availability = itemAvailability[size];
            availabilityObject.push({
                size: size,
                icon: availabilityStringValues[availability] || 'loading'
            });
        });
        return availabilityObject;
    }
};

const generateNewPorterUrl = (url: string) => {
    return decodeURIComponent(url.substring(url.indexOf('murl=') + 5));
};

export const formater = (arrayToFormat: [{ [key: string]: string }], isSizeFilter: boolean) => {
    const itemsObject = arrayToFormat.reduce<{ [key: string]: string | number | string[] }[]>(
        (next, item) => {
            const isPorterItemToFix =
                item.merchant_name.includes('Net-a-Porter') &&
                item.buy_url.includes('https://click.linksynergy.com/link');

            return {
                ...next,
                [item.item_uuid]: {
                    size: item.item_size ?? item.size,
                    brand: item.merchant_name,
                    color: item.color,
                    url: isPorterItemToFix ? generateNewPorterUrl(item.buy_url) : item.buy_url,
                    available_sizes: isSizeFilter
                        ? [item.item_size ?? item.size]
                        : item.available_sizes
                }
            };
        },
        {} as { [key: string]: string | number | string[] }[]
    );

    return { items: itemsObject };
};
