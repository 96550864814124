import './style.scss';

import React from 'react';
import { Image } from 'react-bootstrap';

import { logo } from '../../content.json';

interface ILoader {
    id?: string;
}

const Loader: React.FC<ILoader> = ({ id = '' }) => (
    <div className="loader" data-test-id={id}>
        <Image src={logo.w} />
        <div className="spinner" />
    </div>
);

export default Loader;
