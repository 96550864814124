import './style.scss';

import React from 'react';
import { Modal } from 'react-bootstrap';

const _Modal = ({ show, onHide, className = '', children }) => (
    <Modal show={show} onHide={onHide} className={`modal ${className}`}>
        {children}
    </Modal>
);

export default _Modal;
